import {define, html, LitElement} from '../../common/commons';

import '@domg-wc/components/alert';

class IboError extends LitElement {
  static get properties() {
    return {
      validationErrors: {type: Array},
      notFoundErrors: {type: Array},
      errors4xx: {type: Array},
      errors5xx: {type: Array},
    };
  }

  constructor() {
    super();
    this.validationErrors = null;
    this.notFoundErrors = null;
    this.errors4xx = null;
    this.errors5xx = null;
  }

  createRenderRoot() {
    return this;
  }

  updated(_changedProperties) {
    super.update(_changedProperties);
    if (_changedProperties.has('validationErrors')) {
      window.requestAnimationFrame(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      });
    }
  }

  render() {
    if (this.validationErrors && this.validationErrors.length > 0) {
      return html`
        <vl-alert
          type="error"
          icon="warning"
          title="Gelieve volgende ${this.validationErrors.length} validatiefout(en) op te lossen:">
          <div class="error-alert">
            ${this.validationErrors.map((e) => html`<p>&nbsp;&nbsp;&#8594; ${e.message}</p>`)}
          </div>
        </vl-alert>`;
    }
    if (this.notFoundErrors && this.notFoundErrors.length > 0) {
      return html`
        <vl-alert type="error" icon="warning" title="404-fout">
          <div class="error-alert">
            ${this.notFoundErrors.map((e) => html`<p>${e.message}</p>`)}
          </div>
        </vl-alert>`;
    }
    if (this.errors4xx && this.errors4xx.length > 0) {
      return html`
        <vl-alert
          type="error" icon="warning"
          title="Volgende aanvraagfout heeft zich voorgedaan. Contacteer de applicatie-beheerder.">
          <div class="error-alert">
            ${this.errors4xx.map(
              (e) => html`<p>${e.message}</p>`)}
          </div>
        </vl-alert>`;
    }
    if (this.errors5xx && this.errors5xx.length > 0) {
      return html`
        <vl-alert
          type="error" icon="warning"
          title="Volgende serverfout heeft zich voorgedaan. Contacteer de applicatie-beheerder.">
          <div class="error-alert">
            ${this.errors5xx.map(
              (e) => html`
                <p>Status: ${e.status}</p>
                <p>Message: ${e.message}</p>
              `)}
          </div>
        </vl-alert>`;
    }
    return html``;
  }

  clear() {
    this.validationErrors = null;
    this.notFoundErrors = null;
    this.errors4xx = null;
    this.errors5xx = null;
  }
}

define('ibo-error', IboError);
