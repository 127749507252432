import {
  api,
  define,
  deleteConfirmed,
  formatDate,
  handleErrors,
  html,
  LitElement,
  performWithLoader,
  queryById,
} from '../../common/commons';

import '@domg-wc/elements/button';
import '@domg-wc/components/modal';

import '../error/ibo-error';

class IboBevestigVerwijderInspraak extends LitElement {
  static get properties() {
    return {
      link: {type: String},
      inspraak: {type: Object},
    };
  }

  constructor() {
    super();
  }

  firstUpdated(_changedProperties) {
    this._deletePopup = queryById(this)('bevestigVerwijderInspraakPopUp');
    this._verwijderButton = queryById(this)('verwijder');
    this._deletePopup.on('close', () => this._clearForm());
    this._error = queryById(this)('errorVerwijderInspraak');
  }

  updated(_changedProperties) {
    if (_changedProperties.has('inspraak') && this.inspraak !== null) {
      this._deletePopup.open();
    }
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <vl-modal
        id="bevestigVerwijderInspraakPopUp"
        data-vl-title="Wilt u de volgende inspraakperiode verwijderen?"
        not-auto-closable>
        ${this._renderBevestigVerwijderenData()}
        ${this._renderBevestigVerwijderenActions()}
      </vl-modal>`;
  }

  _renderBevestigVerwijderenData() {
    return html`
      <span slot="content">
          <ibo-error id="errorVerwijderInspraak"></ibo-error>
          ${this._renderInspraakData()}
        </span>`;
  }

  _renderInspraakData() {
    if (!this.inspraak) {
      return html``;
    }
    return html`
      <div is="vl-form-column" data-vl-size="12">
        <p>Bent u zeker dat u inspraakperiode <strong>${this.inspraak.naam}</strong> wilt verwijderen? Deze
          inspraakperiode loopt van <strong>${formatDate(this.inspraak.periodeVan)}</strong> tot en met
          <strong>${formatDate(this.inspraak.periodeTotEnMet)}</strong>.
          De initiatiefnemer van deze inspraakperiode is <strong>${this.inspraak.initiatiefnemer}</strong>.</p>
      </div>`;
  }

  _renderBevestigVerwijderenActions() {
    return html`
      <button id="verwijder" is="vl-button" @click="${this._verwijder}" slot="button">
        <span is="vl-icon" data-vl-icon="bin" data-vl-before></span>
        Inspraakperiode verwijderen
      </button>
    `;
  }

  _verwijder() {
    performWithLoader(this._verwijderButton, () => this._performDelete());
  }

  async _performDelete() {
    try {
      await api.delete(this.link);
      this.dispatchEvent(deleteConfirmed());
      this._deletePopup.close();
    } catch (error) {
      handleErrors(error, this._error);
    }
  }

  _clearForm() {
    this.link = null;
    this.inspraak = null;
    this._error.clear();
  }
}

window.customElements.whenDefined('vl-modal').then(() => {
  define('ibo-bevestig-verwijder-inspraak', IboBevestigVerwijderInspraak);
});
