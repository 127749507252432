import {html, LitElement, define} from '../../common/commons';
import {renderContentHeader, renderLayout} from '../../common/templates';

import '@domg-wc/components/http-error-message';
import '@domg-wc/elements/link';


class IboBeheerView404 extends LitElement {
  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${renderContentHeader('/inspraak-beheer')}
      ${renderLayout([html`
        <section is="vl-region">
          <vl-http-404-message id="not-found-message">
            <div slot="actions">
              <a is="vl-link-button" href="/inspraak-beheer">Terug naar het beheer</a>
            </div>
          </vl-http-404-message>
        </section>
        </div>
      `])}`;
  }
}

define('ibo-beheer-view404', IboBeheerView404);
