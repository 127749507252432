import {
  comparingWithFunction,
  debounce,
  define,
  fixVlRichData,
  formatDate,
  get,
  html,
  LitElement,
  queryById, unsafeHTML,
} from '../../common/commons';
import {api, getResource} from '../../common/rest';
import {
  buildLocatieFilter,
  buildPeriodeEindigtNaFilter,
  buildPubliekeTrefwoordFilter,
} from '../../common/filter';
import {findSearchParamValue, reduceFilterSpec} from '../../common/rsql';
import {
  renderContentHeader,
  renderFormGrid,
  renderLayout,
  renderStack,
} from '../../common/templates';
import {formMixin} from '../../common/forms';
import {select, text} from '../../common/inputs';
import {__asGroupedLocaties} from '../../common/administratieve-eenheden';

import '@domg-wc/components/accordion';
import '@domg-wc/components/alert';
import '@domg-wc/components/info-tile';
import '@domg-wc/components/loader';
import '@domg-wc/components/pager';
import '@domg-wc/components/rich-data';
import '@domg-wc/components/typography';

import '@domg-wc/elements/action-group';
import '@domg-wc/elements/button';
import '@domg-wc/elements/form';
import '@domg-wc/elements/icon';
import '@domg-wc/elements/link';
import '@domg-wc/elements/introduction';
import '@domg-wc/elements/input-field';
import '@domg-wc/elements/properties';
import '@domg-wc/elements/search-filter';
import '@domg-wc/elements/select';
import '@domg-wc/elements/title';

class IboInspraakPubliek extends formMixin(LitElement) {
  static get properties() {
    return {
      page: {type: Object},
      loading: {type: Boolean},
      keuzelijstLoading: {type: Boolean},
    };
  }

  constructor() {
    super();
    this.__debouncedSearch = debounce({func: () => this.__search(), context: this, delay: 100});
    this.__searcher = null;
    this.page = {
      paging: {
        currentPage: 1,
        itemsPerPage: 10,
      },
    };
    this.loading = true;
    this.keuzelijstLoading = true;
  }

  static get defaultSortKeuze() {
    return 'periodeTotEnMet';
  }

  static get currentDate() {
    return new Date().toISOString().split('T')[0];
  }

  async firstUpdated(changedProperties) {
    try {
      const resource = await getResource({url: '/api'});
      this.__links = resource._links;

      const locaties = await getResource({url: this.__links.locaties.href});
      this.__locaties = locaties._embedded.administratieveEenheden;
    } catch (e) {
      console.error(e);
    }
    this.keuzelijstLoading = false;
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);

    if (_changedProperties.has('page') && this.page) {
      this.byId('rich-data').data = this.page;
      fixVlRichData(this.byId('rich-data'));
    }

    if (_changedProperties.has('keuzelijstLoading') && !this.keuzelijstLoading) {
      this.formData = {
        'trefwoord': findSearchParamValue('trefwoord'),
        'betrokken-locaties': findSearchParamValue('locatie'),
      };

      // pas zoeken als formdata ingevuld is
      this.__debouncedSearch();
    }
  }

  get form() {
    return this.byId('search-form');
  }

  __extractPage(data) {
    return {
      data: data._embedded.inspraakPeriodeList,
      paging: {
        currentPage: data.page.number + 1,
        itemsPerPage: data.page.size,
        totalItems: data.page.totalElements,
      },
    };
  }

  byId(id) {
    return queryById(this)(id);
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${renderContentHeader('')}
      <section is="vl-region">
        ${renderLayout([this.__renderContent()])}
      </section>`;
  }

  __renderContent() {
    return renderStack([
      {size: 12, template: this.__renderInspraakTitel()},
      {size: 8, mediumSize: 10, template: this.__renderInspraakIntroduction()},
      {
        size: 8,
        mediumSize: 10,
        template: html`
          <hr/>`,
      },
      {size: 8, mediumSize: 10, template: this.__renderAlert()},
      {size: 8, mediumSize: 10, template: this.__renderAccordion()},
      {size: 12, template: html`<h2 is="vl-h2">Overzicht inspraakperiodes</h2>`},
      {size: 12, template: this.__renderRichData()},
    ]);
  }

  __renderInspraakTitel() {
    return html`
      <vl-typography>
        <h1 is="vl-h1">Inspraak binnen het beleidsdomein Omgeving</h1>
      </vl-typography>
    `;
  }

  __renderInspraakIntroduction() {
    return html`
      <p is="vl-introduction">
        Op dit portaal vindt u alle informatie over lopende inspraakperiodes in
        het beleidsdomein Omgeving van de Vlaamse overheid en over niet-Vlaamse
        procedures. Als burger hebt u het recht hierover uw mening te geven.
      </p>
    `;
  }

  __renderAlert() {
    return html`
      <vl-alert data-cy="alert" data-vl-icon="info-circle" data-vl-type="info" data-vl-title="info">
        <vl-typography>
          <p>
            Opgelet voor de inspraak over omgevingsvergunningen moet u op
            <a
              href="https://omgevingsloket.omgeving.vlaanderen.be/publiek/?openbaaronderzoek"
              target="_blank">volgende website</a>
            zijn.
          </p>
          <p>
            Inspraak over gemeentelijke of provinciale plannen en projecten is
            hier niet opgenomen.
          </p>
        </vl-typography>
      </vl-alert>
    `;
  }

  __renderAccordion() {
    return html`
      <vl-accordion id="accordion" data-vl-toggle-text="Lees meer over de inspraakperiodes">
        ${this.__renderInspraakOverzicht()}
      </vl-accordion>
    `;
  }

  __renderInspraakOverzicht() {
    return html`
      <div>
        <vl-typography>
          <p>
            Dit portaal verzamelt hiertoe alle informatie over de lopende
            inspraakperiodes. Het gaat over:
          </p>
          <ul>
            <li>gewestelijke plannen en projecten over ruimtelijke ordening,
              leefmilieu, natuur, klimaat, energie, wonen of onroerend erfgoed,
            </li>
            <li>voorstellen van wijzigingen aan VLAREM (Vlaams reglement
              betreffende de milieuvergunning),
            </li>
            <li>plannen of projecten van een ander
              gewest, de federale overheid of een buurland die een invloed
              kunnen hebben op uw gemeente en die in het kader van grens- of
              gewestoverschrijdende inspraak werden overgemaakt: verder
              “niet-Vlaamse procedures” genaamd.
            </li>
          </ul>
          <p>
            Per inspraakperiode vindt u:
          </p>
          <ul>
            <li>waar u voor meer informatie over het plan of project terecht
              kan,
            </li>
            <li>in welke fase het plan of project zit,</li>
            <li>hoe, wanneer en waar u uw inspraakreactie kenbaar kan maken.
            </li>
          </ul>
        </vl-typography>
      </div>
    `;
  }

  __renderRichData() {
    // TODO data-vl-filter-closable moet hier eigenlijk niet
    //  (https://github.com/milieuinfo/webcomponent-vl-ui-rich-data/issues/14)

    // TODO 2: sorter select in methode gieten zoals de andere componenten
    //  zie https://github.com/milieuinfo/webcomponent-vl-ui-rich-data/issues/15
    return html`
      <vl-rich-data id="rich-data" data-vl-filter-closable>
        <vl-pager slot="pager" @change="${this.__handlePageNumberChanged}"></vl-pager>
        <div slot="content">${this.__renderResultaten()}</div>
        <div slot="no-content">${this.__renderGeenResultaten()}</div>
        <select
          is="vl-select" id="sort-dropdown" name="sort-dropdown" slot="sorter"
          @change="${this.__handleSortOrderChanged}">
          ${(this.__renderSortKeuzes())}
        </select>
        <div is="vl-search-filter" data-vl-alt="" slot="filter">
          ${this.__renderSearchForm()}
        </div>
      </vl-rich-data>`;
  }

  __renderSortKeuzes() {
    return ['naam', 'periodeVan', 'periodeTotEnMet'].map((sk) => this.__renderSortKeuze(sk));
  }

  __renderSortKeuze(sk) {
    return html`
      <option value="${sk}" ?selected="${sk === IboInspraakPubliek.defaultSortKeuze}">
        ${get(`publiekeSortering.${sk}`)}
      </option>`;
  }

  __renderSearchForm() {
    if (this.keuzelijstLoading) {
      return html`
        <vl-loader id="loader-search-form"></vl-loader>`;
    }
    return html`
      <form
        is="vl-form" id="search-form" novalidate
        @reset="${this.__handleSearchFormReset}"
        @submit="${(e) => e.preventDefault()}">

        ${this.__renderTrefwoord()}
        ${this.__renderLocatie()}
        ${this.__renderSearchButton()}
      </form>
      <div>
        <button is="vl-button-link" type="reset" form="search-form">
          Zoekopdracht verwijderen
        </button>
      </div>`;
  }

  __renderTrefwoord() {
    return html`
      <section>
        <h2>Filter inspraakperiodes</h2>
        ${renderFormGrid([
          text({
            label: 'Trefwoord',
            property: 'trefwoord',
            placeholder: 'Zoek op trefwoord',
            onChange: this.__handleSearchChanged,
          }),
        ])}
      </section>
    `;
  }

  __renderLocatie() {
    return html`
      <section>
        <h2>Locatie</h2>
        ${this.__renderLocatieSelect()}
      </section>
    `;
  }

  __renderLocatieSelect() {
    return renderFormGrid([
      select({
        label: 'Betrokken gemeente of provincie',
        property: 'betrokken-locaties',
        placeholder: 'Alle gemeentes en provincies',
        onChange: this.__handleSearchChanged,
        choices: this.__locaties,
        groupFunction: __asGroupedLocaties,
        sortFilter: comparingWithFunction((x) => x.id),
      })]);
  }

  __renderSearchButton() {
    return html`
      <div>
        <button
          is="vl-button" id="search-button" type="submit"
          @click="${this.__handleSearchChanged}">
          <span is="vl-icon" data-vl-icon="search" data-vl-before></span>
          Zoeken
        </button>
      </div>
    `;
  }

  __renderGeenResultaten() {
    if (this.loading) {
      return html`
        <vl-loader></vl-loader>`;
    }

    return html`
      <p>
        Er zijn geen inspraakperiodes gevonden die aan uw huidige filter voldoen.
      </p>
    `;
  }

  __renderResultaten() {
    if (this.loading) {
      return html`
        <vl-loader></vl-loader>`;
    }

    return renderStack([
      ...this.page.data.flatMap((item) => this.__renderResultaat(item)),
    ]);
  }

  __renderResultaat(inspraakperiode) {
    const subTitle =
      `Inspraakperiode loopt van 
      ${formatDate(inspraakperiode.periodeVan)} 
      tot en met 
      ${formatDate(inspraakperiode.periodeTotEnMet)}`;
    return {
      template: html`
        <vl-info-tile name="resultaat-info" data-vl-toggleable>
          <span slot="title">${inspraakperiode.naam}</span>
          <span slot="subtitle">${subTitle}</span>
          <div slot="content">
            ${renderStack([
              {template: this.__renderResultaatDossierInfo(inspraakperiode)},
              {template: this.__renderResultaatBezwaren(inspraakperiode)},
            ])}
          </div>
        </vl-info-tile>
      `,
    };
  }

  __renderResultaatDossierInfo(inspraakperiode) {
    return html`
      <h4 is="vl-h4">Over dit dossier</h4>
      <vl-typography>${unsafeHTML(inspraakperiode.dossierInfo)}</vl-typography>
    `;
  }

  __renderResultaatBezwaren(inspraakperiode) {
    return html`
      <h4 is="vl-h4">Bezwaren, opmerkingen en adviezen indienen</h4>
      <vl-typography>${unsafeHTML(inspraakperiode.inspraakInstructies)}
      </vl-typography>
    `;
  }

  __handlePageNumberChanged(e) {
    if (this.page.paging.currentPage !== e.detail.currentPage) {
      this.page = Object.assign(this.page, {
        paging: {
          currentPage: e.detail.currentPage,
          itemsPerPage: this.page.paging.itemsPerPage,
          totalItems: this.page.paging.totalItems,
        },
      });
      this.__debouncedSearch();
    }
  }

  __handleSortOrderChanged(e) {
    this.__sort = e.target ? e.target.value : GrupsZoeken.defaultSortKeuze;
    this.__debouncedSearch();
  }

  __handleSearchFormReset() {
    this.formData = {};
    // formdata resettten zou voldoende zijn, daar je dan een change event
    // verwacht van de form dit is niet zo voor alle componenten, dus moeten
    // we hier expliciet nog de pager resetten en de search triggeren
    this.__resetPager();
    this.__debouncedSearch();
  }

  __handleSearchChanged(e) {
    this.__resetPager();
    this.__debouncedSearch();
  }

  __resetPager() {
    this.page = Object.assign(this.page, {
      paging: {
        currentPage: 1,
        itemsPerPage: this.page.paging.itemsPerPage,
        totalItems: this.page.totalItems,
      },
    });
  }

  async __search() {
    this.loading = true;
    this.__updateCurrentLocation();
    const data = await this.__searchApi().get({
      url: this.__searchUrl({
        filterSpec: this.__createFilterSpec(),
        page: this.page.paging.currentPage,
        size: this.page.paging.itemsPerPage,
        sort: this.__sort,
      }),
    });
    this.page = this.__extractPage(data);
    this.loading = false;
  }

  __searchApi() {
    if (this.__searcher) {
      this.__searcher.abort();
    }
    this.__searcher = api();
    return this.__searcher;
  }

  __createFilterSpec() {
    const formData = this.formData;
    return {
      trefwoord: {
        value: formData.trefwoord,
        filter: buildPubliekeTrefwoordFilter,
      },
      betrokkenLocatie: {
        value: formData['betrokken-locaties'],
        filter: buildLocatieFilter,
      },
      periodeTotEnMet: {
        value: IboInspraakPubliek.currentDate,
        filter: buildPeriodeEindigtNaFilter,
      },
    };
  }

  __searchUrl(
    {
      filterSpec,
      page = this.page.paging.currentPage,
      size = this.page.paging.itemsPerPage,
      sort = IboInspraakPubliek.defaultSortKeuze,
    } = {}) {
    const url = new URL(this.__links.inspraakPeriodes.href);
    if (filterSpec) {
      url.searchParams.append('filter', reduceFilterSpec(filterSpec));
    }
    url.searchParams.append('page', (page - 1).toString(10));
    url.searchParams.append('size', size.toString(10));
    url.searchParams.append('sort', sort);
    return url.href;
  }

  __updateCurrentLocation() {
    const currentLocation = new URL(window.location.href);
    const searchParams = currentLocation.searchParams;
    this.__updatesearchParam(searchParams, 'trefwoord', this.formData['trefwoord']);
    this.__updatesearchParam(searchParams, 'locatie', this.formData['betrokken-locaties']);
    window.history.replaceState({}, '', currentLocation.href);
  }

  __updatesearchParam(searchParams, key, value) {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
  }
}

Promise.all([
  window.customElements.whenDefined('vl-select'),
  window.customElements.whenDefined('vl-accordion'),
]).then(() => {
  define('ibo-inspraak-publiek', IboInspraakPubliek);
});

