import './inputs';
import {emptyViolations} from './inputs';

export const formMixin = (base) => class extends base {
  constructor() {
    super();
  }

  get form() {
    return this.querySelector('form');
  }

  set formData(formData) {
    this.formInputs.forEach((input) => {
      const value = formData[input.name];
      __setValue(input, value);
    });
  }

  putFormValue(name, value) {
    this.formInputs
    .filter((input) => input.name === name)
    .forEach((input) => __setValue(input, value));
  }

  async submitForm({valid, invalid, validator}) {
    const formData = this.formData;
    this.formInputs.forEach((input) => {
      if (input.noSubmit) {
        delete formData[input.name];
      }
    });
    const formViolations = this.__combineViolations(
      await this.checkFormValidity(),
      validator ? await validator(formData) : {});

    if (this.__isEmpty(formViolations)) {
      if (valid) {
        await valid(formData);
      }
      return;
    }

    this.bindErrors((name) => {
      return formViolations[name] ?
        formViolations[name] :
        emptyViolations();
    });
    if (invalid) {
      await invalid(formViolations);
    }
  }

  __combineViolations(left, right) {
    const combined = {};
    for (const key in left) {
      if (!left.hasOwnProperty(key)) {
        continue;
      }
      if (right[key] && left[key]) {
        combined[key] = left[key].combine(right[key]);
      }
    }
    return {...left, ...right, ...combined};
  }

  __isEmpty(errors) {
    return Object.entries(errors).length === 0;
  }

  bindErrors(resolveErrorFn) {
    let firstError = null;
    this.formInputs.forEach((input) => {
      const violations = resolveErrorFn(input.name);
      if (!firstError && violations.isInvalid()) {
        firstError = input;
      }
      input.errors = violations.violations;
    });
    if (firstError) {
      firstError.focus();
    }
  }

  putFieldError(name, errors) {
    this.formInputs
    .filter((input) => input.name === name)
    .forEach((input) => {
      input.errors = errors;
    });
  }

  async checkFormValidity() {
    const formViolations = {};
    for (const input of this.formInputs) {
      const inputViolations = await input.checkValidity();
      if (inputViolations.isInvalid()) {
        formViolations[input.name] = inputViolations;
      }
    }
    return formViolations;
  }

  get formData() {
    const formData = this.formInputs
    .map((input) => {
      return {
        [input.name]: this.__extractValue(input),
      };
    })
    .reduce((acc, el) => this.__reduceValues(acc, el), {propertyNames: []});

    delete formData.propertyNames;
    return formData;
  }

  get formInputs() {
    return Array.from(this.form.querySelectorAll('*[data-form-input]'));
  }

  clearFormInput(name) {
    this.formInputs
    .filter((input) => input.name === name)
    .forEach((input) => input.clear());
  }

  __extractValue(input) {
    if (input.hasAttribute('data-vl-switch')) {
      return input.checked;
    }
    if (input.checked) {
      return input._inputElement.value;
    }
    return input.value;
  }

  __reduceValues(acc, el) {
    for (const key in el) {
      if (el.hasOwnProperty(key) === false) {
        continue;
      }
      const value = el[key];
      this.__transformMultiValue(acc, key);
      if (value !== undefined) {
        this.__reduceValue(acc, key, value);
      }
    }
    return acc;
  }

  __reduceValue(acc, key, value) {
    if (Array.isArray(acc[key])) {
      acc[key] = [...acc[key], value];
    } else {
      acc[key] = value;
    }
  }

  __transformMultiValue(acc, key) {
    // the key is associated with multiple values, since we find it again,
    // therefore we change the value to an array if it isn't already..
    if (acc.propertyNames.includes(key)) {
      if (!Array.isArray(acc[key])) {
        acc[key] = acc[key] ? [acc[key]] : [];
      }
    } else {
      acc.propertyNames = [...acc.propertyNames, key];
    }
  }
};

const __setValue = (input, value) => {
  try {
    input.value = value;
  } catch (error) {
    console.error(
      `failed setting input ${input.tagName} / ${input.id} with value ${value}`);
  }
};

