import {comparingWithFunction, groupBy} from './commons';

export const __asGroupedLocaties = (locaties) => {
  return Object.entries(locaties.reduce(groupBy('type'), {}))
  .map((tuple) => __groupedChoice(tuple));
};

const __groupedChoice = (tuple) => {
  const groepering = tuple[0];
  const locaties = tuple[1];
  return {
    label: groepering,
    id: __groeperingId(groepering),
    choices: locaties.map((g) => {
      return {label: g.naam, value: g.niscode};
    }).sort(comparingWithFunction((x) => x.label)),
  };
};

const __groeperingId = (groeperingLabel) => {
  if (groeperingLabel === 'Gewest') {
    return `a.${groeperingLabel}`;
  } else if (groeperingLabel === 'Provincie') {
    return `b.${groeperingLabel}`;
  }
  return `c.${groeperingLabel}`;
};
