import {css} from '../common/commons';

export const IboHeaderStyle = css`
.vlw html, .vlw body, .vlw div, .vlw span, .vlw applet, .vlw object, .vlw iframe, .vlw h1, .vlw h2, .vlw h3, .vlw h4, .vlw h5, .vlw h6, .vlw p, .vlw blockquote, .vlw pre, .vlw a, .vlw abbr, .vlw acronym, .vlw address, .vlw big, .vlw cite, .vlw code, .vlw del, .vlw dfn, .vlw em, .vlw img, .vlw ins, .vlw kbd, .vlw q, .vlw s, .vlw samp, .vlw small, .vlw strike, .vlw strong, .vlw sub, .vlw sup, .vlw tt, .vlw var, .vlw b, .vlw u, .vlw i, .vlw center, .vlw dl, .vlw dt, .vlw dd, .vlw ol, .vlw ul, .vlw li, .vlw fieldset, .vlw form, .vlw label, .vlw legend, .vlw table, .vlw caption, .vlw tbody, .vlw tfoot, .vlw thead, .vlw tr, .vlw th, .vlw td, .vlw article, .vlw aside, .vlw canvas, .vlw details, .vlw embed, .vlw figure, .vlw figcaption, .vlw footer, .vlw header, .vlw hgroup, .vlw menu, .vlw nav, .vlw output, .vlw ruby, .vlw section, .vlw summary, .vlw time, .vlw mark, .vlw audio, .vlw video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.vlw article, .vlw aside, .vlw details, .vlw figcaption, .vlw figure, .vlw footer, .vlw header, .vlw hgroup, .vlw menu, .vlw nav, .vlw section {
    display: block;
}

.vlw body {
    line-height: 1;
}

.vlw ol, .vlw ul {
    list-style: none;
}

.vlw blockquote, .vlw q {
    quotes: none;
}

.vlw blockquote:before, .vlw blockquote:after, .vlw q:before, .vlw q:after {
    content: "";
    content: none;
}

.vlw table {
    border-collapse: collapse;
    border-spacing: 0;
}

.vlw, .vlw * {
    box-sizing: border-box;
}

.vlw::before, .vlw::after, .vlw *::before, .vlw *::after {
    box-sizing: inherit;
}

.vlw a, .vlw button, .vlw [type=submit] {
    color: #0055CC;
    text-decoration: underline;
    text-decoration-skip: ink;
}

.vlw a:hover, .vlw a:focus, .vlw button:hover, .vlw button:focus, .vlw [type=submit]:hover, .vlw [type=submit]:focus {
    text-decoration: none;
    color: #0051C2;
}

.vlw a:focus, .vlw a:active, .vlw button:focus, .vlw button:active, .vlw [type=submit]:focus, .vlw [type=submit]:active {
    color: #004CB8;
}

.vlw a:focus, .vlw a:active {
    background: none;
    background-color: none;
    border: none;
}

.vlw::before {
    display: none;
    content: "large";
}

@media screen and (max-width: 1023px) {
    .vlw::before {
        content: "medium";
    }
}

@media screen and (min-width: 767.1px) {
    .vlw::before {
        content: "medium-up";
    }
}

@media screen and (max-width: 767px) {
    .vlw::before {
        content: "small";
    }
}

@media screen and (max-width: 500px) {
    .vlw::before {
        content: "xsmall";
    }
}

@media screen and (min-width: 1600.1px) {
    .vlw::before {
        content: "xlarge";
    }
}

.vlw h1, .vlw h2, .vlw h3, .vlw h4, .vlw h5, .vlw h6 {
    color: #333332;
}

.vlw {
    font-family: "Flanders Art Sans", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.vlw .vlw__alert-bar {
    position: relative;
    box-shadow: none;
}

.vlw .vlw__alert-bar .vlw__alert {
    margin-top: -43px;
}

.vlw .vlw__alert-bar--showing {
    margin-top: 0;
    box-shadow: 0 1px 3px rgba(12, 13, 14, 0.1), 0 4px 20px rgba(12, 13, 14, 0.035), 0 1px 1px rgba(12, 13, 14, 0.025);
}

.vlw .vlw__alert-bar--showing .vlw__alert {
    margin-top: 0;
}

.vlw .vlw__alert-bar--browser {
    display: none;
}

.vlw .vlw__alert {
    height: 42px;
    min-height: 43px;
    border-bottom: 1px #cbd2da solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    transition: all 0.2s linear;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__alert {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.vlw .vlw__alert--error {
    background: #fbe6e6;
}

.vlw .vlw__alert--error .vlw__alert__icon {
    background: #db3434;
}

.vlw .vlw__alert--warning {
    background: #fef9e5;
}

.vlw .vlw__alert--warning .vlw__alert__icon {
    background: #ffc515;
}

.vlw .vlw__alert--success {
    background: #f7fae5;
}

.vlw .vlw__alert--success .vlw__alert__icon {
    background: #8bae00;
}

.vlw .vlw__alert__badge {
    margin-right: 5px;
    flex: 0 0 26px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__alert__badge {
        flex: 0 0 20px;
        margin-left: 5px;
    }
}

.vlw .vlw__alert__message:not(:last-child) {
    margin-right: 10px;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__alert__message p {
        font-size: 12px;
        line-height: 1;
    }
}

.vlw .vlw__alert__cta {
    flex: 0 0 auto;
}

.vlw .vlw__breadcrumb {
    display: flex;
    flex: 0 0 auto;
    height: 100%;
    padding-left: 10px;
}

.vlw .vlw__breadcrumb__list {
    display: flex;
    height: auto;
    min-height: 43px;
    align-items: center;
}

.vlw .vlw__breadcrumb__list__item {
    position: relative;
    display: flex !important;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__breadcrumb__list__item:not(:first-of-type) {
        display: none;
    }
}

.vlw .vlw__breadcrumb__list__item:not(:last-of-type) {
    margin-right: 10px;
}

.vlw .vlw__breadcrumb__list__item__separator {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.vlw .vlw__breadcrumb__list__item__separator__icon {
    width: 14px;
    height: 14px;
    fill: #cbd2da;
}

.vlw .vlw__breadcrumb__list__item__cta {
    display: inline-block;
    align-items: center;
    font-size: 15px;
    height: 100%;
    line-height: 28px;
    text-decoration: none;
    color: #333332;
    cursor: pointer;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 1023px) {
    .vlw .vlw__breadcrumb__list__item__cta {
        font-size: 14px;
        max-width: 150px;
    }
}

.vlw .vlw__breadcrumb__list__item__cta:hover, .vlw .vlw__breadcrumb__list__item__cta:focus {
    color: #0055CC;
}

.vlw .vlw__breadcrumb__list__item.vlw__js-breadcrumb-item--collapsed .vlw__breadcrumb__list__item__cta {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 0.7rem;
}

.vlw .vlw__breadcrumb__list__item__dropdown {
    margin-right: -8px;
}

.vlw .vlw__breadcrumb__list__item__dropdown__toggle {
    display: flex;
    height: 28px;
    align-items: center;
    padding-left: 9px;
    padding-top: 1px;
    padding-right: 9px;
    fill: #333332;
    background: none;
    border: none;
    cursor: pointer;
}

.vlw .vlw__breadcrumb__list__item__dropdown__toggle:hover, .vlw .vlw__breadcrumb__list__item__dropdown__toggle:focus {
    fill: #0055CC;
}

.vlw .vlw__breadcrumb__list__item__dropdown__toggle__icon {
    width: 10px;
    height: 10px;
    fill: inherit;
}

.vlw-breadcrumb-item-transition-enter-active, .vlw-breadcrumb-item-transition-leave-active {
    transition: opacity 0.2s, transform 0.4s;
}

.vlw-breadcrumb-item-transition-enter, .vlw-breadcrumb-item-transition-leave-to {
    opacity: 0;
    transform: translateY(-10px) translateZ(0);
}

@media screen and (max-width: 500px) {
    .vlw-breadcrumb-item-transition-enter, .vlw-breadcrumb-item-transition-leave-to {
        transform: translateY(20px) translateZ(0);
    }
}

.vlw .vlw__action-group {
    display: flex;
}

.vlw .vlw__action-group .vlw__button:not(:last-child), .vlw .vlw__action-group .vlw__link:not(:last-child) {
    margin-right: 15px;
}

.vlw .vlw__action-group--align-center {
    align-items: center;
}

.vlw .vlw__action-group--align-end {
    align-items: flex-end;
}

.vlw .vlw__alert-tile {
    border: 1px #e8ebee solid;
    background: #f1f2f4;
    padding: 15px;
    display: flex;
    width: 100%;
    flex: 1 0 auto;
}

.vlw .vlw__alert-tile--success {
    background: #f7fae5;
}

.vlw .vlw__alert-tile--success .vlw__alert-tile__svg {
    fill: #8bae00;
}

.vlw .vlw__alert-tile--warning {
    background: #fef9e5;
}

.vlw .vlw__alert-tile--warning .vlw__alert-tile__svg {
    fill: #ffc515;
}

.vlw .vlw__alert-tile--error {
    background: #fbe6e6;
}

.vlw .vlw__alert-tile--error .vlw__alert-tile__svg {
    fill: #db3434;
}

.vlw .vlw__alert-tile__svg {
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
    margin-right: 15px;
}

.vlw .vlw__alert-tile__content {
    line-height: 1.2;
    font-size: 14px;
}

.vlw .vlw__alert-tile__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
}

.vlw .vlw__badge {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 60px;
    color: #666;
    fill: #666;
    background: #fff;
}

.vlw .vlw__badge::before {
    content: attr(data-initials);
    position: absolute;
    display: block;
    top: 50%;
    transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #333332;
    text-transform: uppercase;
    font-weight: 500;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__badge::before {
        font-size: 20px;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__badge::before {
        font-size: 15px;
    }
}

@media screen and (max-width: 767px) {
    .vlw .vlw__badge {
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__badge {
        width: 34px;
        height: 34px;
        border-radius: 34px;
    }
}

.vlw .vlw__badge--tiny {
    width: 26px;
    height: 26px;
    border-radius: 26px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__badge--tiny {
        width: 20px;
        height: 20px;
        border-radius: 20px;
    }
}

.vlw .vlw__badge--tiny::before {
    font-size: 14px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__badge--tiny::before {
        font-size: 12px;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__badge--tiny::before {
        font-size: 10px;
    }
}

.vlw .vlw__badge--tiny .vlw__badge__warning {
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__badge--tiny .vlw__badge__warning {
        width: 30px;
        height: 30px;
    }
}

.vlw .vlw__badge--small {
    width: 43px;
    height: 43px;
    border-radius: 43px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__badge--small {
        width: 30px;
        height: 30px;
        border-radius: 30px;
    }
}

.vlw .vlw__badge--small::before {
    font-size: 20px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__badge--small::before {
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__badge--small::before {
        font-size: 12px;
    }
}

.vlw .vlw__badge--small .vlw__badge__warning {
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__badge--small .vlw__badge__warning {
        width: 15px;
        height: 15px;
    }
}

.vlw .vlw__badge--large {
    width: 90px;
    height: 90px;
    border-radius: 90px;
}

.vlw .vlw__badge--large::before {
    font-size: 36px;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__badge--large {
        width: 60px;
        height: 60px;
        border-radius: 60px;
    }
    .vlw .vlw__badge--large::before {
        font-size: 24px;
    }
}

.vlw .vlw__badge--alt {
    background: #e8ebee;
}

.vlw .vlw__badge--accent {
    background: #FFE615;
    color: #333332;
    fill: #333332;
}

.vlw .vlw__badge--success {
    background: #8bae00 !important;
    color: #fff;
    fill: #fff;
}

.vlw .vlw__badge--success::before {
    color: #fff;
}

.vlw .vlw__badge--warning {
    background: #ffc515 !important;
    color: #fff;
    fill: #fff;
}

.vlw .vlw__badge--warning::before {
    color: #fff;
}

.vlw .vlw__badge--error {
    background: #db3434 !important;
    color: #fff;
    fill: #fff;
}

.vlw .vlw__badge--error::before {
    color: #fff;
}

.vlw .vlw__badge--placeholder {
    background: #fff;
    border: 2px dashed #cbd2da;
    fill: #cbd2da;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__badge--placeholder.vlw__badge--small {
        border-width: 1px;
    }
}

.vlw .vlw__badge--placeholder::before {
    color: #cbd2da;
}

.vlw .vlw__badge--bordered {
    border: 1px solid #cbd2da;
}

.vlw .vlw__badge--overlap {
    margin-left: -10px;
}

.vlw .vlw__badge__icon {
    display: block;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
}

.vlw .vlw__badge__icon--large {
    width: 60%;
    height: 60%;
}

.vlw .vlw__badge__warning {
    display: inline-block;
    position: absolute;
    bottom: -5px;
    right: 0;
    width: 30px;
    height: 30px;
}

.vlw .vlw__badge--icon-stretched {
    overflow: hidden;
}

.vlw .vlw__badge--icon-stretched .vlw__badge__icon {
    width: 100%;
    height: 100%;
}

.vlw .vlw__button {
    display: inline-flex;
    align-items: center;
    background: #0055CC;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    padding: 7px 14px;
    appearance: none;
    border: 1px solid #0055CC;
    border-radius: 0;
    font-family: "Flanders Art Sans", sans-serif;
    cursor: default;
}

.vlw .vlw__button:hover, .vlw .vlw__button:focus {
    background: #0051C2;
    border: 1px solid #0051C2;
    color: #fff;
}

.vlw .vlw__button:active {
    background: #004CB8;
    border-color: #004CB8;
    color: #fff;
}

.vlw .vlw__button--small {
    padding: 4px 10px;
    font-size: 14px;
}

.vlw .vlw__button--disabled {
    background-color: #cbd2da;
    border-color: #cbd2da;
    color: #fff;
    cursor: not-allowed;
}

.vlw .vlw__button--disabled:hover, .vlw .vlw__button--disabled:focus, .vlw .vlw__button--disabled:active {
    background-color: #cbd2da;
    border-color: #cbd2da;
    color: #fff;
    box-shadow: none;
}

.vlw .vlw__button--focus {
    outline: none;
    box-shadow: 0 0 0 2px #ffc515;
}

.vlw .vlw__button--focus:hover {
    outline: none;
    box-shadow: 0 0 0 2px #ffc515;
}

.vlw .vlw__button--loading {
    background-color: #cbd2da;
    border-color: #cbd2da;
    position: relative;
    color: #cbd2da !important;
}

.vlw .vlw__button--loading:hover, .vlw .vlw__button--loading:focus, .vlw .vlw__button--loading:active {
    background-color: #cbd2da;
    border-color: #cbd2da;
    box-shadow: none;
}

.vlw .vlw__button--loading:hover::before, .vlw .vlw__button--loading:focus::before, .vlw .vlw__button--loading:active::before {
    background-color: #cbd2da;
}

.vlw .vlw__button--loading:visited {
    color: #cbd2da;
}

.vlw .vlw__button--loading::before {
    animation: waving-light infinite 1s linear;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.2rem;
    margin-left: -1.4rem;
    width: 0.3rem;
    height: 0.3rem;
    background-color: #cbd2da;
    border-radius: 50%;
    box-shadow: 1rem 0 #fff, 2rem 0 #fff, 3rem 0 #fff;
}

@keyframes waving-light {
    0% {
        box-shadow: 10px 0px #fff, 20px 0px #fff, 30px 0px #fff;
    }
    10% {
        box-shadow: 10px -3px #fff, 20px 0px #fff, 30px 0px #fff;
    }
    20% {
        box-shadow: 10px -6px #fff, 20px -3px #fff, 30px 0px #fff;
    }
    30% {
        box-shadow: 10px -3px #fff, 20px -6px #fff, 30px -3px #fff;
    }
    40% {
        box-shadow: 10px 0px #fff, 20px -3px #fff, 30px -6px #fff;
    }
    50% {
        box-shadow: 10px 0px #fff, 20px 0px #fff, 30px -3px #fff;
    }
    60% {
        box-shadow: 10px 0px #fff, 20px 0px #fff, 30px 0px #fff;
    }
    100% {
        box-shadow: 10px 0px #fff, 20px 0px #fff, 30px 0px #fff;
    }
}

.vlw .vlw__button[type=submit] {
    color: #fff;
    text-decoration: none;
}

.vlw .vlw__button[type=submit]:hover, .vlw .vlw__button[type=submit]:focus {
    color: #fff;
}

.vlw .vlw__button__icon {
    display: inline-block;
    vertical-align: middle;
    fill: #fff;
    width: 14px;
    height: 14px;
}

.vlw .vlw__button__icon--before {
    margin-right: 10px;
}

.vlw .vlw__button__icon--after {
    margin-left: 10px;
}

.vlw .vlw__description-data__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vlw .vlw__description-data__label, .vlw .vlw__description-data__subdata {
    display: block;
    color: #666;
    font-size: 16px;
    font-weight: 400;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__description-data__label, .vlw .vlw__description-data__subdata {
        font-size: 14px;
    }
    .vlw .vlw__description-data__label .vlw__link, .vlw .vlw__description-data__subdata .vlw__link {
        font-size: 14px;
    }
}

.vlw .vlw__description-data__value, .vlw .vlw__description-data__data {
    display: block;
    color: #333332;
    font-size: 16px;
    font-weight: 500;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__description-data__value, .vlw .vlw__description-data__data {
        font-size: 14px;
    }
}

.vlw .vlw__description-data__icon {
    position: absolute;
    top: -4px;
    left: 0;
    color: #cbd2da;
    font-size: 22px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__description-data__icon {
        top: -2px;
        font-size: 18px;
    }
}

.vlw .vlw__description-data__icon-wrapper {
    position: relative;
    padding: 0 0 0 40px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__description-data__icon-wrapper {
        padding: 0 0 0 30px;
    }
}

.vlw .vlw__description-data--bordered {
    border: 1px solid #cbd2da;
    border-width: 1px 0;
    padding: 10px 0;
}

.vlw .vlw__description-data__wrapper--alt {
    background: #e8ebee;
    padding: 10px;
}

.vlw .vlw__dropdown__content {
    position: absolute;
    z-index: 10005;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    width: 240px;
    max-width: 100vw;
    background: #fff;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__dropdown__content {
        box-shadow: 0 -3px 25px rgba(0, 0, 0, 0.2);
    }
}

.vlw .vlw__dropdown__content__layers {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    min-height: 150px;
    height: 400px;
    max-height: calc(100vh - 200px);
}

@media screen and (max-width: 500px) {
    .vlw .vlw__dropdown__content__layers {
        max-height: 50vh;
    }
}

.vlw .vlw__dropdown__content__layers--has-header:after {
    display: block;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 43px;
    background: #e8ebee;
}

.vlw .vlw__dropdown__content__layer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
}

.vlw .vlw__dropdown__content__header, .vlw .vlw__dropdown__content__footer {
    background: #e8ebee;
    padding: 12px 15px;
}

.vlw .vlw__dropdown__content__header {
    min-height: 43px;
}

.vlw .vlw__dropdown__content__header--flex {
    display: flex;
}

.vlw .vlw__dropdown__content__header__cta {
    position: relative;
    background: none;
    border: 0;
    height: 21px;
    fill: #333332;
    color: #0055CC;
    cursor: pointer;
}

.vlw .vlw__dropdown__content__header__cta:hover, .vlw .vlw__dropdown__content__header__cta:focus {
    fill: #0055CC;
}

.vlw .vlw__dropdown__content__header__cta--before {
    padding: 0;
    padding-right: 15px;
    margin-right: 15px;
}

.vlw .vlw__dropdown__content__header__cta--before::before {
    content: "";
    position: absolute;
    top: 1px;
    right: 0;
    height: 17px;
    background: #cbd2da;
    width: 1px;
}

.vlw .vlw__dropdown__content__header__cta__icon {
    width: 13px;
    height: 13px;
    fill: inherit;
}

.vlw .vlw__dropdown__content__header__title {
    display: flex;
    font-weight: 500;
    line-height: 1.2;
}

.vlw .vlw__dropdown__content__header__title__icon {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
    margin-right: 10px;
}

.vlw .vlw__dropdown__content__overflow {
    overflow: auto;
    max-height: calc(100vh - 110px);
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-overflow-scrolling: touch;
}

.vlw__dropdown--has-header.vlw__dropdown--has-footer .vlw .vlw__dropdown__content__overflow {
    max-height: calc(100vh - 154px);
}

.vlw__dropdown--has-header .vlw .vlw__dropdown__content__overflow, .vlw__dropdown--has-footer .vlw .vlw__dropdown__content__overflow {
    max-height: calc(100vh - 154px);
}

.vlw .vlw__dropdown__content__close {
    display: none;
    position: absolute;
    top: 1px;
    right: 0;
    z-index: 10006;
    padding: 15px;
    fill: #333332;
    border: 0;
    appearance: none;
    background: none;
    cursor: pointer;
    line-height: 1;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__dropdown__content__close {
        display: block;
    }
}

.vlw .vlw__dropdown__content__close:hover, .vlw .vlw__dropdown__content__close:focus {
    fill: #0055CC;
}

.vlw .vlw__dropdown__content__close__icon {
    width: 12px;
    height: 12px;
    fill: inherit;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__dropdown__list__item:first-of-type {
        max-width: calc(100% - 45px);
    }
}

.vlw .vlw__dropdown__list__item__cta {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    color: #0055CC;
    text-decoration: none;
    font-size: 15px;
    line-height: 1.2;
}

.vlw .vlw__dropdown__list__item__cta[href]:hover, .vlw .vlw__dropdown__list__item__cta[href]:focus {
    color: #0055CC;
    text-decoration: underline;
    cursor: pointer;
}

.vlw .vlw__dropdown__list__item__cta--current {
    color: #333332;
    font-weight: 500;
}

.vlw .vlw__dropdown__list__item__cta__icon {
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
}

.vlw .vlw__dropdown__list__item__cta__icon--before {
    margin-right: 5px;
}

.vlw .vlw__dropdown__list__separator {
    height: 1px;
    background: #e8ebee;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
    width: calc(100% - (2*15px));
}

.vlw .vlw__icon--color-warning {
    fill: #cc6e2a;
}

.vlw .vlw__iframe {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    background: #fff;
    margin: 10px;
    position: fixed;
    left: 0;
    top: 43px;
    right: 0;
    bottom: 0;
}

.vlw .vlw__iframe__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 auto;
    width: 100%;
    height: 44px;
    background: #f1f2f4;
    border-bottom: 1px #cbd2da solid;
    padding: 0 0 0 15px;
    font-weight: 500;
}

.vlw .vlw__iframe__header__close {
    display: block;
    width: 46px;
    line-height: 1;
    padding: 15px;
}

.vlw .vlw__iframe__header__close--icon {
    display: block;
    fill: inherit;
}

.vlw .vlw__iframe__content {
    height: calc(100% - 44px);
    width: 100%;
}

.vlw .vlw__link {
    display: inline-flex;
    font-size: 16px;
    line-height: 1;
    text-decoration: underline;
    background: none;
    border: none;
    font-family: "Flanders Art Sans", sans-serif;
    cursor: pointer;
}

.vlw .vlw__link:hover, .vlw .vlw__link:focus {
    text-decoration: none;
}

.vlw .vlw__link:hover {
    color: #0051C2;
}

.vlw .vlw__link:focus, .vlw .vlw__link:active {
    color: #004CB8;
}

.vlw .vlw__link--small {
    font-size: 14px;
}

.vlw .vlw__link--small .vlw__link__icon {
    flex: 0 0 10px;
    width: 10px;
    height: 10px;
}

.vlw .vlw__link--medium {
    font-weight: 500;
}

.vlw .vlw__link--alt .vlw__link__icon {
    fill: #333332;
}

.vlw .vlw__link__label {
    text-decoration: none;
}

.vlw .vlw__link__annotation {
    font-size: 14px;
    font-weight: 400;
    color: #333332;
    line-height: inherit;
}

.vlw .vlw__link--icon, .vlw .vlw__link--arrow {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.vlw .vlw__link--icon {
    justify-content: flex-start;
}

.vlw .vlw__link--block {
    display: flex;
    width: 100%;
}

.vlw .vlw__link__icon {
    flex: 0 0 14px;
    width: 14px;
    height: 14px;
    margin-right: 5px;
    fill: #0055CC;
}

.vlw .vlw__link__icon--before {
    margin-right: 5px;
}

.vlw .vlw__link__icon--after {
    margin-left: 5px;
}

.vlw .vlw__link__icon--alt {
    fill: #333332 !important;
}

.vlw .vlw__link__icon--small {
    flex: 0 0 10px;
    width: 10px;
    height: 10px;
}

.vlw .vlw__link__icon--large {
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
}

.vlw .vlw__link__arrow {
    flex: 0 0 12px;
    margin-left: auto;
    fill: #000;
    width: 12px;
    height: 12px;
}

.vlw .vlw__loader {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 10px;
}

.vlw .vlw__loader::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    margin-left: -24px;
    width: 4px;
    height: 4px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    box-shadow: 10px 0px #666, 20px 0px #666, 30px 0px #666;
    animation: loader-wave infinite 1s linear;
    animation: loader-wave infinite 1s linear;
}

@keyframes loader-wave {
    0% {
        box-shadow: 10px 0px #666, 20px 0px #666, 30px 0px #666;
    }
    10% {
        box-shadow: 10px -3px #666, 20px 0px #666, 30px 0px #666;
    }
    20% {
        box-shadow: 10px -6px #666, 20px -3px #666, 30px 0px #666;
    }
    30% {
        box-shadow: 10px -3px #666, 20px -6px #666, 30px -3px #666;
    }
    40% {
        box-shadow: 10px 0px #666, 20px -3px #666, 30px -6px #666;
    }
    50% {
        box-shadow: 10px 0px #666, 20px 0px #666, 30px -3px #666;
    }
    60% {
        box-shadow: 10px 0px #666, 20px 0px #666, 30px 0px #666;
    }
    100% {
        box-shadow: 10px 0px #666, 20px 0px #666, 30px 0px #666;
    }
}

.vlw .vlw__overlay {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(232, 235, 238, 0.7);
}

.vlw .vlw__popover {
    display: inline-block;
    position: relative;
}

.vlw .vlw__popover--align-right .vlw__popover__content {
    right: -18px;
}

.vlw .vlw__popover--align-right .vlw__popover__content .vlw__popover__arrow {
    right: 12px;
    left: auto;
}

.vlw .vlw__popover__content {
    position: absolute;
    z-index: 10005;
    top: calc(100% + 6px);
    display: inline-block;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    width: 240px;
    max-width: 100vw;
    background: #fff;
}

.vlw .vlw__popover__content--large {
    width: 450px;
}

.vlw .vlw__popover__content--large .vlw__dropdown__content:before {
    border-color: transparent transparent #e8ebee transparent;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__popover__content {
        box-shadow: 0 -3px 25px rgba(0, 0, 0, 0.2);
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__popover__content {
        position: fixed;
        top: auto;
        left: 5px;
        right: 5px !important;
        bottom: 5px;
        width: calc(100% - 10px);
        max-height: 60vh;
    }
}

.vlw .vlw__popover__content .vlw__popover__arrow {
    display: block;
    content: "";
    position: absolute;
    left: 2px;
    top: -10px;
    border: 12px solid transparent;
    border-bottom-color: #fff;
    border-top-width: 0;
}

.vlw .vlw__popover__content .vlw__popover__arrow--alt {
    border-bottom-color: #e8ebee;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__popover__content .vlw__popover__arrow {
        display: none;
    }
}

.vlw .vlw__toaster {
    z-index: 10006;
    position: fixed;
    max-height: calc(100vh - (43px + 5px));
    overflow: auto;
    padding: 15px;
    transition: transform 0.3s ease-in-out;
    top: 0;
    right: 0;
}

.vlw .vlw__toaster__item {
    display: flex;
    position: relative;
    width: 300px;
    border: 1px #cbd2da solid;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    padding: 10px;
    background: #e8ebee;
}

.vlw .vlw__toaster__item:not(:last-of-type) {
    margin-bottom: 5px;
}

.vlw .vlw__toaster__item--success {
    background: #f7fae5;
}

.vlw .vlw__toaster__item--success .vlw__toaster__item__img {
    background: #8bae00;
}

.vlw .vlw__toaster__item--warning {
    background: #fef9e5;
}

.vlw .vlw__toaster__item--warning .vlw__toaster__item__img {
    background: #ffc515;
}

.vlw .vlw__toaster__item--error {
    background: #fbe6e6;
}

.vlw .vlw__toaster__item--error .vlw__toaster__item__img {
    background: #db3434;
}

.vlw .vlw__toaster__item__close {
    position: absolute;
    z-index: 2;
    top: 4px;
    right: 4px;
    padding: 8px;
    border: 0;
    background: none;
    fill: #333332;
    cursor: pointer;
}

.vlw .vlw__toaster__item__close:hover, .vlw .vlw__toaster__item__close:focus {
    fill: #0055CC;
}

.vlw .vlw__toaster__item__close__icon {
    width: 11px;
    height: 11px;
    fill: inherit;
}

.vlw .vlw__toaster__item__content {
    width: 100%;
}

.vlw .vlw__toaster__item__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 10px;
    max-width: calc(100% - 20px);
}

.vlw .vlw__toaster__item__message {
    position: relative;
    z-index: 1;
}

.vlw .vlw__toaster__item__actions {
    margin-top: 15px;
}

.vlw .vlw__toaster__item__img {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #cbd2da;
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 10px;
}

.vlw .vlw__toaster__item__img__icon {
    fill: #fff;
    width: 12px;
    height: 12px;
}

.vlw .vlw-transition-toaster-fade-enter-active {
    transition: opacity 0.3s ease;
}

.vlw .vlw-transition-toaster-fade-leave-active {
    transition: opacity 0.3s ease;
}

.vlw .vlw-transition-toaster-fade-enter, .vlw .vlw-transition-toaster-fade-leave-to {
    opacity: 0;
}

.vlw .vlw__contact {
    display: flex;
    height: 100%;
    transition: width 0.2s ease-in-out, background 0.2s ease-in-out;
}

.vlw .vlw__contact--primary, .vlw .vlw__contact--secondary, .vlw .vlw__contact--tertiary {
    margin-left: 14px;
}

.vlw .vlw__contact--primary .vlw__contact__toggle, .vlw .vlw__contact--secondary .vlw__contact__toggle, .vlw .vlw__contact--tertiary .vlw__contact__toggle {
    background: #FFE615;
}

.vlw .vlw__contact--primary .vlw__contact__toggle::after, .vlw .vlw__contact--secondary .vlw__contact__toggle::after, .vlw .vlw__contact--tertiary .vlw__contact__toggle::after {
    content: "";
    position: absolute;
    top: 0;
    left: -13px;
    width: 0;
    height: 0;
    border-top: 0 solid transparent;
    border-bottom: 43px solid transparent;
    border-right: 13.5px solid #FFE615;
    transition: border-color 0.2s ease-in-out;
}

.vlw .vlw__contact--primary .vlw__contact__toggle:hover, .vlw .vlw__contact--primary .vlw__contact__toggle:focus, .vlw .vlw__contact--secondary .vlw__contact__toggle:hover, .vlw .vlw__contact--secondary .vlw__contact__toggle:focus, .vlw .vlw__contact--tertiary .vlw__contact__toggle:hover, .vlw .vlw__contact--tertiary .vlw__contact__toggle:focus {
    background: #FFE507;
    color: #0055CC;
}

.vlw .vlw__contact--primary .vlw__contact__toggle:hover::after, .vlw .vlw__contact--primary .vlw__contact__toggle:focus::after, .vlw .vlw__contact--secondary .vlw__contact__toggle:hover::after, .vlw .vlw__contact--secondary .vlw__contact__toggle:focus::after, .vlw .vlw__contact--tertiary .vlw__contact__toggle:hover::after, .vlw .vlw__contact--tertiary .vlw__contact__toggle:focus::after {
    border-right-color: #FFE507;
}

.vlw .vlw__contact--quaternary {
    background: #FFE615;
}

.vlw .vlw__contact--quaternary:hover, .vlw .vlw__contact--quaternary:focus {
    background: #FFE507;
}

.vlw .vlw__contact__toggle {
    position: relative;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    color: #333332;
    transition: width 0.2s ease-in-out, background 0.2s ease-in-out;
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__contact__toggle {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.vlw .vlw__contact__toggle__icon {
    width: 20px;
    height: 20px;
    transition: fill 0.1s ease-in-out;
    fill: #333;
    pointer-events: none;
}

.vlw .vlw__contact__toggle__label {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: #333;
    padding-right: 10px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__contact__toggle__label {
        position: absolute !important;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }
}

.vlw .vlw__contact__toggle:hover .vlw__contact__toggle__icon, .vlw .vlw__contact__toggle:focus .vlw__contact__toggle__icon {
    opacity: 1;
}

.vlw .vlw__contact-panel {
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 100%;
    max-width: 370px;
    height: calc(100% - (43px + 15px));
    position: fixed;
    z-index: 10005;
    top: calc(43px + 15px);
    right: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 500px) {
    .vlw .vlw__contact-panel {
        max-width: 100%;
        height: calc(100% - 10px);
        top: 10px;
    }
}

.vlw .vlw__contact-panel::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px;
    border-color: transparent transparent #fff;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__contact-panel::before {
        right: 10px;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__contact-panel::before {
        display: none;
    }
}

.vlw .vlw__contact-panel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    width: 100%;
    height: 56px;
    background: #fff;
    border-bottom: 1px #cbd2da solid;
    padding: 18px 15px;
    font-weight: 500;
}

.vlw .vlw__contact-panel__header__title {
    display: inline-block;
    max-width: 90%;
    color: #333332;
    line-height: 56px;
    height: 56px;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.vlw .vlw__contact-panel__header__close {
    display: block;
    width: 16px;
    line-height: 1;
    border: none;
    background: none;
    height: 16px;
    padding: 0;
    cursor: pointer;
    margin: 0;
    margin-right: 2px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__contact-panel__header__close {
        margin-right: -3px;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__contact-panel__header__close {
        margin-right: 0;
    }
}

.vlw .vlw__contact-panel__header__close__icon {
    display: block;
    fill: inherit;
}

.vlw .vlw__contact-panel__header__close:hover {
    fill: #0055CC;
}

.vlw .vlw__contact-panel__header__close:focus, .vlw .vlw__contact-panel__header__close:active {
    fill: #004CB8;
}

.vlw .vlw__contact-panel__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
}

.vlw .vlw__contact-panel__scroll {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
}

.vlw .vlw__contact-panel__layout {
    flex: 0 0 auto;
}

.vlw .vlw__contact-panel__region {
    flex: 0 0 auto;
}

.vlw .vlw__contact-panel__detail {
    flex: 1 0 auto;
}

.vlw .vlw__contact-panel__badge {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    border: 1px #e8ebee solid;
}

.vlw .vlw__contact-panel__badge--accent {
    background: #FFE615;
}

.vlw .vlw__contact-panel__badge__icon {
    width: 14px;
    height: 14px;
    fill: #333332;
}

.vlw .vlw__contact-panel__badge__icon--light {
    fill: #fff;
}

.vlw .vlw__contact-panel__chat {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    position: relative;
    margin: 0 15px;
    overflow: hidden;
    transform: translateZ(0);
}

.vlw .vlw__contact-panel__chat--v-center {
    flex: 1;
    justify-content: center;
}

.vlw .vlw__contact-panel__chat__close {
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.vlw .vlw__contact-panel__chat__message-window {
    display: flex;
    position: relative;
    left: -15px;
    width: calc(100% + 30px);
    padding: 15px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: baseline;
    flex: 0 0 auto;
}

.vlw .vlw__contact-panel__chat__message {
    display: block;
    max-width: 100%;
    line-height: 1.3;
    font-weight: 400;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: calc(100% - 15px);
}

@supports (overflow-wrap: break-word) {
    .vlw .vlw__contact-panel__chat__message {
        overflow-wrap: break-word;
        word-break: normal;
    }
}

.vlw .vlw__contact-panel__chat__message.vlw__alert-tile {
    display: flex;
}

.vlw .vlw__contact-panel__chat__message--you {
    background: #e4f0ad;
    border-radius: 15px 0 15px 15px;
    margin-left: auto;
}

.vlw .vlw__contact-panel__chat__message--you:not(:last-of-type) {
    margin-top: 5px;
    margin-bottom: 5px;
}

.vlw .vlw__contact-panel__chat__message--them {
    background: #e8ebee;
    border-radius: 0 15px 15px 15px;
    margin-right: auto;
    max-width: calc(100% - 15px);
}

.vlw .vlw__contact-panel__chat__message--has-action .vlw__contact-panel__chat__message__item:last-of-type {
    padding-bottom: 0;
}

.vlw .vlw__contact-panel__chat__message__item {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
}

.vlw .vlw__contact-panel__chat__message__item:first-child {
    padding-top: 10px;
}

.vlw .vlw__contact-panel__chat__message__item:last-child {
    border-radius: 0 0 15px 15px;
}

.vlw .vlw__contact-panel__chat__message__item--action {
    padding: 0;
    border-left: 2px #e8ebee solid;
    border-right: 2px #e8ebee solid;
    border-bottom: 2px #e8ebee solid;
    overflow: hidden;
}

.vlw .vlw__contact-panel__chat__message__item--action:last-of-type .vlw__contact-panel__chat__message__item__action {
    border-radius: 0 0 15px 15px;
}

.vlw .vlw__contact-panel__chat__message__item__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    position: relative;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 500;
    text-decoration: none;
}

.vlw .vlw__contact-panel__chat__message__item__action:hover, .vlw .vlw__contact-panel__chat__message__item__action:focus {
    text-decoration: underline;
}

.vlw .vlw__contact-panel__chat__actions {
    padding: 10px 0;
    margin: 0 15px;
    border-top: 1px #e8ebee solid;
}

.vlw .vlw__contact-panel__chat__input {
    border-top: 1px #e8ebee solid;
    margin: 0;
}

.vlw .vlw__contact-panel__chat__input__actions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
}

.vlw .vlw__contact-panel__chat__input__action {
    position: absolute;
    bottom: 34px;
    left: 15px;
    z-index: 1;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__contact-panel__chat__input__action {
        bottom: 20px;
    }
}

.vlw .vlw__contact-panel__chat__input__action--send {
    left: auto;
    right: 15px;
    padding: 0;
}

.vlw .vlw__contact-panel__chat__input__popover .vlw__contact-panel__popover__content {
    left: -7.5px;
}

.vlw .vlw__contact-panel__chat__input__field.vlw__contact-panel__textarea {
    flex: 1;
    min-height: 76px;
    padding: 17px 60px 19px 55px;
    border-color: transparent;
    -ms-overflow-style: none;
    line-height: 1.2;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__contact-panel__chat__input__field.vlw__contact-panel__textarea {
        min-height: 33px;
        padding: 10px 60px 10px 55px;
    }
}

.vlw .vlw__contact-panel__chat__input__field.vlw__contact-panel__textarea:focus, .vlw .vlw__contact-panel__chat__input__field.vlw__contact-panel__textarea--focus, .vlw .vlw__contact-panel__chat__input__field.vlw__contact-panel__textarea--focus:hover {
    outline: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
    background: #f1f2f4;
}

.vlw .vlw__contact-panel__chat-bubble {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    bottom: 15px;
    right: 15px;
    height: 65px;
    border-radius: 65px;
    padding: 0 5px;
    background: #fff;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease-in-out;
}

.vlw .vlw__contact-panel__chat-bubble--open {
    right: 385px;
}

.vlw .vlw__contact-panel__chat-bubble__divider {
    display: inline-block;
    width: 1px;
    height: 45px;
    background: #d3d9e0;
    margin: 0 3px;
}

.vlw .vlw__contact-panel__chat-bubble>* {
    flex: 1 0 auto;
    margin: 0 5px;
}

.vlw .vlw__contact-panel__chat-bubble .vlw__alert-tile {
    width: auto;
    max-width: 340px;
    border-radius: 55px 0 0 55px;
}

.vlw .vlw__contact-panel__chat-bubble .vlw__alert-tile__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vlw .vlw__contact-panel__circular-button {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    border: none;
    width: 28px;
    height: 28px;
    flex: 0 0 28px;
    background: #0055CC;
    border-radius: 3rem;
    transition: background 0.1s ease-in-out;
    cursor: pointer;
    fill: #fff;
}

.vlw .vlw__contact-panel__circular-button:hover {
    background: #0051C2;
}

.vlw .vlw__contact-panel__circular-button:focus, .vlw .vlw__contact-panel__circular-button:active {
    background: #004CB8;
}

.vlw .vlw__contact-panel__circular-button--alt {
    background: #cbd2da;
}

.vlw .vlw__contact-panel__circular-button--alt:focus, .vlw .vlw__contact-panel__circular-button--alt:active {
    background: #004CB8;
}

.vlw .vlw__contact-panel__circular-button--full {
    fill: #0055CC;
    background: transparent;
}

.vlw .vlw__contact-panel__circular-button--full:hover {
    fill: #0051C2;
    background: transparent;
}

.vlw .vlw__contact-panel__circular-button--full:focus, .vlw .vlw__contact-panel__circular-button--full:active {
    fill: #004CB8;
    background: transparent;
}

.vlw .vlw__contact-panel__circular-button--full .vlw__contact-panel__circular-button__icon {
    width: 28px;
    height: 28px;
}

.vlw .vlw__contact-panel__circular-button__icon {
    width: 12px;
    height: 12px;
    fill: inherit;
    transition: 0.35s ease;
}

.vlw .vlw__contact-panel__feedback {
    padding: 15px 0;
}

.vlw .vlw__contact-panel__feedback__icon {
    width: 48px;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__contact-panel__feedback__icon {
        width: 28px;
    }
}

.vlw .vlw__contact-panel__feedback__stars {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
}

.vlw .vlw__contact-panel__feedback__stars__input--selected+.vlw__contact-panel__feedback__stars__image {
    fill: #8bae00;
}

.vlw .vlw__contact-panel__feedback__stars__image {
    transition: fill 0.1s linear;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    fill: #cbd2da;
}

.vlw .vlw__contact-panel__feedback__stars__image:hover, .vlw .vlw__contact-panel__feedback__stars__image:focus {
    fill: #8bae00;
}

.vlw .vlw__contact-panel__checkbox {
    display: inline-block;
    font-size: 16px;
    margin: 0 50px 2px 30px;
    position: relative;
    min-height: 20px;
}

.vlw .vlw__contact-panel__checkbox.vlw__contact-panel__checkbox--block {
    display: block;
    margin: 0 0 0 30px;
}

.vlw .vlw__contact-panel__checkbox.vlw__contact-panel__checkbox--disabled {
    color: #666;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__contact-panel__checkbox {
        font-size: 14px;
    }
}

.vlw .vlw__contact-panel__checkbox__toggle {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
}

.vlw .vlw__contact-panel__checkbox__toggle:focus+span {
    border-color: #ffc515;
    outline: 2px solid #ffc515;
}

.vlw .vlw__contact-panel__checkbox__toggle:checked+span {
    background: #0055CC;
    border: 1px #0055CC solid;
}

.vlw .vlw__contact-panel__checkbox__toggle:checked+span .vlw__contact-panel__checkbox__toggle__icon {
    fill: white;
    transform: translateZ(0) scale(1);
}

.vlw .vlw__contact-panel__checkbox__toggle+span {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #fff;
    border: 1px #cbd2da solid;
    outline: 2px transparent solid;
    top: 4px;
    left: -28px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9);
    white-space: nowrap;
    margin: 0 5px 0 0;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__contact-panel__checkbox__toggle+span {
        top: 2px;
    }
}

.vlw .vlw__contact-panel__checkbox--disabled .vlw__contact-panel__checkbox__toggle+span {
    background-color: #f4f5f7;
    border-color: #cbd2da;
}

.vlw .vlw__contact-panel__checkbox--error .vlw__contact-panel__checkbox__toggle+span {
    background-color: #fbe6e6;
    border-color: #db3434;
}

.vlw .vlw__contact-panel__checkbox__toggle__icon {
    width: 14px;
    height: 14px;
    transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9) 0.1s;
    transform: translateZ(0) scale(0);
    fill: #0055CC;
}

.vlw .vlw__contact-panel__checkbox--disabled .vlw__contact-panel__checkbox__toggle:checked+span {
    background: #f4f5f7;
    border: 1px #cbd2da solid;
}

.vlw .vlw__contact-panel__checkbox--disabled .vlw__contact-panel__checkbox__toggle:checked+span .vlw__contact-panel__checkbox__toggle__icon {
    fill: #f4f5f7;
}

.vlw .vlw__contact-panel__form__error {
    color: #db3434;
    font-size: 14px;
}

.vlw .vlw__contact-panel__input-field {
    border: 1px solid #cbd2da;
    border-radius: 0;
    display: inline-block;
    max-width: 100%;
    color: #333332;
    background: #fff;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    font-family: "Flanders Art Sans", sans-serif;
    text-indent: 0;
    appearance: none;
    padding: 6px 10px;
}

.vlw .vlw__contact-panel__input-field:hover {
    box-shadow: none;
}

.vlw .vlw__contact-panel__input-field::placeholder {
    color: #b2b2b2;
}

.vlw .vlw__contact-panel__input-field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.vlw .vlw__contact-panel__input-field:focus:valid::-webkit-search-cancel-button::after {
    content: "";
    display: inline-block;
    font-style: italic;
    width: 11px;
    height: 11px;
    margin-right: 0.7rem;
    background-position: center;
    background-repeat: no-repeat;
    background-image: inline-image("icon-cross-2x.png", "png");
    background-size: 9px;
    opacity: 0.6;
}

.vlw .vlw__contact-panel__input-field--block {
    display: block;
    width: 100%;
}

.vlw .vlw__contact-panel__label {
    font-size: 14px;
    font-family: "Flanders Art Sans", sans-serif;
    font-weight: 500;
    line-height: 1.2;
    display: inline-block;
}

.vlw .vlw__contact-panel__label--block {
    display: block;
    width: 100%;
}

.vlw .vlw__contact-panel__label--placeholder {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    font-family: "Flanders Art Sans", sans-serif;
    font-weight: 400;
    color: #b2b2b2;
}

.vlw .vlw__contact-panel__label-as-placeholder {
    position: relative;
    width: 100%;
}

.vlw .vlw__contact-panel__label-as-placeholder:first-child .vlw__contact-panel__label--placeholder {
    margin-left: 15px;
}

.vlw .vlw__contact-panel__label-as-placeholder:first-child .vlw__contact-panel__chat__input__field {
    padding-left: 15px;
}

.vlw .vlw__contact-panel__label-as-placeholder .vlw__contact-panel__label--placeholder {
    font-size: 16px;
    margin: 18px 10px 10px 55px;
    transition: 0.2s linear;
    cursor: text;
    user-select: none;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__contact-panel__label-as-placeholder .vlw__contact-panel__label--placeholder {
        margin: 10px 10px 10px 55px;
    }
}

.vlw .vlw__contact-panel__label-as-placeholder .vlw__contact-panel__label__input-field {
    transition: background 0.2s linear;
}

.vlw .vlw__contact-panel__radio {
    display: inline-block;
    margin: 0 5px 2px 30px;
    position: relative;
}

.vlw .vlw__contact-panel__radio.vlw__contact-panel__radio--block {
    display: block;
    margin: 0 0 0 30px;
}

.vlw .vlw__contact-panel__radio.vlw__contact-panel__radio--disabled {
    color: #cbd2da;
}

.vlw .vlw__contact-panel__radio.vlw__contact-panel__radio--disabled .vlw__contact-panel__radio__toggle:checked+span {
    background: #cbd2da;
    color: #cbd2da;
    border: 1px #cbd2da solid;
}

.vlw .vlw__contact-panel__radio.vlw__contact-panel__radio-radio--single {
    margin: 0;
}

.vlw .vlw__contact-panel__radio.vlw__contact-panel__radio-radio--single .vlw__contact-panel__radio__toggle+span {
    position: relative;
    top: auto;
    left: auto;
    margin: 0;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__contact-panel__radio {
        font-size: 14px;
    }
}

.vlw .vlw__contact-panel__radio__toggle {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
}

.vlw .vlw__contact-panel__radio__toggle:checked+span {
    background: #0055CC;
    color: #0055CC;
    border: 1px #0055CC solid;
}

.vlw .vlw__contact-panel__radio__toggle:checked+span::before {
    transform: translateZ(0) translate(-50%, -50%) scale(1);
}

.vlw .vlw__contact-panel__radio__toggle+span {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    text-indent: -990px;
    background: #fff;
    border: 1px #cbd2da solid;
    top: 4px;
    left: -28px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9);
    white-space: nowrap;
    margin: 0 5px 0 0;
}

.vlw .vlw__contact-panel__radio__toggle+span::before {
    content: "";
    position: absolute;
    display: block;
    height: 6px;
    width: 6px;
    top: 50%;
    left: 50%;
    background: #fff;
    border-radius: 100%;
    transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9) 0.05s;
    transform: translateZ(0) translate(-50%, -50%) scale(0);
}

@media screen and (max-width: 767px) {
    .vlw .vlw__contact-panel__radio__toggle+span {
        top: 2px;
    }
}

.vlw .vlw__contact-panel__radio--error .vlw__contact-panel__radio__toggle+span {
    background-color: #fbe6e6;
    border-color: #db3434;
}

.vlw .vlw__contact-panel__radio--disabled .vlw__contact-panel__radio__toggle+span {
    background-color: #f4f5f7;
    border-color: #cbd2da;
}

.vlw .vlw__contact-panel__radio__toggle:focus+span, .vlw .vlw__contact-panel__radio__toggle:focus:checked+span {
    border-color: #ffc515 !important;
}

.vlw .vlw__contact-panel__select {
    display: inline-block;
    position: relative;
    padding: 0 15px;
    padding-right: 60px;
    max-width: 100%;
    height: 35px;
    line-height: 35px;
    appearance: none;
    border: 1px solid #cbd2da;
    border-radius: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #fff;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 26px 7px;
    background-image: url("data:png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAOCAYAAABgmT8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDNFMkMzRjlDRjJEMTFFNUIyQzZFODNGQkM5ODE4QjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDNFMkMzRkFDRjJEMTFFNUIyQzZFODNGQkM5ODE4QjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEM0UyQzNGN0NGMkQxMUU1QjJDNkU4M0ZCQzk4MThCOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEM0UyQzNGOENGMkQxMUU1QjJDNkU4M0ZCQzk4MThCOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgmTEjgAAAEBSURBVHja1JavDsIwEMYPgUXhwEwgEAgMbgJHeFAeAMkToGdmlsxMkCwkS5bMIMpdck2apv+YuXLJ53q377f2rgUAKFEtqkGdYV6cUDWq0zWUUiKiqOj7rGkGFMEMRo1OGsg0QxrZZEocUG8r/4NaSgLdLEOKIY8RmB2qd+TepXdohXo6jJHZvQem4KNl59DxXUsDaajKYbDjnTBjywPEXltrmByAgM3UDqMtQ6SuyQaIYuP5+w0fP98uFnahXIBC/aF+6bOcgPQEe0VgBh7b8A9A+o7pAzDBuypHINcrQL8mylhirkDA5kcD5pKSJAW0SHziUE9dUQ8e20lAEvEVYAD2OwgvYjKQFwAAAABJRU5ErkJggg==");
    text-decoration: none;
    color: #333332;
    font-family: "Flanders Art Sans", sans-serif;
    font-size: 16px;
}

.vlw .vlw__contact-panel__select::-ms-expand {
    display: none;
}

.vlw .vlw__contact-panel__select[disabled] {
    color: #cbd2da;
    border-color: #cbd2da;
    background-color: #f4f5f7;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__contact-panel__select {
        height: 35px;
        line-height: 35px;
        font-size: 15px;
    }
}

.vlw .vlw__contact-panel__select:focus {
    border-color: #ffc515 !important;
    box-shadow: inset 0 0 0 1px #ffc515 !important;
    outline: none;
}

.vlw .vlw__contact-panel__select--block {
    display: block;
    width: 100%;
}

@media all and (min-width: 0\\0) and (min-resolution: 0.001dpcm) {
    .vlw select.vlw__contact-panel__select {
        padding-right: 0;
        background-image: none;
    }
}

.vlw .vlw__contact-panel__textarea {
    border: 1px solid #cbd2da;
    border-radius: 0;
    display: inline-block;
    max-width: 100%;
    color: #333332;
    background: #fff;
    height: auto;
    line-height: 1.2;
    font-size: 16px;
    font-family: "Flanders Art Sans", sans-serif;
    text-indent: 0;
    appearance: none;
    padding: 6px 10px;
}

.vlw .vlw__contact-panel__textarea:hover {
    box-shadow: none;
}

.vlw .vlw__contact-panel__textarea::placeholder {
    color: #b2b2b2;
}

.vlw .vlw__contact-panel__textarea::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.vlw .vlw__contact-panel__textarea:focus:valid::-webkit-search-cancel-button::after {
    content: "";
    display: inline-block;
    font-style: italic;
    width: 11px;
    height: 11px;
    margin-right: 0.7rem;
    background-position: center;
    background-repeat: no-repeat;
    background-image: inline-image("icon-cross-2x.png", "png");
    background-size: 9px;
    opacity: 0.6;
}

.vlw .vlw__contact-panel__textarea--block {
    display: block;
    width: 100%;
}

.vlw .vlw__contact-panel__form-element--error {
    background-color: #fbe6e6;
    border-color: #db3434;
    box-shadow: inset 0 0 0 1px #db3434;
}

.vlw .vlw__contact-panel__form-element--error:focus {
    background-color: #fff;
}

.vlw .vlw__contact-panel__form-element--disabled {
    background-color: #f4f5f7;
}

.vlw .vlw__contact-panel__form-element:focus {
    outline: none;
    border-color: #ffc515;
    box-shadow: inset 0 0 0 1px #ffc515;
}

.vlw .vlw__contact-panel__form-element--focus {
    outline: none;
    border-color: #ffc515;
    box-shadow: inset 0 0 0 1px #ffc515;
}

.vlw .vlw__contact-panel__form-element--focus:hover {
    outline: none;
    border-color: #ffc515;
    box-shadow: inset 0 0 0 1px #ffc515;
}

.vlw .vlw__contact-panel__region {
    position: relative;
    padding: 15px 0;
}

.vlw .vlw__contact-panel__region--alt {
    background: #e8ebee;
}

.vlw .vlw__contact-panel__region--full {
    position: relative;
    left: -15px;
    width: calc(100% + 30px);
}

.vlw .vlw__contact-panel__region--stretch {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    flex: 1;
}

.vlw .vlw__contact-panel__region--no-padding-bottom {
    padding-bottom: 0;
}

.vlw .vlw__contact-panel__layout {
    position: relative;
    padding: 0 15px;
}

.vlw .col--flex>.grid {
    min-width: 100%;
}

.vlw body:after {
    box-sizing: border-box;
    position: fixed;
    display: block;
    bottom: 10px;
    right: 10px;
    padding: 5px 28px 5px 20px;
    font-size: 12px;
    color: #777;
    border-radius: 3px;
    background-color: #f7f7f7;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    z-index: 100000;
}

.vlw .vlw__contact-panel__grid {
    position: relative;
    box-sizing: border-box;
    display: flex;
    margin-left: -15px;
    flex-direction: row;
    flex: 0 1 auto;
    flex-wrap: wrap;
}

.vlw .vlw__contact-panel__grid>* {
    box-sizing: border-box;
    padding-left: 15px;
    position: relative;
}

.vlw .vlw__contact-panel__grid--no-gutter {
    margin-left: 0;
}

.vlw .vlw__contact-panel__grid--no-gutter>* {
    padding-left: 0;
}

.vlw .vlw__contact-panel__grid--v-top {
    align-items: flex-start;
}

.vlw .vlw__contact-panel__grid--v-center {
    align-items: center;
}

.vlw .vlw__contact-panel__grid--v-bottom {
    align-items: flex-end;
}

.vlw .vlw__contact-panel__grid--v-stretch {
    align-items: stretch;
}

.vlw .vlw__contact-panel__grid--v-baseline {
    align-items: stretch;
}

.vlw .vlw__contact-panel__grid--align-start {
    justify-content: flex-start;
}

.vlw .vlw__contact-panel__grid--align-end {
    justify-content: flex-end;
}

.vlw .vlw__contact-panel__grid--align-center {
    justify-content: center;
}

.vlw .vlw__contact-panel__grid--align-space-between {
    justify-content: space-between;
}

.vlw .vlw__contact-panel__grid--align-space-around {
    justify-content: space-around;
}

.vlw .vlw__contact-panel__col--fit {
    flex: 1 0;
}

.vlw .vlw__contact-panel__col--flex {
    display: flex;
}

.vlw .vlw__contact-panel__col--wide-content {
    margin-left: -15px;
    margin-right: -15px;
}

.vlw .vlw__contact-panel__col--1-2 {
    flex-basis: 50%;
    max-width: 50%;
    min-width: 50%;
}

.vlw .vlw__contact-panel__col--1-1, .vlw .vlw__contact-panel__col--2-2 {
    flex-basis: 100%;
    max-width: 100%;
    min-width: 100%;
}

.vlw .vlw__contact-panel__grid--is-stacked {
    margin-top: -15px;
}

.vlw .vlw__contact-panel__grid--is-stacked>* {
    margin-top: 15px;
}

.vlw .vlw__contact-panel__col--omega {
    margin-left: auto;
}

.vlw body:after {
    content: "mygrid";
    background: linear-gradient(to right, #f7f7f7, #f7f7f7 90%, #000 90%, #000 100%);
}

.vlw .vlw__contact-panel__form-grid {
    position: relative;
    box-sizing: border-box;
    display: flex;
    margin-left: -7.5px;
    flex-direction: row;
    flex: 0 1 auto;
    flex-wrap: wrap;
}

.vlw .vlw__contact-panel__form-grid>* {
    box-sizing: border-box;
    padding-left: 7.5px;
    position: relative;
}

.vlw .vlw__contact-panel__form-grid--no-gutter {
    margin-left: 0;
}

.vlw .vlw__contact-panel__form-grid--no-gutter>* {
    padding-left: 0;
}

.vlw .vlw__contact-panel__form-grid--v-top {
    align-items: flex-start;
}

.vlw .vlw__contact-panel__form-grid--v-center {
    align-items: center;
}

.vlw .vlw__contact-panel__form-grid--v-bottom {
    align-items: flex-end;
}

.vlw .vlw__contact-panel__form-grid--v-stretch {
    align-items: stretch;
}

.vlw .vlw__contact-panel__form-grid--v-baseline {
    align-items: stretch;
}

.vlw .vlw__contact-panel__form-grid--align-start {
    justify-content: flex-start;
}

.vlw .vlw__contact-panel__form-grid--align-end {
    justify-content: flex-end;
}

.vlw .vlw__contact-panel__form-grid--align-center {
    justify-content: center;
}

.vlw .vlw__contact-panel__form-grid--align-space-between {
    justify-content: space-between;
}

.vlw .vlw__contact-panel__form-grid--align-space-around {
    justify-content: space-around;
}

.vlw .vlw__contact-panel__form-col--fit {
    flex: 1 0;
}

.vlw .vlw__contact-panel__form-col--flex {
    display: flex;
}

.vlw .vlw__contact-panel__form-col--wide-content {
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.vlw .vlw__contact-panel__form-col--1-2 {
    flex-basis: 50%;
    max-width: 50%;
    min-width: 50%;
}

.vlw .vlw__contact-panel__form-col--1-1, .vlw .vlw__contact-panel__form-col--2-2 {
    flex-basis: 100%;
    max-width: 100%;
    min-width: 100%;
}

.vlw .vlw__contact-panel__form-grid--is-stacked {
    margin-top: -7.5px;
}

.vlw .vlw__contact-panel__form-grid--is-stacked>* {
    margin-top: 7.5px;
}

.vlw .vlw__contact-panel__form-col--omega {
    margin-left: auto;
}

.vlw .vlw__contact-panel__heading {
    display: block;
    background: #fff;
    padding: 0 15px;
    flex: 0 0 auto;
}

.vlw .vlw__contact-panel__heading--absolute {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    z-index: 3;
}

.vlw .vlw__contact-panel__heading__main {
    display: flex;
    border-bottom: 1px #e8ebee solid;
    padding: 15px 0;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__contact-panel__heading__main {
        padding: 10px 0;
    }
}

.vlw .vlw__contact-panel__heading__main__back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 19px;
    border-right: 1px #cbd2da solid;
    margin-right: 15px;
    padding-right: 15px;
    fill: #333332;
}

.vlw .vlw__contact-panel__heading__main__back:hover, .vlw .vlw__contact-panel__heading__main__back:focus {
    fill: #0055CC;
    border-right: 1px #cbd2da solid;
}

.vlw .vlw__contact-panel__heading__main__back__icon {
    display: block;
    width: 14px;
    height: 14px;
    fill: inherit;
}

.vlw .vlw__contact-panel__heading__main__title {
    display: flex;
    width: 100%;
    font-size: 16px;
    color: #333332;
    font-weight: 500;
    line-height: 1.2;
}

.vlw .vlw__contact-panel__heading__main__title__text {
    display: block;
    width: 100%;
    max-width: 100%;
}

.vlw .vlw__contact-panel__heading__main__title__icon {
    flex: 0 0 21px;
    height: 19px;
}

.vlw .vlw__contact-panel__heading__main__title__badge {
    flex: 0 0 auto;
}

.vlw .vlw__contact-panel__heading__main__title__icon, .vlw .vlw__contact-panel__heading__main__title__badge {
    margin-right: 10px;
}

.vlw .vlw__contact-panel__icon-button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background: #FFE615;
    color: #333;
    text-decoration: none;
    padding: 0;
    width: 45px;
    height: 45px;
    box-sizing: border-box;
    border-radius: 50%;
    appearance: none;
    border: 1px solid #d3d9e0;
    font-family: "Flanders Art Sans", sans-serif;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.vlw .vlw__contact-panel__icon-button__icon {
    height: 70%;
}

.vlw .vlw__contact-panel__icon-button svg {
    fill: #333;
}

.vlw .vlw__contact-panel__icon-button--light svg {
    fill: #fff;
}

.vlw .vlw__contact-panel__icon-button:hover, .vlw .vlw__contact-panel__icon-button:focus {
    background: #FFE507;
}

.vlw .vlw__contact-panel__icon-button__counter {
    display: block;
    position: absolute;
    top: -3px;
    right: -3px;
    padding-left: 2px;
    padding-right: 2px;
    min-width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: bold;
    background: #0055CC;
    color: #fff;
}

.vlw .vlw__contact-panel__icon-button--outline {
    background: #fff;
    border: 2px solid #0055CC;
}

.vlw .vlw__contact-panel__icon-button--outline svg {
    fill: #0055CC;
}

.vlw .vlw__contact-panel__icon-button--outline:hover, .vlw .vlw__contact-panel__icon-button--outline:focus {
    background: #0055CC;
}

.vlw .vlw__contact-panel__icon-button--outline:hover svg, .vlw .vlw__contact-panel__icon-button--outline:focus svg {
    fill: #fff;
}

.vlw .vlw__contact-panel__icon-button--error {
    background: #db3434;
}

.vlw .vlw__contact-panel__icon-button--error svg {
    fill: #333332;
}

.vlw .vlw__contact-panel__icon-button--error:hover, .vlw .vlw__contact-panel__icon-button--error:focus {
    background: #af2929;
}

.vlw .vlw__contact-panel__icon-button--success {
    background: #8bae00;
}

.vlw .vlw__contact-panel__icon-button--success svg {
    fill: #fff;
}

.vlw .vlw__contact-panel__icon-button--success:hover, .vlw .vlw__contact-panel__icon-button--success:focus {
    background: #8bae00;
}

.vlw .vlw__contact-panel__icon-list__item__block {
    display: flex;
    text-decoration: none;
    padding: 15px 0;
    color: #333332;
}

.vlw .vlw__contact-panel__icon-list__item__block__icon {
    width: 28px;
    height: 28px;
    flex: 0 0 28px;
    margin-right: 15px;
}

.vlw .vlw__contact-panel__icon-list__item__block__icon--success, .vlw .vlw__contact-panel__icon-list__item__block__icon--error {
    position: relative;
}

.vlw .vlw__contact-panel__icon-list__item__block__icon--success::before, .vlw .vlw__contact-panel__icon-list__item__block__icon--error::before {
    content: "";
    position: absolute;
    bottom: -4px;
    right: -6px;
    width: 12px;
    height: 12px;
    border: 2px #fff solid;
    border-radius: 12px;
}

.vlw .vlw__contact-panel__icon-list__item__block__icon--success::before {
    background: #8bae00;
}

.vlw .vlw__contact-panel__icon-list__item__block__icon--error::before {
    background: #db3434;
}

.vlw .vlw__contact-panel__icon-list__item__block__icon__svg {
    display: inline-block;
    width: 100%;
    height: 100%;
    fill: #333332;
}

.vlw .vlw__contact-panel__icon-list__item__block__icon__img {
    display: inline-block;
    width: 118%;
    height: 118%;
    border: 1px #cbd2da solid;
    border-radius: 3rem;
}

.vlw .vlw__contact-panel__icon-list__item__block__content {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.vlw .vlw__contact-panel__icon-list__item__block__content__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.vlw .vlw__contact-panel__icon-list__item__block__content__main__title {
    display: block;
    font-weight: 500;
    text-decoration: none;
    margin-bottom: 3px;
    line-height: 1.1;
}

.vlw .vlw__contact-panel__icon-list__item__block__content__main__annotation {
    display: block;
    line-height: 1.1;
}

.vlw .vlw__contact-panel__icon-list__item__block__content__svg {
    flex: 0 0 12px;
    margin-left: 10px;
    width: 13px;
    height: 13px;
}

.vlw .vlw__contact-panel__icon-list__item__block[href], .vlw .vlw__contact-panel__icon-list__item__block--has-action {
    color: #0055CC;
}

.vlw .vlw__contact-panel__icon-list__item__block[href] .vlw__contact-panel__icon-list__item__block__icon__svg, .vlw .vlw__contact-panel__icon-list__item__block--has-action .vlw__contact-panel__icon-list__item__block__icon__svg {
    fill: #0055CC;
}

.vlw .vlw__contact-panel__icon-list__item__block[href] .vlw__contact-panel__icon-list__item__block__content__main__title, .vlw .vlw__contact-panel__icon-list__item__block--has-action .vlw__contact-panel__icon-list__item__block__content__main__title {
    text-decoration: underline;
}

.vlw .vlw__contact-panel__icon-list__item__block[href]:hover .vlw__contact-panel__icon-list__item__block__icon__svg, .vlw .vlw__contact-panel__icon-list__item__block--has-action:hover .vlw__contact-panel__icon-list__item__block__icon__svg {
    fill: #0051C2;
}

.vlw .vlw__contact-panel__icon-list__item__block[href]:hover .vlw__contact-panel__icon-list__item__block__content__main__title, .vlw .vlw__contact-panel__icon-list__item__block--has-action:hover .vlw__contact-panel__icon-list__item__block__content__main__title {
    text-decoration: none;
    color: #0051C2;
}

.vlw .vlw__contact-panel__icon-list__item__block[href]:focus .vlw__contact-panel__icon-list__item__block__icon__svg, .vlw .vlw__contact-panel__icon-list__item__block[href]:active .vlw__contact-panel__icon-list__item__block__icon__svg, .vlw .vlw__contact-panel__icon-list__item__block--has-action:focus .vlw__contact-panel__icon-list__item__block__icon__svg, .vlw .vlw__contact-panel__icon-list__item__block--has-action:active .vlw__contact-panel__icon-list__item__block__icon__svg {
    fill: #004CB8;
}

.vlw .vlw__contact-panel__icon-list__item__block[href]:focus .vlw__contact-panel__icon-list__item__block__content__main__title, .vlw .vlw__contact-panel__icon-list__item__block[href]:active .vlw__contact-panel__icon-list__item__block__content__main__title, .vlw .vlw__contact-panel__icon-list__item__block--has-action:focus .vlw__contact-panel__icon-list__item__block__content__main__title, .vlw .vlw__contact-panel__icon-list__item__block--has-action:active .vlw__contact-panel__icon-list__item__block__content__main__title {
    text-decoration: none;
    color: #004CB8;
}

.vlw .vlw__contact-panel__icon-list__item__block--disabled {
    color: #666;
}

.vlw .vlw__contact-panel__icon-list__item__block--disabled .vlw__contact-panel__icon-list__item__block__icon__svg {
    fill: #666;
}

.vlw .vlw__contact-panel__icon-list__item--divider {
    height: 1px;
    margin: 5px 0;
    background: #e8ebee;
}

.vlw .vlw__contact-panel__icon-list--bordered .vlw__contact-panel__icon-list__item:not(:last-of-type) {
    position: relative;
}

.vlw .vlw__contact-panel__icon-list--bordered .vlw__contact-panel__icon-list__item:not(:last-of-type)::before {
    content: "";
    position: absolute;
    left: 45px;
    right: 0;
    bottom: 0;
    height: 1px;
    background: #e8ebee;
}

.vlw .vlw__contact-panel__link-list__item__action {
    padding: 15px 0;
}

.vlw .vlw__contact-panel__link-list--bordered .vlw__contact-panel__link-list__item:not(:last-of-type) {
    border-bottom: 1px #e8ebee solid;
}

.vlw .vlw__contact-panel__pill {
    display: inline-block;
    vertical-align: bottom;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    border: 1px solid #cbd2da;
    border-radius: 6px;
    background-color: #e8ebee;
    text-decoration: none;
    transition: color 0.2s, background-color 0.2s;
}

.vlw .vlw__contact-panel__pill__close {
    display: inline-block;
    height: 24px;
    text-align: center;
    border-left: 1px solid #cbd2da;
    margin: 0 5px;
    text-decoration: none;
}

.vlw .vlw__contact-panel__pill__close__icon {
    height: 10px;
    margin-left: 5px;
}

.vlw .vlw__contact-panel__pill--closable {
    padding-right: 0;
}

.vlw .vlw__contact-panel__pill--clickable {
    color: #0055CC;
}

.vlw .vlw__contact-panel__pill--clickable:hover, .vlw .vlw__contact-panel__pill--clickable:focus {
    color: #0051C2;
    background-color: #f1f2f4;
}

.vlw .vlw__contact-panel__popover {
    display: inline-block;
    position: relative;
}

.vlw .vlw__contact-panel__popover .vlw__contact-panel__popover__toggle {
    text-decoration: none;
}

.vlw .vlw__contact-panel__popover__content {
    display: none;
    position: absolute;
    z-index: 3;
    left: 7.5px;
    top: -5px;
    transform: translate3d(0, -100%, 0);
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
    width: 355px;
}

.vlw .vlw__contact-panel__popover__content::before {
    display: block;
    content: "";
    position: absolute;
    left: 11px;
    bottom: -7px;
    border: 9px solid transparent;
    border-top-color: #fff;
    border-bottom-width: 0;
}

.vlw .vlw__contact-panel__popover--open .vlw__contact-panel__popover__content {
    display: block;
}

.vlw .vlw__contact-panel__popover--bottom .vlw__contact-panel__popover__content {
    top: auto;
    bottom: -5px;
    transform: translate3d(0, 100%, 0);
}

.vlw .vlw__contact-panel__popover--bottom .vlw__contact-panel__popover__content::before {
    top: -7px;
    bottom: auto;
    border-top-color: transparent;
    border-bottom-color: #fff;
    border-bottom-width: 9px;
    border-top-width: 0;
}

.vlw .vlw__contact-panel__questions {
    flex: 0 0 auto;
}

.vlw .vlw__contact-panel__question {
    border-bottom: 1px #cbd2da solid;
}

.vlw .vlw__contact-panel__question__toggle {
    display: flex;
    align-items: center;
    padding: 15px;
    text-decoration: none;
    background: #e8ebee;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    font-family: "Flanders Art Sans", sans-serif;
    border: none;
    width: 100%;
    cursor: pointer;
    margin: 0;
}

.vlw .vlw__contact-panel__question__toggle:hover, .vlw .vlw__contact-panel__question__toggle:focus {
    background: #f1f2f4;
}

.vlw .vlw__contact-panel__question__toggle__icon {
    fill: #000;
    width: 12px;
    height: 12px;
    margin-right: 15px;
    transition: all 0.2s ease-in-out;
}

.vlw .vlw__contact-panel__question.vlw__contact-panel__js-accordion--open .vlw__contact-panel__question__toggle__icon {
    transform: rotate(90deg);
}

.vlw .vlw__contact-panel__social-icons__item {
    display: inline-block;
    margin-top: 8px;
    margin-left: 5px;
    margin-right: 5px;
}

.vlw .vlw__contact-panel__social-icons__item:first-of-type {
    margin-left: 0;
}

.vlw .vlw__contact-panel__social-icons__item__action {
    display: block;
    text-align: center;
    fill: #0055CC;
    line-height: 1;
    transition: fill 0.1s linear;
}

.vlw .vlw__contact-panel__social-icons__item__action:hover {
    fill: #0051C2;
}

.vlw .vlw__contact-panel__social-icons__item__action:focus, .vlw .vlw__contact-panel__social-icons__item__action:active {
    fill: #004CB8;
}

.vlw .vlw__contact-panel__social-icons__item__icon {
    width: 22px;
    height: 22px;
    fill: inherit;
}

.vlw .vlw__contact-panel__title {
    font-size: 18px;
    font-weight: 500;
    color: #333332;
    line-height: 1.1;
}

.vlw .vlw__contact-panel__title--serif {
    font-family: "Flanders Art Sans", sans-serif;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__contact-panel__title {
        font-size: 16px;
    }
}

.vlw .vlw__contact-panel__subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #333332;
    line-height: 1;
}

.vlw .vlw__contact-panel__subtitle--margin-bottom {
    margin-bottom: 10px;
}

.vlw .vlw__annotation {
    font-size: 14px;
    color: #333332;
    font-weight: 400;
}

.vlw .vlw__annotation--block {
    display: block;
    width: 100%;
}

.vlw .vlw__contact-panel__footer {
    padding-bottom: 15px;
}

.vlw .vlw__contact-panel .vlw__contact-panel__form-grid--loading:after {
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
}

.vlw .vlw__contact-panel .vlw__contact-panel__form-grid--loading .vlw__contact-panel__email__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.vlw .vlw-location-availability {
    color: #779500;
}

.vlw .vlw-location-availability--closed {
    color: #db3434;
}

.vlw .vlw-contact-panel-animation-fade-enter-active, .vlw .vlw-contact-panel-animation-fade-leave-active {
    transition: opacity 0.2s, transform 0.4s;
}

.vlw .vlw-contact-panel-animation-fade-enter, .vlw .vlw-contact-panel-animation-fade-leave-to {
    opacity: 0;
    transform: translateY(-10px) translateZ(0);
}

.vlw .vlw-contact-panel__animation-wrapper {
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    align-items: start;
    position: relative;
    height: 100%;
}

.vlw .vlw-contact-panel__animation-wrapper>.vlw__contact-panel__content {
    min-width: 100%;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out, opacity 0.2s linear 0.1s;
    transform: translateX(0) translateZ(0);
    backface-visibility: hidden;
}

.vlw .vlw-contact-panel__animation-wrapper>.vlw__contact-panel__content.next-enter-active {
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateX(10%) translateZ(0);
}

.vlw .vlw-contact-panel__animation-wrapper>.vlw__contact-panel__content.next-enter-to {
    opacity: 1;
    transform: translateX(0%) translateZ(0);
}

.vlw .vlw-contact-panel__animation-wrapper>.vlw__contact-panel__content.next-leave-active {
    opacity: 1;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0%) translateZ(0);
}

.vlw .vlw-contact-panel__animation-wrapper>.vlw__contact-panel__content.next-leave-to {
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s linear;
    transform: translateX(-5%) translateZ(0);
}

.vlw .vlw-contact-panel__animation-wrapper>.vlw__contact-panel__content.prev-enter-active {
    z-index: 0;
    opacity: 0;
    transform: translateX(-5%) translateZ(0);
}

.vlw .vlw-contact-panel__animation-wrapper>.vlw__contact-panel__content.prev-enter-to {
    opacity: 1;
    transform: translateX(0%) translateZ(0);
}

.vlw .vlw-contact-panel__animation-wrapper>.vlw__contact-panel__content.prev-leave-active {
    z-index: 1;
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    transform: translateX(0%) translateZ(0);
}

.vlw .vlw-contact-panel__animation-wrapper>.vlw__contact-panel__content.prev-leave-to {
    opacity: 0;
    transform: translateX(10%) translateZ(0);
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__primary-bar__plugins, [data-useragent*="MSIE 9.0"] .vlw .vlw__alert-bar, [data-useragent*="MSIE 10.0"] .vlw .vlw__primary-bar__plugins, [data-useragent*="MSIE 10.0"] .vlw .vlw__alert-bar {
    display: none !important;
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__primary-bar__brand, [data-useragent*="MSIE 10.0"] .vlw .vlw__primary-bar__brand {
    display: block;
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__primary-bar__brand__umbrella, [data-useragent*="MSIE 9.0"] .vlw .vlw__primary-bar__brand__host, [data-useragent*="MSIE 10.0"] .vlw .vlw__primary-bar__brand__umbrella, [data-useragent*="MSIE 10.0"] .vlw .vlw__primary-bar__brand__host {
    display: inline-block;
    vertical-align: middle;
    height: auto;
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__primary-bar__brand__umbrella__logo, [data-useragent*="MSIE 10.0"] .vlw .vlw__primary-bar__brand__umbrella__logo {
    height: 100%;
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__primary-bar__brand__umbrella__logo__icon, [data-useragent*="MSIE 9.0"] .vlw .vlw__primary-bar__brand__umbrella__logo__label, [data-useragent*="MSIE 10.0"] .vlw .vlw__primary-bar__brand__umbrella__logo__icon, [data-useragent*="MSIE 10.0"] .vlw .vlw__primary-bar__brand__umbrella__logo__label {
    display: inline-block;
    vertical-align: middle;
    line-height: 43px;
    height: 43px;
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__primary-bar__brand__umbrella__logo__icon, [data-useragent*="MSIE 10.0"] .vlw .vlw__primary-bar__brand__umbrella__logo__icon {
    margin-right: 12px;
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__primary-bar__brand__umbrella__logo__label::before, [data-useragent*="MSIE 10.0"] .vlw .vlw__primary-bar__brand__umbrella__logo__label::before {
    top: 11px;
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__alert-bar--browser, [data-useragent*="MSIE 10.0"] .vlw .vlw__alert-bar--browser {
    display: block !important;
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__alert, [data-useragent*="MSIE 10.0"] .vlw .vlw__alert {
    display: block;
    height: auto;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

@media screen and (max-width: 1023px) {
    [data-useragent*="MSIE 9.0"] .vlw .vlw__alert, [data-useragent*="MSIE 10.0"] .vlw .vlw__alert {
        text-align: left;
    }
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__alert__badge, [data-useragent*="MSIE 9.0"] .vlw .vlw__alert__message, [data-useragent*="MSIE 9.0"] .vlw .vlw__alert__cta, [data-useragent*="MSIE 10.0"] .vlw .vlw__alert__badge, [data-useragent*="MSIE 10.0"] .vlw .vlw__alert__message, [data-useragent*="MSIE 10.0"] .vlw .vlw__alert__cta {
    display: inline-block;
    vertical-align: middle;
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__alert__message, [data-useragent*="MSIE 10.0"] .vlw .vlw__alert__message {
    max-width: calc(100vw - 180px);
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__primary-bar__zombies, [data-useragent*="MSIE 10.0"] .vlw .vlw__primary-bar__zombies {
    display: block;
}

.vlw .vlw__header {
    position: fixed;
    z-index: 10004;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.vlw .vlw__header--static {
    position: static !important;
}

.vlw .vlw__primary-bar {
    position: relative;
    z-index: 10004;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    height: 43px;
    background: #fff;
}

.vlw .vlw__primary-bar:not(:last-child)::before {
    content: "";
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: #cbd2da;
}

.vlw .vlw__primary-bar:last-child {
    box-shadow: 0 1px 3px rgba(12, 13, 14, 0.1), 0 4px 20px rgba(12, 13, 14, 0.035), 0 1px 1px rgba(12, 13, 14, 0.025);
}

.vlw .vlw__primary-bar__brand {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%;
}

.vlw .vlw__primary-bar__brand__umbrella {
    display: flex;
    height: 100%;
    text-decoration: none;
    color: #333332;
}

.vlw .vlw__primary-bar__brand__umbrella[href]:hover, .vlw .vlw__primary-bar__brand__umbrella[href]:focus {
    color: #0055CC;
}

.vlw .vlw__primary-bar__brand__umbrella--primary .vlw__primary-bar__brand__umbrella__logo::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 100%;
    background: #ffe615;
    transition: background 0.1s ease-in-out;
}

.vlw .vlw__primary-bar__brand__umbrella--primary .vlw__primary-bar__brand__umbrella__logo::after {
    content: "";
    position: absolute;
    top: 0;
    left: 28px;
    width: 0;
    height: 0;
    border-top: 43px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 13.5px solid #ffe615;
    transition: border-color 0.1s ease-in-out;
}

.vlw .vlw__primary-bar__brand__umbrella--primary:hover .vlw__primary-bar__brand__umbrella__logo::before, .vlw .vlw__primary-bar__brand__umbrella--primary:focus .vlw__primary-bar__brand__umbrella__logo::before {
    background: #fbe000;
}

.vlw .vlw__primary-bar__brand__umbrella--primary:hover .vlw__primary-bar__brand__umbrella__logo::after, .vlw .vlw__primary-bar__brand__umbrella--primary:focus .vlw__primary-bar__brand__umbrella__logo::after {
    border-left: 13.5px solid #fbe000;
}

.vlw .vlw__primary-bar__brand__umbrella--primary .vlw__primary-bar__brand__umbrella__logo__label::before {
    display: none;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__primary-bar__brand__umbrella--primary .vlw__primary-bar__brand__umbrella__logo__label {
        display: block;
        margin-right: 0;
        font-size: 16px;
    }
}

.vlw .vlw__primary-bar__brand__umbrella--secondary .vlw__primary-bar__brand__umbrella__logo {
    overflow: hidden;
}

.vlw .vlw__primary-bar__brand__umbrella--secondary .vlw__primary-bar__brand__umbrella__logo::after {
    content: "";
    position: absolute;
    top: -3px;
    left: 34px;
    width: 1px;
    height: 48px;
    transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    background: #cbd2da;
}

.vlw .vlw__primary-bar__brand__umbrella--secondary:hover::before, .vlw .vlw__primary-bar__brand__umbrella--secondary:focus::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 100%;
    background: #ffe615;
}

.vlw .vlw__primary-bar__brand__umbrella--secondary:hover::after, .vlw .vlw__primary-bar__brand__umbrella--secondary:focus::after {
    content: "";
    position: absolute;
    top: 0;
    left: 28px;
    width: 0;
    height: 0;
    background: none;
    transform: none;
    -ms-transform: none;
    border-top: 43px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 13.5px solid #ffe615;
}

.vlw .vlw__primary-bar__brand__umbrella__logo {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 2px;
}

.vlw .vlw__primary-bar__brand__umbrella__logo__icon, .vlw .vlw__primary-bar__brand__umbrella__logo__label {
    position: relative;
    z-index: 2;
    text-decoration: none;
    font-weight: 500;
    color: inherit;
}

.vlw .vlw__primary-bar__brand__umbrella__logo__icon {
    width: 31px;
    height: 31px;
    margin-right: 17px;
    fill: #333332;
}

.vlw .vlw__primary-bar__brand__umbrella__logo__label {
    top: -1px;
    font-size: 18px;
    line-height: 1;
    margin-right: 20px;
}

.vlw .vlw__primary-bar__brand__umbrella__logo__label::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -15px;
    height: 24px;
    width: 1px;
    background: #cbd2da;
    transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
}

@media screen and (max-width: 767px) {
    .vlw .vlw__primary-bar__brand__umbrella__logo__label {
        display: none;
    }
}

.vlw .vlw__primary-bar__brand__host {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #333332;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
    text-decoration: none;
}

.vlw .vlw__primary-bar__brand__host:hover, .vlw .vlw__primary-bar__brand__host:focus {
    color: #0055CC;
}

.vlw .vlw__primary-bar__brand__host:first-child {
    margin-left: 0;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__primary-bar__brand__host {
        margin-left: 0;
        font-size: 13px;
        line-height: 12px;
    }
    .vlw .vlw__primary-bar__brand__host:not(:first-child) {
        padding-left: 0;
        padding-right: 0;
    }
}

.vlw .vlw__primary-bar__brand__host__img {
    max-height: 100%;
    max-width: 130px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__primary-bar__brand__host__img {
        max-width: 90px;
    }
}

.vlw .vlw__primary-bar__plugins {
    display: flex;
    height: 100%;
    margin-left: 15px;
}

.vlw .vlw__primary-bar__plugins:only-child, .vlw .vlw__primary-bar__plugins:first-child {
    margin-left: auto;
}

.vlw .vlw__primary-bar__plugins__item:not(:last-of-type) {
    margin-right: 15px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__primary-bar__plugins__item:not(:last-of-type) {
        margin-right: 10px;
    }
}

.vlw .vlw__primary-bar__progress {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    max-width: 100%;
    height: 3px;
    background: #FFE615;
}

.vlw .vlw__secondary-bar {
    position: relative;
    z-index: 10001;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    min-height: 43px;
    background: #e8ebee;
    transition: all 0.2s linear;
    transform: translateX(0);
    transform-origin: top center;
}

@media screen and (min-width: 0\\0) {
    .vlw .vlw__secondary-bar {
        height: 43px;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__secondary-bar {
        transform: none;
    }
}

.vlw .vlw__secondary-bar--hidden {
    margin-top: -43px;
    transform: translateX(-90deg);
    box-shadow: none;
}

.vlw .vlw__secondary-bar--hidden:focus-within {
    display: flex;
    transform: translateX(0);
    transition: none !important;
    margin-top: 0 !important;
    box-shadow: 0 1px 3px rgba(12, 13, 14, 0.1), 0 4px 20px rgba(12, 13, 14, 0.035), 0 1px 1px rgba(12, 13, 14, 0.025);
}

@media screen and (max-width: 500px) {
    .vlw .vlw__secondary-bar--hidden:focus-within {
        transform: none;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__secondary-bar--hidden {
        transform: none;
    }
}

[data-useragent*="MSIE 9.0"] .vlw .vlw__secondary-bar, [data-useragent*="MSIE 10.0"] .vlw .vlw__secondary-bar {
    display: none !important;
}

.vlw .vlw__modal {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    background: #fff;
    position: fixed;
    z-index: 10002;
    left: 20px;
    top: 63px;
    right: 20px;
    bottom: 10px;
}

.vlw .vlw__modal__scroll {
    display: flex;
    width: 100%;
    height: 100%;
}

@supports (-webkit-overflow-scrolling: touch) {
    .vlw .vlw__modal__scroll {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
    }
}

@media screen and (max-width: 767px) {
    .vlw .vlw__modal {
        top: 53px;
        left: 10px;
        right: 10px;
        bottom: 10px;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__modal {
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.vlw .vlw__modal__close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10003;
    top: -5px;
    right: -5px;
    background: #fff;
    border: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    width: 35px;
    height: 35px;
    line-height: 1;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__modal__close {
        top: -3px;
        right: 0;
    }
}

.vlw .vlw__modal__close:hover, .vlw .vlw__modal__close:focus {
    fill: #0055CC;
}

.vlw .vlw__modal__close__icon {
    display: block;
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    fill: inherit;
}

.vlw .vlw__modal__content {
    height: 100%;
    width: 100%;
}

.vlw-modal-slide-fade-enter-active {
    transition: opacity 0.3s ease, transform 0.8s cubic-bezier(0.17, 0.65, 0.31, 1);
}

.vlw-modal-slide-fade-leave-active {
    transition: opacity 0.5s ease, transform 0.8s cubic-bezier(0.67, 0, 0.76, 0.64);
}

.vlw-modal-slide-fade-enter, .vlw-modal-slide-fade-leave-to {
    transform: translateY(-60px);
    opacity: 0;
}

.vlw .vlw__notification {
    position: relative;
    display: flex;
    height: 100%;
}

.vlw .vlw__notification:last-child {
    margin-right: 15px;
}

.vlw .vlw__notification--new .vlw__notification__toggle__icon {
    animation: bellshake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    backface-visibility: hidden;
    transform-origin: top right;
}

.vlw .vlw__notification--new .vlw__notification__toggle__unread {
    animation: pop 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    backface-visibility: hidden;
    transform-origin: top right;
}

.vlw .vlw__notification__toggle {
    display: flex;
    align-items: center;
    fill: #666;
    text-decoration: none;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__notification__toggle {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.vlw .vlw__notification__toggle:hover, .vlw .vlw__notification__toggle:focus {
    fill: #0055CC;
}

.vlw .vlw__notification__toggle__unread {
    min-width: 15px;
    height: 15px;
    margin-right: 3px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 15px;
    background: #0055CC;
    font-size: 11px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 15px;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__notification__toggle__unread {
        position: absolute;
        top: 7px;
        left: 3px;
    }
}

.vlw .vlw__notification__toggle__icon {
    width: 18px;
    height: 18px;
    fill: inherit;
    transition: fill 0.1s ease-in-out;
    pointer-events: none;
}

@media screen and (max-width: 500px) {
    .vlw .vlw__notification__toggle__icon {
        width: 16px;
        height: 16px;
    }
}

@media screen and (max-width: 767px) {
    .vlw .vlw__notification .vlw__dropdown__content, .vlw .vlw__notification .vlw__dropdown__content__layers {
        max-height: 80vh;
    }
}

.vlw .vlw__notification__list .vlw__alert-tile {
    margin: 10px;
    max-width: calc(100% - (2*10px));
}

.vlw .vlw__notification__list, .vlw .vlw__notification__detail, .vlw .vlw__notification__nested {
    z-index: 1;
    overflow: auto;
    height: 100%;
}

.vlw .vlw__notification__nested {
    z-index: 2;
    background: #fff;
}

.vlw .vlw__notification__detail, .vlw .vlw__notification__item {
    text-align: left;
    list-style-type: none;
}

.vlw .vlw__notification__detail__title, .vlw .vlw__notification__detail__subtitle, .vlw .vlw__notification__detail__time, .vlw .vlw__notification__item__title, .vlw .vlw__notification__item__subtitle, .vlw .vlw__notification__item__time {
    display: block;
}

.vlw .vlw__notification__detail__time, .vlw .vlw__notification__item__time {
    color: #666;
    font-size: 14px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__notification__detail__time, .vlw .vlw__notification__item__time {
        font-size: 13px;
        line-height: 1.1;
    }
}

.vlw .vlw__notification__detail__title, .vlw .vlw__notification__item__title {
    font-weight: 500;
}

.vlw .vlw__notification__detail__title__icon, .vlw .vlw__notification__item__title__icon {
    width: 12px;
    height: 12px;
    display: inline-block;
    vertical-align: middle;
    fill: #666;
}

.vlw .vlw__notification__detail__subtitle, .vlw .vlw__notification__item__subtitle {
    font-size: 14px;
    color: #666;
    margin: 0 0 15px;
}

.vlw .vlw__notification__detail {
    background: #fff;
    padding: 15px 20px 20px;
}

.vlw .vlw__notification__detail__cta {
    border-left: #cc6e2a 4px solid;
    padding-left: 10px;
}

.vlw .vlw__notification__detail__title {
    font-size: 19px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__notification__detail__title {
        font-size: 18px;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__notification__detail__title {
        font-size: 17px;
    }
}

.vlw .vlw__notification__detail__link {
    padding-left: 17.5px;
    margin-top: 20px;
}

.vlw .vlw__notification__detail table {
    width: 100% !important;
}

.vlw .vlw__notification__detail table table[align=right] td {
    text-align: left !important;
}

.vlw .vlw__notification__detail table.item-image {
    width: auto !important;
}

.vlw .vlw__notification__detail td.button {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.vlw .vlw__notification__item {
    position: relative;
}

.vlw .vlw__notification__item:not(:last-of-type) {
    border-bottom: 1px #cbd2da solid;
}

.vlw .vlw__notification__item--new::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 7.5px;
    width: 8px;
    height: 8px;
    background: #0055CC;
    border-radius: 10px;
}

.vlw .vlw__notification__item__arrow {
    position: absolute;
    top: 14px;
    right: 10px;
    fill: #333332;
    width: 12px;
    height: 12px;
}

.vlw .vlw__notification__item__cta {
    display: block;
    text-decoration: none;
    line-height: 1;
    padding: 10px 10px 10px 15px;
}

.vlw .vlw__notification__item__cta:hover .vlw__notification__item__arrow, .vlw .vlw__notification__item__cta:focus .vlw__notification__item__arrow {
    fill: #0055CC;
}

.vlw .vlw__notification__item__cta:hover .vlw__notification__item__title, .vlw .vlw__notification__item__cta:focus .vlw__notification__item__title {
    text-decoration: none;
}

.vlw .vlw__notification__item__content {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 75px);
}

.vlw .vlw__notification__item__badge {
    margin: 0 12.5px 0 0;
}

.vlw .vlw__notification__item__title, .vlw .vlw__notification__item__subtitle, .vlw .vlw__notification__item__time, .vlw .vlw__notification__item__nested {
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vlw .vlw__notification__item__title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 7px;
    display: block;
    line-height: 1.2;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__notification__item__title {
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__notification__item__title {
        font-size: 14px;
    }
}

.vlw__notification__item--external .vlw .vlw__notification__item__title__icon {
    position: relative;
    top: 3px;
    display: inline-block;
    margin-right: 3px;
    color: #b2b2b2;
}

a.vlw__notification__item__cta:hover .vlw .vlw__notification__item__title {
    text-decoration: underline;
}

.vlw .vlw__notification__item__subtitle {
    color: #333332;
    font-size: 14px;
    padding: 0 0 7px;
    margin-bottom: 0;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__notification__item__subtitle {
        font-size: 13px;
        line-height: 1.1;
    }
}

.vlw .vlw__notification__item__nested {
    display: inline-flex;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 73px;
    text-decoration: underline;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__notification__item__nested {
        margin-left: 60px;
    }
}

.vlw .vlw__notification__item__nested .vi {
    color: #333332;
}

.vlw .vlw__notification__item__submenu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #fff;
}

.vlw .left-enter-active, .vlw .left-leave-active, .vlw .right-enter-active, .vlw .right-leave-active {
    transition: all 0.25s ease;
}

.vlw .left-enter, .vlw .left-leave-to {
    opacity: 0;
    transform: translateX(-10%);
}

.vlw .right-enter, .vlw .right-leave-to {
    opacity: 0;
    transform: translateX(10%);
}

@keyframes bellshake {
    0% {
        transform: rotate(0);
    }
    15% {
        transform: rotate(5deg);
    }
    30% {
        transform: rotate(-5deg);
    }
    45% {
        transform: rotate(4deg);
    }
    60% {
        transform: rotate(-4deg);
    }
    75% {
        transform: rotate(2deg);
    }
    85% {
        transform: rotate(-2deg);
    }
    92% {
        transform: rotate(1deg);
    }
    100% {
        transform: rotate(0);
    }
}

@keyframes pop {
    0% {
        box-shadow: 0 0 0 1px rgba(0, 85, 204, 0.6);
    }
    100% {
        box-shadow: 0 0 0 6px rgba(0, 85, 204, 0);
    }
}

.vlw-notification-dropdown-transition-enter-active, .vlw-notification-dropdown-transition-leave-active {
    transition: opacity 0.2s, transform 0.4s;
}

.vlw-notification-dropdown-transition-enter, .vlw-notification-dropdown-transition-leave-to {
    opacity: 0;
    transform: translateY(-10px) translateZ(0);
}

@media screen and (max-width: 500px) {
    .vlw-notification-dropdown-transition-enter, .vlw-notification-dropdown-transition-leave-to {
        transform: translateY(20px) translateZ(0);
    }
}

.vlw .vlw__profile {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.vlw .vlw__profile:last-child {
    margin-right: 15px;
}

.vlw .vlw__profile__heading {
    display: flex;
    align-items: center;
    padding: 15px;
}

.vlw .vlw__profile__heading__icon {
    flex: 0 0 36px;
    width: 36px;
    height: 36px;
    margin-right: 14px;
    fill: #333332;
}

.vlw .vlw__profile__heading__label {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: #333332;
}

.vlw .vlw__profile__toggle {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333332;
    fill: #666;
    transition: all 0.1s ease-in-out;
    border: none;
    background: none;
    font-family: "Flanders Art Sans", sans-serif;
    cursor: pointer;
    padding: 0;
}

.vlw .vlw__profile__toggle:hover, .vlw .vlw__profile__toggle:focus {
    fill: #0055CC;
    color: #0055CC;
}

.vlw .vlw__profile__toggle__icon {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    margin-right: 3px;
    fill: #333332;
}

.vlw .vlw__profile__toggle__name {
    font-size: 14px;
    font-weight: 500;
    color: inherit;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
    margin-right: 5px;
    line-height: 1;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__profile__toggle__name {
        position: absolute !important;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }
}

.vlw .vlw__profile__toggle__name:first-child {
    margin-right: 5px;
}

.vlw .vlw__profile__toggle__name:last-child {
    margin-left: 5px;
}

.vlw .vlw__profile__toggle__badge {
    position: relative;
    width: 30px;
    height: 30px;
    border: 1px #e8ebee solid;
    border-radius: 30px;
    background: #e8ebee;
    overflow: hidden;
    fill: inherit;
}

.vlw .vlw__profile__toggle__badge[data-initials] {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vlw .vlw__profile__toggle__badge[data-initials]::before {
    content: attr(data-initials);
    font-weight: 500;
    line-height: 1;
    font-size: 13px;
    color: #333332;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__profile__toggle__badge {
        width: 25px;
        height: 25px;
        border-radius: 25px;
    }
}

.vlw .vlw__profile__toggle__badge--small {
    border: 0;
    width: 20px;
    height: 20px;
}

.vlw .vlw__profile__toggle__badge__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.vlw .vlw__profile__toggle__badge__icon {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    margin: auto;
    width: 18px;
    height: 18px;
    object-fit: cover;
    border-radius: 30px;
    fill: inherit;
    pointer-events: none;
}

.vlw .vlw__profile__toggle__badge__unread {
    display: none;
    position: absolute;
    top: -3px;
    left: -3px;
    width: 15px;
    height: 15px;
    margin-right: 3px;
    border-radius: 15px;
    background: #0055CC;
    font-size: 11px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 15px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__profile__toggle__badge__unread {
        display: block;
    }
}

.vlw-profile-dropdown-transition-enter-active, .vlw--dropdown-transition-leave-active {
    transition: opacity 0.2s, transform 0.4s;
}

.vlw--dropdown-transition-enter, .vlw--dropdown-transition-leave-to {
    opacity: 0;
    transform: translateY(-10px) translateZ(0);
}

@media screen and (max-width: 500px) {
    .vlw--dropdown-transition-enter, .vlw--dropdown-transition-leave-to {
        transform: translateY(20px) translateZ(0);
    }
}

.vlw .vlw__search {
    position: relative;
    height: 100%;
    padding-left: 15px;
    padding-right: 5px;
    flex: 0 0 auto;
    margin-left: auto;
}

.vlw .vlw__search__content {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__search {
        margin-right: 0;
    }
}

.vlw .vlw__search>fieldset {
    display: flex;
    height: 100%;
}

.vlw .vlw__search input[type=text].vlw__search__input {
    border: 0;
    background: none;
    font-family: "Flanders Art Sans", sans-serif;
    font-size: 16px;
    padding: 7px 35px 7px 10px;
    border-radius: 0;
    width: 230px;
    height: 33px;
    transition: background-color 0.2s ease-in-out;
    margin: 0 0 0 10px;
    border: 1px #687483 solid;
    border-radius: 3px;
    background-color: #fff;
}

.vlw .vlw__search input[type=text].vlw__search__input:focus, .vlw .vlw__search input[type=text].vlw__search__input:not([value=""]):not(:focus):not(:invalid) {
    box-shadow: none;
    background: #fff;
    border: 1px #687483 solid;
}

.vlw .vlw__search input[type=text].vlw__search__input:focus {
    outline: none;
    box-shadow: none;
}

.vlw .vlw__search input[type=text].vlw__search__input:required {
    box-shadow: none;
    color: #333332;
    outline: 0;
}

.vlw .vlw__search input[type=text].vlw__search__input:required:not(:focus) {
    box-shadow: none;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__search input[type=text].vlw__search__input {
        display: none;
        background: #fff;
        height: 33px;
        transition: none;
        top: 5px;
        right: 5px;
    }
}

.vlw .vlw__search__submit, .vlw .vlw__search__toggle {
    background: none;
    border: 0;
    appearance: none;
    border-radius: 0;
    fill: #05c;
    padding: 7px 10px;
    margin-left: 3px;
    height: 33px;
    cursor: pointer;
}

.vlw .vlw__search__submit__icon, .vlw .vlw__search__toggle__icon {
    width: 16px;
    height: 16px;
    fill: inherit;
    display: inherit;
}

.vlw .vlw__search__submit {
    position: absolute;
    right: 6px;
}

@media screen and (min-width: 0\\0) {
    .vlw .vlw__search__submit {
        top: 7px;
    }
}

@media screen and (max-width: 767px) {
    .vlw .vlw__search__submit {
        display: none;
    }
}

.vlw .vlw__search__toggle {
    display: none;
    border: 1px #687483 solid;
    border-radius: 3px;
    background-color: #fff;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__search__toggle {
        display: block;
    }
}

.vlw .vlw__search__close {
    display: none;
    position: absolute;
    right: 3px;
    background: none;
    border: 0;
    appearance: none;
    border-radius: 0;
    fill: #333332;
    padding: 7px 10px;
    height: 35px;
    cursor: pointer;
}

.vlw .vlw__search__close:hover, .vlw .vlw__search__close:focus {
    fill: #0055CC;
}

.vlw .vlw__search__close__icon {
    width: 20px;
    height: 20px;
    fill: inherit;
    padding: 2px 1px 0;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__search--active {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;
    }
    .vlw .vlw__search--active::before {
        display: none;
    }
    .vlw .vlw__search--active:only-child {
        margin-left: 0;
    }
    .vlw .vlw__search--active .vlw__search__input, .vlw .vlw__search--active .vlw__search__submit {
        display: block !important;
        position: absolute;
        top: 5px;
    }
    .vlw .vlw__search--active .vlw__search__submit {
        right: 45px;
    }
    .vlw .vlw__search--active .vlw__search__input {
        left: 5px;
        right: 0;
        margin-left: 0 !important;
        width: calc(100% - (5px * 3) - 35px) !important;
    }
    .vlw .vlw__search--active .vlw__search__toggle {
        display: none;
    }
    .vlw .vlw__search--active .vlw__search__close {
        display: block;
        position: absolute;
        top: 4px;
    }
}

.vlw .vlw__u-visually-hidden {
    position: absolute !important;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.vlw .vlw__u-align-center {
    text-align: center;
}

.vlw .vlw__u-align-full-center {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.vlw .vlw__u-inline-block {
    display: inline-block;
}

.vlw .vlw__u-block {
    display: block;
}

.vlw .vlw__u-100 {
    width: 100%;
}

.vlw .vlw__u-color-error {
    color: #db3434;
}

.vlw .vlw__u-divider {
    border: 0;
    border-bottom: 1px #e8ebee solid !important;
}

.vlw .vlw__u-divider--full {
    position: relative;
    left: -15px;
    width: calc(100% + 30px);
}

.vlw .vlw__u-font-bold {
    font-weight: 500;
}

.vlw .vlw__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vlw .vlw__u-placeholder {
    visibility: hidden;
}

@media screen and (max-width: 1023px) {
    .vlw .vlw__u-hidden--m {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .vlw .vlw__u-hidden--s {
        display: none !important;
    }
}

@media screen and (max-width: 500px) {
    .vlw .vlw__u-hidden--xs {
        display: none !important;
    }
}

.vlw .vlw__u-spacer {
    display: block;
    margin-bottom: 15px;
}

.vlw .vlw__u-spacer--xs {
    margin-bottom: 5px;
}

.vlw .vlw__u-spacer--s {
    margin-bottom: 10px;
}

.vlw .vlw__u-spacer--none {
    margin-bottom: 0;
}

.vlw .vlw__u-typography>* {
    margin-bottom: 15px;
}

.vlw .vlw__u-typography>*:last-child {
    margin-bottom: 0;
}

.vlw .vlw__u-typography p {
    font-size: 16px;
    line-height: 1.5;
    color: #333332;
}

.vlw .vlw__u-typography h1, .vlw .vlw__u-typography h2, .vlw .vlw__u-typography h3, .vlw .vlw__u-typography h4, .vlw .vlw__u-typography h5, .vlw .vlw__u-typography .vlw__h1, .vlw .vlw__u-typography .vlw__h2, .vlw .vlw__u-typography .vlw__h3, .vlw .vlw__u-typography .vlw__h4, .vlw .vlw__u-typography .vlw__h5 {
    font-weight: 500;
    margin-bottom: 15px;
}

.vlw .vlw__u-typography h1, .vlw .vlw__u-typography .vlw__h1 {
    font-size: 22px;
}

.vlw .vlw__u-typography h2, .vlw .vlw__u-typography .vlw__h2 {
    font-size: 20px;
}

.vlw .vlw__u-typography h3, .vlw .vlw__u-typography .vlw__h3 {
    font-size: 18px;
}

.vlw .vlw__u-typography h4, .vlw .vlw__u-typography .vlw__h4 {
    font-size: 16px;
}

.vlw .vlw__u-typography h5, .vlw .vlw__u-typography .vlw__h5 {
    font-size: 14px;
}

.vlw .vlw__u-typography ul, .vlw .vlw__u-typography ol, .vlw .vlw__u-typography .vlw__ul, .vlw .vlw__u-typography .vlw__ol {
    margin-left: 18px;
    font-size: 16px;
    line-height: 1.5;
}

.vlw .vlw__u-typography ul, .vlw .vlw__u-typography .vlw__ul {
    list-style-type: disc;
}

.vlw .vlw__u-typography ol, .vlw .vlw__u-typography .vlw__ol {
    list-style-type: decimal;
}

.vlw .vlw__u-typography b, .vlw .vlw__u-typography strong {
    font-weight: 500;
}

.vlw .vlw__u-typography i {
    font-style: italic;
}

.vlw .vlw__u-typography--small p {
    font-size: 14px;
    line-height: 1.25;
    margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
    .vlw .vlw__u-typography--small p {
        line-height: 1.1;
    }
}

.vlw .vlw__u-typography--small p:last-child {
    margin-bottom: 0;
}

.vlw .vlw__u-text-clamp {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vlw__u-noscroll {
    height: 100%;
    overflow: hidden;
}

.vlw__u-noscroll body {
    position: fixed;
    width: 100%;
}
`;
