import {html, LitElement, define} from '../../common/commons';
import {renderContentHeader, renderLayout} from '../../common/templates';

import '@domg-wc/components/http-error-message';
import '@domg-wc/elements/link';

class IboView404 extends LitElement {
  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${renderContentHeader('/')}
      ${renderLayout([html`
        <section is="vl-region">
          <vl-http-404-message id="not-found-message">
            <div slot="actions">
              <a is="vl-link-button" href="/">Terug naar de startpagina</a>
            </div>
          </vl-http-404-message>
        </section>
        </div>
      `])}`;
  }
}

define('ibo-view404', IboView404);
