import {define, html, LitElement} from '../common/commons';
import {renderFunctionalHeader} from '../common/templates';

import '@domg-wc/sections/cookie-statement';

class IboCookie extends LitElement {
  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <div slot="main">
        <vl-cookie-statement>
          ${renderFunctionalHeader('Cookieverklaring')}
        </vl-cookie-statement>
      </div>`;
  }
}

define('ibo-cookie', IboCookie);
