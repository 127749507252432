import '../components/ibo-header';
import '../components/ibo-footer';

import {html, render} from './commons';

import '@domg-wc/components/content-header';
import '@domg-wc/components/datepicker';
import '@domg-wc/components/functional-header';
import '@domg-wc/components/template';
import '@domg-wc/components/textarea';
import '@domg-wc/components/upload';

import '@domg-wc/elements/form-grid';
import '@domg-wc/elements/form-message';
import '@domg-wc/elements/grid';
import '@domg-wc/elements/image';
import '@domg-wc/elements/input-field';
import '@domg-wc/elements/multiselect';
import '@domg-wc/elements/select';

import '@domg-wc/sections/footer';
import '@domg-wc/sections/header';

export const renderHeader = (template) => {
  if (template.enabled) {
    return html`
      <vl-header
        slot="header"
        ?data-vl-development="${template.development}"
        data-vl-identifier="${template.headerId}"
        data-vl-login-url="/inspraak-beheer"
        data-vl-login-redirect-url="/"
        data-vl-logout-url="/sso/afgemeld"
        data-vl-switch-capacity-url="/sso/wissel_organisatie"
        data-vl-authenticated-user-url="/sso/ingelogde_gebruiker"
      ></vl-header>`;
  }
  return html`
    <ibo-header slot="header"></ibo-header>`;
};

export const renderFooter = (template) => {
  if (template.enabled) {
    return html`
      <vl-footer
        slot="footer" ?data-vl-development="${template.development}"
        data-vl-identifier="${template.footerId}"></vl-footer>`;
  }
  return html`
    <ibo-footer slot="footer"></ibo-footer>`;
};

export const renderContentHeader = (titleLink) => {
  return html`
    <vl-content-header>
      <img is="vl-image" slot="image" src="/images/banner.jpg"
           srcset="/images/banner.jpg 320w, /images/banner.jpg 1024w, /images/banner.jpg 1600w" alt="">
      <a slot="context-link" href="https://omgeving.vlaanderen.be/">Omgeving</a>
      <a slot="title-link" href="${titleLink}">Inspraak</a>
    </vl-content-header>
  `;
};

export const renderFunctionalHeader = (subTitle) => {
  return html`
    <vl-functional-header
        slot="header"
        data-vl-title="Departement Omgeving"
        data-vl-sub-title="${subTitle}"
        data-vl-link="/"
        data-vl-back-link="/"
        data-vl-back="Start">
    </vl-functional-header>`;
};

export const renderLayout = (templates) => {
  return html`
    <div is="vl-layout">${templates}</div>`;
};

const hasTemplate = (c) => c.template;

export const renderRow = (columns = []) => {
  const filteredColumns = columns.filter(hasTemplate);
  if (filteredColumns.length === 0) {
    return html``;
  }
  return html`
    <div is="vl-grid">
      ${filteredColumns.map((c) => renderColumn(c))}
    </div>`;
};

export const renderStack = (columns = []) => {
  const filteredColumns = columns.filter(hasTemplate);
  if (filteredColumns.length === 0) {
    return html``;
  }
  return html`
    <div is="vl-grid" data-vl-is-stacked>
      ${filteredColumns.map((c) => renderColumn(c))}
    </div>`;
};

export const renderColumn = (
  {
    // watch devices
    extraSmallSize = 12,
    extraSmallMaxSize = 12,

    // phone devices
    smallSize = extraSmallSize,
    smallMaxSize = extraSmallMaxSize,

    // tablet devices
    mediumSize = smallSize,
    mediumMaxSize = smallMaxSize,

    // desktop devices
    size = mediumSize,
    maxSize = mediumMaxSize,

    // the template we want to render
    template = html``,
  } = {}) => {
  return html`
    <div
      is="vl-column"
      data-vl-size="${size}"
      data-vl-max-size="${maxSize}"

      data-vl-medium-size="${mediumSize}"
      data-vl-medium-max-size="${mediumMaxSize}"

      data-vl-small-size="${smallSize}"
      data-vl-small-max-size="${smallMaxSize}"

      data-vl-extra-small-size="${extraSmallSize}"
      data-vl-extra-small-max-size="${extraSmallMaxSize}">

      ${template}
    </div>`;
};

export const renderFormGrid = (columns = []) => {
  const filteredColumns = columns.filter(hasTemplate);
  if (filteredColumns.length === 0) {
    return html``;
  }
  return html`
    <div is="vl-form-grid" data-vl-is-stacked>
      ${filteredColumns.map((c) => renderFormColumn(c))}
    </div>`;
};

export const renderFormColumn = ({size = 12, template = html``} = {}) => {
  return html`
    <div is="vl-form-column" data-vl-size="${size}">
      ${template}
    </div>`;
};

export const renderFormRow = (columns = []) => {
  const filteredColumns = columns.filter(hasTemplate);
  if (filteredColumns.length === 0) {
    return html``;
  }
  return html`
    <div is="vl-form-grid">
      ${filteredColumns.map((c) => renderColumn(c))}
    </div>`;
};

export const renderUpload = (
  {
    formData,
    label,
    inputId = __camelCase(label),
    errorId = __camelCase(`error${label}`),
    divId = __camelCase(`${inputId}Div`),
    maxSize = 10000000,
    maxFiles = 1,
    acceptedFiles = 'application/pdf, .png',
    errorMessageFileSize = 'De grootte van het bestand mag maximaal :maxFsz zijn.',
    errorMessageMaxFiles = 'Je kan maximaal :maxfl file(s) uploaden.',
    errorMessageAcceptedFiles = 'Je kan enkel :filetype bestanden opladen',
    validator,
  }) => {
  const inputValidator = () => __inputValidator({
    formData, inputId, errorId,
    valueReader: (input) => {
      return {
        files: input.files,
        accepted: input.acceptedFiles,
        rejected: input.rejectedFiles,
      };
    },
    validator,
  });

  formData.setValidator(inputId, inputValidator);

  return html`
    <div id="${divId}">
      <label is="vl-form-message"
             data-vl-block
             for="${inputId}">${label}</label>
      <vl-upload
        id="${inputId}"
        data-vl-autoprocess="false"
        data-vl-full-body-drop="false"
        data-vl-disallow-duplicates="true"
        data-vl-max-size="${maxSize}"
        data-vl-error-message-filesize="${errorMessageFileSize}"
        data-vl-max-files="${maxFiles}"
        data-vl-error-message-maxfiles="${errorMessageMaxFiles}"
        data-vl-accepted-files="${acceptedFiles}"
        data-vl-error-message-accepted-files="${errorMessageAcceptedFiles}"
        @input="${() => setTimeout(inputValidator, 0)}"
        @focusout="${() => setTimeout(inputValidator, 0)}">
      </vl-upload>
      <p is="vl-form-validation-message"
         id="${errorId}">
      </p>
    </div>
  `;
};

export const renderDatepicker = (
  {
    formData,
    label,
    inputId = __camelCase(label),
    errorId = __camelCase(`error${label}`),
    value = '',
    format = 'Y-m-d',
    visualFormat = 'd-m-Y',
    validator,
  }) => {
  const inputValidator = () => __inputValidator({
    formData, inputId, errorId, validator,
  });

  formData.setValidator(inputId, inputValidator);

  return html`
    <label is="vl-form-message"
           data-vl-block
           for="${inputId}">${label}</label>
    <vl-datepicker
      id="${inputId}"
      data-vl-name="${inputId}"
      data-vl-format="${format}"
      data-vl-selected-date="${value}"
      data-vl-visual-format="${visualFormat}"
      @change="${inputValidator}"
      @focusout="${inputValidator}"></vl-datepicker>
    <p is="vl-form-validation-message"
       id="${errorId}">
    </p>
  `;
};

export const renderTextArea = (
  {
    formData,
    label,
    inputId = __camelCase(label),
    errorId = __camelCase(`error${label}`),
    value = '',
    toolbar = 'undo redo | bold italic underline strikethrough | h1 h2 h3 h4 h5 h6 | vlLink blockquote hr | numlist bullist | indent outdent',
    validator,
  }) => {
  const inputValidator = () => __inputValidator({
    formData, inputId, errorId, validator,
  });

  formData.setValidator(inputId, inputValidator);

  return html`
    <label
      is="vl-form-message"
      data-vl-block
      for="${inputId}">${label}</label>
    <textarea
      is="vl-textarea"
      id="${inputId}"
      name="${inputId}"
      rows="4"
      data-vl-rich
      data-vl-block
      data-vl-toolbar="${toolbar}"
      @change="${inputValidator}">${value}</textarea>
    <p is="vl-form-validation-message"
       id="${errorId}">
    </p>
  `;
};

export const renderSelect = (
  {
    formData,
    label,
    inputId = __camelCase(label),
    errorId = __camelCase(`error${label}`),
    validator,
    changeHandler,
    search = false,
  }) => {
  const inputValidator = () => __inputValidator({
    formData, inputId, errorId, validator,
  });

  formData.setValidator(inputId, inputValidator);

  return html`
    <label
      is="vl-form-message"
      data-vl-block
      for="${inputId}">${label}</label>
    <select
      is="vl-select"
      id="${inputId}"
      name="${inputId}"
      data-vl-block
      data-vl-select
      data-vl-select-search="${search}"
      @change="${async (e) => {
        await changeHandler(e);
        await validator(e);
      }}"></select>
    <p is="vl-form-validation-message"
       id="${errorId}">
    </p>
  `;
};

export const renderMultiSelect = (
  {
    formData,
    label,
    inputId = __camelCase(label),
    errorId = __camelCase(`error${label}`),
    divId = __camelCase(`${inputId}Div`),
    validator,
    values = [],
    search = false,
  }) => {
  const inputValidator = () => __inputValidator({
    formData, inputId, errorId,
    valueReader: (input) => {
      return {
        value: input.values,
      };
    },
    validator,
  });

  formData.setValidator(inputId, inputValidator);

  return html`
    <div id="${divId}">
      <label
        is="vl-form-message"
        data-vl-block
        for="${inputId}">${label}</label>
      <select
        is="vl-multiselect"
        id="${inputId}"
        name="${inputId}"
        data-vl-block
        data-vl-multiselect
        multiple
        data-vl-select-search-no-result-limit
        data-vl-select-search="${search}"
        @change="${inputValidator}"></select>
      <p is="vl-form-validation-message"
         id="${errorId}">
      </p>
    </div>
  `;
};

export const renderInputField = (
  {
    formData,
    label,
    inputId = __camelCase(label),
    errorId = __camelCase(`error${label}`),
    validator,
    value = '',
    placeholder = '',
    disabled = false,
  }) => {
  const inputValidator = () => __inputValidator({
    formData, inputId, errorId, validator,
  });

  formData.setValidator(inputId, inputValidator);

  return html`
    <label
      is="vl-form-message"
      data-vl-block
      for="${inputId}">${label}</label>
    <input
      is="vl-input-field"
      id="${inputId}"
      name="${inputId}"
      type="text"
      value="${value}"
      data-vl-block
      @change="${inputValidator}"
      @focusout="${inputValidator}"
      placeholder="${placeholder}"
      ?disabled="${disabled}"/>
    <p is="vl-form-validation-message"
       id="${errorId}">
    </p> `;
};

const __camelCase = (str) => {
  return (str.slice(0, 1).toLowerCase() + str.slice(1))
    .replace(/([-_ ])+/g, ' ')
    .split(' ')
    .reduce((cur, acc) => {
      return cur + acc[0].toUpperCase() + acc.substring(1);
    });
};

const __inputValidator = async (
  {
    formData, inputId, errorId,
    valueReader = (input) => input.value,
    validator = (inputValue) => ['please provide a validator'],
  }) => {
  const input = formData.input(inputId);
  const error = formData.input(errorId);
  const inputValue = valueReader(input);
  const {value, errors = []} = await validator(inputValue);

  formData.setValidationResult(inputId, value, errors);

  if (formData.hasInputErrors(inputId)) {
    input.setAttribute('data-vl-error', '');
    error.setAttribute('data-vl-error', '');
  } else {
    input.removeAttribute('data-vl-error');
    error.removeAttribute('data-vl-error');
  }

  render(html`${errors.map((em) => html`${em}<br/>`)}`, error);
};
