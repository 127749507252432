const __rsqlJoin = (o1, o2, op) => {
  if (o1 && o2) {
    const r = {};
    r[op] = [o1, o2];
    return r;
  }
  return __oneOfTwo(o1, o2);
};

const __oneOfTwo = (o1, o2) => {
  if (o1) {
    return o1;
  }
  if (o2) {
    return o2;
  }
};

export const toRSQLString = (objectToTransform) => {
  if (Array.isArray(objectToTransform)) {
    throw new Error('Cannot create RSQL string for array!');
  }
  if (Object.keys(objectToTransform).length === 1) {
    const key = Object.keys(objectToTransform)[0];
    return _toRSQLString(objectToTransform[key], key);
  }
  if (Object.keys(objectToTransform).length === 3) {
    return _objectToRsqlString(objectToTransform);
  }
  throw new Error('Cannot create RSQL string for invalid object!\n' +
    JSON.stringify(objectToTransform));
};

const _toRSQLString = (objectToTransform, operator) => {
  const list = objectToTransform.map((object) => {
    if (Object.keys(object).length === 1) {
      const key = Object.keys(object)[0];
      return _toRSQLString(object[key], key);
    }
    return _objectToRsqlString(object);
  });
  return _arrayToRsqlString(list, operator);
};

function _objectToRsqlString(object) {
  return `${object.name}${object.operator}${object.value}`;
}

const _arrayToRsqlString = (array, operator) => {
  if (operator === 'and') {
    return '(' + array.join(';') + ')';
  }
  if (operator === 'or') {
    return '(' + array.join(',') + ')';
  }
  if (!operator && array.length === 1) {
    return array.join();
  }
  throw new Error(`Unknown operator ${operator}`);
};

export const reduceFilterSpec = (filterSpec) => {
  const rsql = Object.values(filterSpec).reduce((previous, {value, filter}) => {
    return __rsqlJoin(previous, __isNullOrEmpty(value) ? null : filter(value),
      'and');
  }, null);
  return rsql ? toRSQLString(rsql) : '';
};

const __isNullOrEmpty = (value) => {
  if (!value) {
    return true;
  }
  if (value instanceof String) {
    return value === '';
  }
  if (value instanceof Array) {
    return value.length === 0;
  }
  return Object.keys(value).length === 0 && value.constructor === Object;
};

export const findSearchParamValue = (searchParam) => {
  const currentLocation = new URL(window.location.href);
  const searchParams = currentLocation.searchParams;
  if (searchParams.has(searchParam)) {
    return searchParams.get(searchParam);
  }
  return null;
};
