import {define, html, LitElement, queryById, registerTranslateConfig, Router, use} from './common/commons';

import './components/ibo-header';
import './components/ibo-footer';
import './components/ibo-accessibility';
import './components/ibo-cookie';
import './components/ibo-privacy';
import './components/inspraak-beheer/ibo-inspraak-aanmaken';
import './components/inspraak-beheer/ibo-inspraak-wijzigen';
import './components/inspraak-beheer/ibo-inspraak-beheer';
import './components/inspraak-publiek/ibo-inspraak-publiek';
import './components/error/ibo-beheer-view404';
import './components/error/ibo-beheer-view500';
import './components/error/ibo-view404';
import './components/error/ibo-view403';
import './components/error/ibo-view500';

import {config, resolveConfig} from './common/config';
import {renderFooter, renderHeader} from './common/templates';

import '@domg-wc/components/template';

registerTranslateConfig({
  loader: (lang) =>
    fetch(`../i18n/${lang}.json`, {cache: 'reload'})
    .then((res) => res.json()),
});

export class IboApp extends LitElement {
  static get properties() {
    return {
      appTitle: {type: String},
      _place: {type: String},
      _properties: {type: Object},
      _offline: {type: Boolean},
    };
  }

  constructor() {
    super();
  }

  firstUpdated(_changedProperties) {
    const router = new Router(queryById(this)('outlet'));

    router.setRoutes([
      {path: '/inspraak-aanmaken', component: 'ibo-inspraak-aanmaken'},
      {path: '/inspraak-wijzigen/:id', component: 'ibo-inspraak-wijzigen'},
      {path: '/inspraak-beheer', component: 'ibo-inspraak-beheer'},
      {path: '/inspraak-beheer/404', component: 'ibo-beheer-view404'},
      {path: '/inspraak-beheer/500', component: 'ibo-beheer-view500'},
      {path: '/inspraak-publiek', component: 'ibo-inspraak-publiek'},
      {path: '/inspraak(.*)', redirect: '/inspraak-publiek'},
      {path: '/', component: 'ibo-inspraak-publiek'},
      {path: '/cookieverklaring', component: 'ibo-cookie'},
      {path: '/privacy', component: 'ibo-privacy'},
      {path: '/toegankelijkheid', component: 'ibo-accessibility'},
      {path: '/403', component: 'ibo-view403'},
      {path: '/404', component: 'ibo-view404'},
      {path: '/500', component: 'ibo-view500'},
      {path: '(.*)', redirect: '/404'},
    ]);
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <vl-template>
        ${renderHeader(config.globalTemplate)}
        <div slot="main" id="outlet"></div>
        ${renderFooter(config.globalTemplate)}
      </vl-template>
    `;
  }
}

Promise.all([
  resolveConfig(),
  use('nl'),
]).then(() => {
  define('ibo-app', IboApp);
});
