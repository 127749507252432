import {
  api,
  bindVlSelect,
  comparingWithFunction,
  define,
  handleErrors,
  html,
  LitElement,
  performWithLoader,
  queryById,
  Router,
} from '../../common/commons';
import {config, resolveConfig} from '../../common/config';
import {
  renderDatepicker,
  renderFormGrid,
  renderFormRow,
  renderFunctionalHeader,
  renderInputField,
  renderLayout,
  renderMultiSelect,
  renderSelect,
  renderStack,
  renderTextArea,
  renderUpload,
} from '../../common/templates';
import '../error/ibo-error';
import {inspraak, newFormData, readShapeFileToGeoJson} from './ibo-inspraak';
import {__asGroupedLocaties} from '../../common/administratieve-eenheden';

import '@domg-wc/components/radio';
import '@domg-wc/components/textarea';
import '@domg-wc/components/upload';

import '@domg-wc/elements/button';
import '@domg-wc/elements/form';
import '@domg-wc/elements/form-message';
import '@domg-wc/elements/input-field';
import '@domg-wc/elements/multiselect';
import '@domg-wc/elements/select';

class IboInspraakAanmaken extends LitElement {
  static get properties() {
    return {
      zonderShapefile: {type: Boolean},
    };
  }

  constructor() {
    super();
    this._formData = newFormData(this);
    this.zonderShapefile = false;
  }

  createRenderRoot() {
    return this;
  }

  async firstUpdated(_changedProperties) {
    this._bewaarButton = queryById(this)('bewaar');
    this._geometrieDiv = queryById(this)('geometrieDiv');
    this._niscodesDiv = queryById(this)('niscodesDiv');

    await this.__loadLinks();
    await this.__loadFormKeuzes();
  }

  async __loadLinks() {
    const data = await api.get('/api');
    this.__links = data._links;
  }

  async __loadFormKeuzes() {
    await this.__loadAdministratieveEenheden();
    await this.__loadProcedurekeuzes();
  }

  async __loadAdministratieveEenheden() {
    const data = await api.get(this.__links.administratieveEenheden.href);
    bindVlSelect({
      component: queryById(this)('niscodes'),
      choices: __asGroupedLocaties(data._embedded.administratieveEenheden),
      sortFilter: (comparingWithFunction((x) => x.id)),
    });
  }

  async __loadProcedurekeuzes() {
    bindVlSelect({
      component: queryById(this)('procedure'), choices: config.eigenProcedurekeuzes.map((p) => {
        return {label: p.label, value: p.id};
      }),
    });
  }

  updated(_changedProperties) {
    if (_changedProperties.has('zonderShapefile')) {
      this.__hideAndUnhideLocatieShapeOrList();
    }
  }

  __hideAndUnhideLocatieShapeOrList() {
    if (this.zonderShapefile) {
      this._geometrieDiv.setAttribute('hidden', '');
      queryById(this)('geometrie').clear();
      this._niscodesDiv.removeAttribute('hidden');
    } else {
      this._geometrieDiv.removeAttribute('hidden');
      this._niscodesDiv.setAttribute('hidden', '');
    }
  }

  render() {
    return html`
      ${renderFunctionalHeader('Inspraakportaal beleidsdomein omgeving', 'Inspraakperiode toevoegen', '/inspraak-beheer')}
      ${renderLayout([this._renderAanmaakFormulier()])}
    `;
  }

  _renderAanmaakFormulier() {
    return html`
      <section is="vl-region">
        <h1 is="vl-h1">Inspraakperiode toevoegen</h1>
        ${renderStack([
          {size: 8, mediumSize: 10, template: this.__renderForm()},
          {size: 8, mediumSize: 10, template: this.__renderFormActions()},
        ])}
      </section>
    `;
  }

  __renderForm() {
    return html`
      <ibo-error id="errorAanmakenInspraak"></ibo-error>
      <form is="vl-form" id="inspraak-form" novalidate>
        ${renderFormGrid([{
          template: renderFormRow([{template: this.__renderNaam()}]),
        }, {
          template: renderFormRow([{size: 6, mediumSize: 6, template: this.__renderPeriodeVan()}, {
            size: 6, mediumSize: 6, template: this.__renderPeriodeTotEnMet(),
          }]),
        }, {
          template: renderFormRow([{template: this.__renderLocatieKeuzes()}]),
        }, {
          template: renderFormRow([{template: this.__renderGeometrieUpload()}]),
        }, {
          template: renderFormRow([{template: this.__renderNamenVoorNiscodes()}]),
        }, {
          template: renderFormRow([{template: this.__renderDossierInfo()}]),
        }, {
          template: renderFormRow([{template: this.__renderInspraakInstructies()}]),
        }, {
          template: renderFormRow([{size: 6, mediumSize: 6, template: this.__renderProcedure()},
            {size: 6, mediumSize: 6, template: this.__renderAlgemeenPlanId()},
          ]),
        }, {
          template: renderFormRow([{
            size: 6, mediumSize: 6, template: this.__renderInitiatiefnemer(),
          }]),
        }])}
      </form>
    `;
  }

  __renderNaam() {
    return renderInputField({
      formData: this._formData,
      label: 'Naam',
      validator: (value) => value ? {value: value} : {errors: ['Naam  is verplicht']},
    });
  }

  __renderPeriodeVan() {
    return renderDatepicker({
      formData: this._formData,
      label: 'Periode van',
      validator: (value) => value ? {value: value} : {errors: ['Start datum van de periode is verplicht']},
    });
  }

  __renderPeriodeTotEnMet() {
    return renderDatepicker({
      formData: this._formData,
      label: 'Periode tot en met',
      validator: (value) => value ? {value: value} : {errors: ['Eind datum van de periode is verplicht']},
    });
  }

  __renderLocatieKeuzes() {
    return html`
      <vl-radio-group id="radio-group">
        <vl-radio
          id="shape" data-vl-label="A.d.h.v. shape bestand"
          data-vl-value="shape" data-vl-checked=""
          @change="${this.__handleLocatieKeuzeChanged}"></vl-radio>
        <vl-radio
          id="list"
          data-vl-label="A.d.h.v. gemeenten, provincies of gewest"
          data-vl-value="list"
          @change="${this.__handleLocatieKeuzeChanged}"></vl-radio>
      </vl-radio-group>
    `;
  }

  __handleLocatieKeuzeChanged() {
    this.zonderShapefile = queryById(this)('list').checked;
  }

  __renderGeometrieUpload() {
    return renderUpload({
      formData: this._formData,
      label: 'Locatie invoer',
      inputId: 'geometrie',
      errorId: 'errorGeometrie',
      acceptedFiles: '.zip',
      validator: async ({files, accepted}) => {
        if (this.zonderShapefile) {
          return '';
        }
        return await readShapeFileToGeoJson(accepted);
      },
    });
  }

  __renderNamenVoorNiscodes() {
    return renderMultiSelect({
      formData: this._formData,
      label: 'Locatie invoer',
      inputId: 'niscodes',
      errorId: 'errorNiscodes',
      search: true,
      validator: (value) => {
        if (!this.__niscodesHasValue() && this.zonderShapefile) {
          return {errors: ['Locatie is verplicht']};
        }
        return value;
      },
    });
  }

  __niscodesHasValue() {
    return queryById(this)('niscodes').value.length > 0;
  }

  __renderDossierInfo() {
    return renderTextArea({
      formData: this._formData,
      label: 'Dossier Informatie',
      validator: (value) => value ? {value: value} : {errors: ['Dossier informatie is verplicht']},
    });
  }

  __renderInspraakInstructies() {
    return renderTextArea({
      formData: this._formData,
      label: 'Inspraak Instructies',
      validator: (value) => value ? {value: value} : {errors: ['Inspraak instructies zijn verplicht']},
    });
  }

  __renderProcedure() {
    const grupValue = '0d6264ed-6c51-4559-a0ee-eab1e7aed9c3';

    return renderSelect({
        formData: this._formData,
        label: 'Procedure',
        validator: (value) => value ? {value: value} : {errors: ['Procedure is verplicht']},
        changeHandler: (value) => {
          value = value.detail.value;
          if (value !== grupValue) {
            queryById(this)('algemeenPlanId').setAttribute('disabled', 'true');
          } else {
            queryById(this)('algemeenPlanId').removeAttribute('disabled');
          }
        },
      },
    );
  }

  __renderAlgemeenPlanId() {
    const grupValue = '0d6264ed-6c51-4559-a0ee-eab1e7aed9c3';

    return renderInputField({
      formData: this._formData,
      label: 'Algemeen Plan ID',
      inputId: 'algemeenPlanId',
      validator: (value) => {
        if (queryById(this)('procedure').value === grupValue && !value) {
          return {errors: ['Algemeen Plan ID is verplicht']};
        } else {
          return {value: value};
        }
      },
      placeholder: 'RUP_02000_212_00437_00001',
      //  disabled: true,
    });
  }

  __renderInitiatiefnemer() {
    return renderInputField({
      formData: this._formData,
      label: 'Initiatiefnemer',
      validator: (value) => value ? {value: value} : {errors: ['Initiatiefnemer is verplicht']},
    });
  }

  __renderFormActions() {
    return html`
      <div is="vl-action-group" id="modal-action-group">
        <button
          id="bewaar" is="vl-button" value="Aanmaken"
          @click="${this._createInspraak}">
          <span is="vl-icon" data-vl-icon="save" data-vl-before></span>
          Inspraakperiode toevoegen
        </button>
        <button
          is="vl-button-link" data-vl-secondary value="Annuleren"
          @click="${() => Router.go('/inspraak-beheer')}">
          <span is="vl-icon" data-vl-icon="close" data-vl-before></span>
          Annuleer
        </button>
      </div>
    `;
  }

  async _createInspraak() {
    performWithLoader(this._bewaarButton, () => this._performSave());
  }

  async _performSave() {
    if (!await this._formData.isValid()) {
      this._formData.focusOnInvalidInput();
      return;
    }

    try {
      await api.post(this.__links.inspraakPeriodes.href, inspraak(this._formData, this.zonderShapefile));
      Router.go('/inspraak-beheer');
    } catch (error) {
      handleErrors(error, queryById(this)('errorAanmakenInspraak'));
    }
  }
}

Promise.all([
  window.customElements.whenDefined('vl-select'),
  resolveConfig(),
]).then(() => {
  define('ibo-inspraak-aanmaken', IboInspraakAanmaken, null);
});
