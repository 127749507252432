import {define, html, LitElement} from '../common/commons';
import {renderFunctionalHeader} from '../common/templates';

import '@domg-wc/sections/privacy';

class IboPrivacy extends LitElement {
  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <div slot="main">
        <vl-privacy>
          ${renderFunctionalHeader('Privacy')}
        </vl-privacy>
      </div>`;
  }
}

define('ibo-privacy', IboPrivacy);
