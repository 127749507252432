import {define, html, ifDefined, LitElement, render} from './commons';

import '@domg-wc/components/radio';
import '@domg-wc/components/datepicker';

import '@domg-wc/elements/form-message';
import '@domg-wc/elements/input-field';
import '@domg-wc/elements/select';

const camelCaseToDash = (value) => {
  return value.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
};

export class ConstraintViolations {
  constructor() {
    this.__violations = [];
  }

  isValid() {
    return this.__violations.length === 0;
  }

  isInvalid() {
    return this.__violations.length !== 0;
  }

  combine(other) {
    return violations(
      [...other.__violations, ...this.__violations]);
  }

  map(onViolation) {
    return this.__violations.map((v) => onViolation(v));
  }

  forEach(onViolation) {
    this.__violations.forEach((v) => onViolation(v));
  }

  get violations() {
    return this.__violations;
  }
}

export const violations = (messages) => {
  const cvs = new ConstraintViolations();
  cvs.__violations = Array.isArray(messages) ? messages : [messages];
  return cvs;
};

export const emptyViolations = () => {
  return new ConstraintViolations();
};

export const inputMixin = (base) => class extends base {
  constructor() {
    super();
  }

  static get properties() {
    return {
      label: {type: String},
      name: {type: String},
      placeholder: {type: String},
      annotation: {type: String},
      value: {type: Object},
      noSubmit: {type: Boolean},
      validator: {
        type: Object, hasChanged: () => false,
      },
    };
  }

  static _attributeNameForProperty(name, options) {
    return super._attributeNameForProperty(camelCaseToDash(name), options);
  }

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('data-form-input', true);
  }

  render() {
    return html`
      ${this.__renderLabel()}
      ${this.__renderAnnotation()}
      ${this.__renderInput()}
      ${this.__renderError()}
    `;
  }

  __renderAnnotation() {
    if (!this.annotation) {
      return html``;
    }
    return html`
      <p is="vl-form-annotation" data-vl-light>
        ${this.annotation}
      </p>`;
  }

  __renderLabel() {
    return html`
      <label is="vl-form-label" for="${this.name}" data-vl-block>
        ${this.label}
      </label>`;
  };

  __renderError() {
    return html`
      <p is="vl-form-validation-message" id="errorMessage"></p>
    `;
  }

  __renderInput() {
    throw new Error(
      'You have to implement the "__renderInput()" method.');
  }

  get __input() {
    throw new Error(
      'You have to implement the "getResource __input()" method.');
  }

  get __error() {
    return this.querySelector('#errorMessage');
  }

  async checkValidity() {
    return this.__validate(this.value);
  }

  focus() {
    this.__input.focus();
  }

  clear() {
    throw new Error(
      'You have to implement the "clear()" method.');
  }

  set errors(errors) {
    this.__updateInput(this.__isValid(errors));
    render(html`${errors.map((em) => html`${em}<br/>`)}`, this.__error);
  }

  async __validate(inputValue) {
    const violations = await this.__validateInput(inputValue);
    if (violations.isValid()) {
      this.__updateInput(true);
      const newValue = this.__normalizeValue(inputValue);
      this.__dispatchChangeEvent(newValue, this.value);
      this.value = newValue;
      this.errors = [];
    } else {
      this.value = inputValue;
      this.__updateInput(false);
      this.errors = violations.violations;
    }
    return violations;
  }

  __dispatchChangeEvent(newValue, oldValue) {
    if (this._isChanged(newValue, oldValue)) {
      this.dispatchEvent(new CustomEvent('change', {detail: newValue}));
    }
  }

  _isChanged(newValue, oldValue) {
    return newValue !== oldValue;
  }

  __normalizeValue(value) {
    return value;
  }

  __isValid(errors) {
    return errors === null || errors.length === 0;
  }

  async __validateInput(inputValue) {
    if (this.validator) {
      return await this.validator(inputValue);
    }
    return emptyViolations();
  }

  __updateInput(isValid) {
    if (isValid) {
      this.__input.removeAttribute('data-vl-error');
      this.__error.removeAttribute('data-vl-error');
    } else {
      this.__input.setAttribute('data-vl-error', '');
      this.__error.setAttribute('data-vl-error', '');
    }
  }
};

class DateInput extends inputMixin(LitElement) {
  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  // data-vl-visual-format="d-m-Y" mag niet aan staan, anders werkt chagne event niet.
  __renderInput() {
    return html`
      <vl-datepicker
        id="localdate"
        data-vl-format="Y-m-d"
        data-vl-name="${this.name}"
        placeholder="${ifDefined(this.placeholder)}"
        @change="${this.__changeValue}"
        @blur="${this.__changeValue}"
      ></vl-datepicker>
    `;
  }

  firstUpdated(_changedProperties) {
    if (this.value) {
      this.__input.value = this.value;
    }
  }

  __normalizeValue(value) {
    return value == null || value === '' ? undefined : value;
  }

  async __changeValue(e) {
    await this.__validate(this.__input.value);
  }

  get __input() {
    return this.querySelector('#localdate');
  }
}

window.customElements.whenDefined('vl-datepicker').then(() => {
  define('vl-date-input', DateInput);
});

export const date = ({label, property, placeholder, validator, annotation, onChange}) => {
  return {
    template: html`
      <vl-date-input
        label="${label}"
        name="${property}"
        placeholder="${ifDefined(placeholder)}"
        @change="${onChange}"
        .validator="${validator}"
        annotation="${ifDefined(annotation)}">
      </vl-date-input>`,
  };
};

class SelectInput extends inputMixin(LitElement) {
  constructor() {
    super();
    this.choices = [];
    this.groupFunction = null;
    this.sortFilter = null;
    this.doSerialize = true;
  }

  static get properties() {
    return {
      choices: {type: Array, reflect: false},
      groupFunction: {type: Object, reflect: false},
      sortFilter: {type: Object, reflect: false},
      doSerialize: {type: Boolean, reflect: false},
    };
  }

  createRenderRoot() {
    return this;
  }

  __renderInput() {
    return html`
      <select
        name="${this.name}"
        is="vl-select"
        data-vl-block
        data-vl-select
        data-vl-select-deletable
        data-vl-select-search-no-result-limit
        @change="${this.__changeValue}"></select>
    `;
  }

  updated(_changedProperties) {
    if (_changedProperties.has('sortFilter') && this.sortFilter) {
      customElements.whenDefined('vl-select').then(() => {
        this.__input.ready().then(() => {
          this.__input.sortFilter = this.sortFilter;
        });
      });
    }

    if ((_changedProperties.has('placeholder') || _changedProperties.has('choices')) && this.__hasChoices()) {
      this.__bindChoices();
      this.__bindValue();
    }

    if (_changedProperties.has('value') && this.value && this.__hasChoices()) {
      this.__bindValue();
    }

    if (_changedProperties.has('value') && !this.value && !this.__placeholderSelected()) {
      customElements.whenDefined('vl-select').then(() => {
        this.__input.ready().then(() => {
          this.__input.removeActive();
        });
      });
    }
  }

  get __input() {
    return this.querySelector('select');
  }

  async __changeValue(e) {
    await this.__validate(this.__deserialize(this.__input.value));
  }

  __placeholderSelected() {
    return this.placeholder && this.__input.value === '';
  }

  __hasChoices() {
    // placeholder is achterliggend geimplementeerd als een choice ipv het ontbreken van een gekozen choice
    return this.choices || this.placeholder;
  }

  __bindChoices() {
    customElements.whenDefined('vl-select').then(() => {
      this.__input.ready().then(() => {
        if (this.groupFunction) {
          this.__groupChoices();
        } else {
          const choices = this.choices.map((c) => {
            return {
              label: c.label,
              value: this.__serialize(c.value),
            };
          });
          if (this.placeholder) {
            choices.push({label: this.placeholder, value: '', placeholder: true, selected: true});
          }
          this.__input.choices = choices;
        }
      });
    });
  }

  __groupChoices() {
    const groupedChoices = this.groupFunction(this.choices);
    groupedChoices.forEach((gc) => {
      gc.choices = gc.choices.map((c) => {
        return {
          label: c.label,
          value: this.__serialize(c.value),
        };
      });
    });
    if (this.placeholder) {
      groupedChoices.push({label: this.placeholder, value: '', placeholder: true, selected: true});
    }
    this.__input.choices = groupedChoices;
  }

  __bindValue() {
    customElements.whenDefined('vl-select').then(() => {
      this.__input.ready().then(() => {
        if (this.value &&
          !this.__isEqual(this.value, this.__deserialize(this.__input.value))) {
          this.__input.value = this.__serialize(this.value);
        }
      });
    });
  }

  __deserialize(value) {
    if (!value) {
      return undefined;
    }
    if (this.doSerialize) {
      return JSON.parse(this.__fromBinary(atob(value)));
    }
    return value;
  }

  __serialize(value) {
    if (this.doSerialize) {
      return btoa(this.__toBinary(JSON.stringify(value)));
    }
    return value;
  }

  __toBinary(string) {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = string.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
  };

  __fromBinary(binary) {
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
  };

  __isEqual(a, b) {
    if (a == null || b == null) {
      return false;
    }
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  };
}

window.customElements.whenDefined('vl-select').then(() => {
  define('vl-select-input', SelectInput);
});

export const select = (
  {
    label,
    property,
    placeholder,
    choices,
    validator,
    onChange,
    annotation,
    groupFunction,
    sortFilter,
    serialize,
  }) => {
  return {
    template: html`
      <vl-select-input
        label="${label}"
        name="${property}"
        placeholder="${ifDefined(placeholder)}"
        @change="${onChange}"
        .choices="${choices}"
        .validator="${validator}"
        .groupFunction="${ifDefined(groupFunction)}"
        .sortFilter="${ifDefined(sortFilter)}"
        .doSerialize="${serialize}"
        annotation="${ifDefined(annotation)}">
      </vl-select-input>`,
  };
};

class TextInput extends inputMixin(LitElement) {
  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  updated(_changedProperties) {
    this.__input.value = this.value || '';
  }

  __renderInput() {
    return html`
      <input
        is="vl-input-field" block
        type="text" name="${this.name}"
        placeholder="${ifDefined(this.placeholder)}"
        @blur="${this.__onBlur}"
        @change="${this.__changeValue}"/>
    `;
  }

  get __input() {
    return this.querySelector('input');
  }

  async __onBlur(e) {
    await this.__validate(this.__input.value);
  }

  async __changeValue(e) {
    await this.__validate(e.target.value);
  }
}

define('vl-text-input', TextInput);

export const text = (
  {
    label,
    property,
    placeholder,
    validator,
    annotation,
    hidden = false,
    onChange,
  }) => {
  return {
    template: html`
      <vl-text-input
        label="${label}"
        name="${property}"
        placeholder="${ifDefined(placeholder)}"
        .validator="${validator}"
        @change="${onChange}"
        annotation="${ifDefined(annotation)}"
        ?hidden="${hidden}">
      </vl-text-input>`,
  };
};

class RadioInput extends inputMixin(LitElement) {
  constructor() {
    super();
    this.choices = [];
  }

  static get properties() {
    return {
      choices: {type: Array, reflect: false},
    };
  }

  createRenderRoot() {
    return this;
  }

  get __input() {
    return this.querySelector(`#${this.name}-radio-group`);
  }

  __renderInput() {
    return html`
      <vl-radio-group
        id="${this.name}-radio-group"
        name="${this.name}">
        ${this.choices.map(
          (choice, index) => this.__renderChoice({index, choice}))}
      </vl-radio-group>`;
  }

  __renderChoice({index, choice}) {
    return html`
      <vl-radio
        id="${this.name}-${index}"
        name="${this.name}"
        data-vl-block
        data-vl-label="${choice.label}"
        data-vl-value="${choice.value}"
        ?checked="${this.__isChecked(choice.value)}"
        @input="${this.__changeValue}"></vl-radio>
    `;
  }

  __isChecked(value) {
    return this.value !== null && this.value !== undefined && this.value === value;
  }

  async __changeValue(e) {
    await this.__validate(e.target.value);
  }

  focus() {
    this.__input._inputElement.focus();
  }
}

define('vl-radio-input', RadioInput);

export const radio = (
  {
    property,
    validator,
    annotation,
    choices,
    onChange,
    hidden = false,
  }) => {
  return {
    template: html`
      <vl-radio-input
        name="${property}"
        @change="${onChange}"
        .choices="${choices}"
        .validator="${validator}"
        annotation="${ifDefined(annotation)}"
        ?hidden="${hidden}">
      </vl-radio-input>
    `,
  };
};
