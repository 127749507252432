import {api} from './commons';
import {resolve} from './rest';

const SINGLETON = {};
export {
  SINGLETON as config,
};

export const resolveConfig = async () => {
  if (SINGLETON.isLoaded) {
    return;
  }
  const data = await api.get('/api');
  await resolveAlleProcedurekeuzes(data);
  await resolveEigenProcedurekeuzes(data);
  await resolveGlobalTemplate(data);
  SINGLETON.isLoaded = true;
};

const resolveAlleProcedurekeuzes = async (data) => {
  const alleProcedurekeuzesUrl = resolve(data, 'alleProcedurekeuzes');
  const alleProcedurekeuzes = await api.get(alleProcedurekeuzesUrl);
  SINGLETON.alleProcedurekeuzes = alleProcedurekeuzes._embedded.procedurekeuzes;
};


const resolveGlobalTemplate = async (data) => {
  const configUrl = resolve(data, 'config');
  const config = await api.get(configUrl);
  SINGLETON.globalTemplate = config.globalTemplate;
};

const resolveEigenProcedurekeuzes = async (data) => {
  const eigenProcedurekeuzesUrl = resolve(data, 'eigenProcedurekeuzes');
  const eigenProcedurekeuzes = await api.get(eigenProcedurekeuzesUrl);
  SINGLETON.eigenProcedurekeuzes = eigenProcedurekeuzes._embedded ?
    eigenProcedurekeuzes._embedded.procedurekeuzes : [];
};
