export const buildLocatieFilter = (locatie) => {
  return __inLocatieValue('locatie', locatie);
};

export const buildPeriodeBegintVoorFilter = (periodeBegintVoor) => {
  return __leValue('periodeVan', periodeBegintVoor);
};

export const buildPeriodeEindigtNaFilter = (periodeEindigtNa) => {
  return __geValue('periodeTotEnMet', periodeEindigtNa);
};

export const buildBeheerTrefwoordFilter = (trefwoord) => {
  return {
    or: [
      __likeValue('naam', trefwoord),
      __likeDossierType('dossierType', trefwoord),
      __likeValue('dossierInfo', trefwoord),
      __likeValue('inspraakInstructies', trefwoord),
      __likeValue('initiatiefnemer', trefwoord),
    ],
  };
};

export const buildPubliekeTrefwoordFilter = (trefwoord) => {
  return {
    or: [
      __likeValue('naam', trefwoord),
      __likeValue('dossierInfo', trefwoord),
      __likeValue('inspraakInstructies', trefwoord),
    ],
  };
};

const __geValue = (name, value) => __value(name, '>=', value);
const __leValue = (name, value) => __value(name, '<=', value);
const __likeValue = (name, value) => __value(name, '=like=', value);
const __inLocatieValue = (name, value) => __value(name, '=inlocatie=', value);
const __likeDossierType = (name, value) => __value(name, '=likedossiertype=', value);

const __value = (name, operator, value) => {
  return {'name': name, 'operator': operator, 'value': JSON.stringify(value)};
};
