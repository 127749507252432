import {LitElement, html, define} from '../common/commons';
import {IboHeaderStyle} from './ibo-header-styles';

class IboHeader extends LitElement {
  static get styles() {
    return [IboHeaderStyle];
  }

  render() {
    return html`
      <div id="header">
        <div class="vlw">
          <span class="vlw__u-visually-hidden" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <symbol id="icon-flanders-logo" viewBox="0 0 32 32">
                <path
                  d="M18.83 6.45c.05-.14.12 0 .27-.12a1.69 1.69 0 0 1 .59-.49 1.06 1.06 0 0 1 .61 0c.12 0 .05.33 0 .4s-.73-.08-.73.33c0 .67.91 0 1.38 0 .18 1.67-2.53 1.22-2.12-.12zm.5 1.49c1 .3 2.08.13 2.32.74-.47.24-.1 1.05.37.92.38-1.08.57-5.24-5-4 .59.07 1 1.92 2.34 2.32zM10 8.81c3.44-2.83 2.6-4.05 2.48-4.27a1 1 0 0 0-.93-.64s.86.71-3.36 4.21-.55 5.53-.55 5.53-1.08-2 2.36-4.82zm-.26-4.65A3 3 0 0 0 10.95.48a2.66 2.66 0 0 1-1 1.25C9.42 2.06 7.39 3 7.24 5.18a4.57 4.57 0 0 0 .16 1.5 6.07 6.07 0 0 1 2.34-2.52zm4.32 15.1c1.17-5.12-.5-6.39-.5-6.39s.3 1.4-.95 5c-3.26 9.45 3 10.34 4.74 12.56 0 0 .68-1-2.14-4-1-1.12-2-3.6-1.15-7.14zm-4-1.88a13.41 13.41 0 0 1 2.38-5.32A4.84 4.84 0 0 0 13 7.84c-.1 1.77-2.86 4.16-3.72 5.75a11.16 11.16 0 0 0-1.08 2.84 7.5 7.5 0 0 0 .62 4.67c.91 2.17-.13 3 .59 2.53.88-.71.75-2.38.68-3.43a15.71 15.71 0 0 1 .02-2.82zm12.65 6.33a18.28 18.28 0 0 1 3 .31v-7.59c-1.09-.82-1.6 0-2.27-.05s-1.12-1-1.57-.75c-.85.43.34 2 .86 2.27s1 .53 1.18.6A1.7 1.7 0 0 1 25 20a2.71 2.71 0 0 1 0 .75c-.31 1.32-2.63 2.52-4 1.61a3 3 0 0 1-1.4-2c-.36-1.73-1.62-3-2-4.7-.25-1-.43-2.12-.7-3.15s-.56-2.14-.79-3.11a25.07 25.07 0 0 0-.95-3.08c-1.27-3.31-1.85-3.08-1.85-3.08s.47.92 2.15 9a47.49 47.49 0 0 0 1.14 5.07c.15.43.43 1.33.62 1.72.53 1.17 1.95 2.93 2.05 4.52.05.91.15 1.67.18 2.37a6.61 6.61 0 0 0 .23 1.4c.35 1 3.14 4.09 6.1 4.09v-2.26A10.86 10.86 0 0 1 20 27.34a6.05 6.05 0 0 1 .35-1.7 2.77 2.77 0 0 1 2.4-1.94z">
                </path>
              </symbol>
              <symbol id="icon-question-outline" viewBox="0 0 20 20">
                <path
                  d="M10 20a10.01 10.01 0 0 1 0-20 10.01 10.01 0 0 1 0 20zm0-18a8 8 0 0 0 0 16 8 8 0 0 0 0-16zm1 11H9v-3h1a2 2 0 1 0-2-2H6a4 4 0 1 1 5 3.88V13zm-1 3.25a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5z">
                </path>
              </symbol>
            </svg>
          </span>
          <div class="vlw__js-placeholder" style="height: 43px;"></div>
          <div id="vl-global-header" class="vlw__header">
            <header role="presentation">
              <div class="vlw__primary-bar">
                <div class="vlw__primary-bar__brand">
                  <a href="https://www.vlaanderen.be/"
                     class="vlw__primary-bar__brand__umbrella vlw__primary-bar__brand__umbrella--secondary">
                    <span class="vlw__u-visually-hidden">Naar de homepagina van Vlaanderen</span>
                    <div aria-hidden="true" class="vlw__primary-bar__brand__umbrella__logo">
                      <svg viewBox="0 0 32 32" class="vlw__primary-bar__brand__umbrella__logo__icon">
                        <use xlink:href="#icon-flanders-logo"></use>
                      </svg>
                      <p class="vlw__primary-bar__brand__umbrella__logo__label">Vlaanderen</p>
                    </div>
                  </a>
                  <a href="https://omgeving.vlaanderen.be/" class="vlw__primary-bar__brand__host">Departement
                    Omgeving</a>
                </div>
                <div class="vlw__primary-bar__plugins">
                  <div style="height: 100%;">
                    <div class="vlw__primary-bar__plugins__item vlw__contact vlw__contact--primary">
                      <div style="cursor: auto;" aria-expanded="false" class="vlw__contact__toggle">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vlw__primary-bar__progress" style="width: 0%;"></div>
              </div>
            </header>
          </div>
          <div>
            <div class="vlw__modal" style="display: none;">
              <div class="vlw__modal__scroll"></div>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

define('ibo-header', IboHeader);
