import {html, LitElement, define} from '../../common/commons';
import {renderContentHeader, renderLayout} from '../../common/templates';

import '@domg-wc/components/http-error-message';
import '@domg-wc/elements/link';

class IboView403 extends LitElement {
  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${renderContentHeader('/')}
      ${renderLayout([html`
        <section is="vl-region">
          <vl-http-403-message
            id="forbidden-message"
            data-vl-image="/images/error-403.png"
            data-vl-image-alt="Geen toegang">
            <div slot="actions">
              <a is="vl-link-button" href="/">Naar het publieke deel</a>
            </div>
          </vl-http-403-message>
        </section>
        </div>
      `])}`;
  }
}

define('ibo-view403', IboView403);
