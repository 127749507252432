import {
  ALLOWED_EXTENSIONS,
  INVALID_EXTENSION,
  MULTIPLE_SHP_FILES,
  NO_FEATURES,
  NO_FILE,
  NO_SHP_FILES,
  NOT_ZIP,
  queryById,
} from '../../common/commons';

import * as shapefile from 'shapefile';

import {GeoJSON} from 'ol/format';
import GeometryFactory from 'jsts/org/locationtech/jts/geom/GeometryFactory';
import GeoJSONWriter from 'jsts/org/locationtech/jts/io/GeoJSONWriter';
import OL3Parser from 'jsts/org/locationtech/jts/io/OL3Parser';

import {
  GeometryCollection,
  LinearRing,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from 'ol/geom';

import {unzip} from 'unzipit';

export const GeoJsonReader = new GeoJSON({
  dataProjection: 'EPSG:31370',
  featureProjection: 'EPSG:31370',
});

export const GoeJsonWriter = new GeoJSONWriter();

export const Ol3Parser = new OL3Parser(new GeometryFactory(), {
  geom: {
    Point,
    LineString,
    LinearRing,
    Polygon,
    MultiPoint,
    MultiLineString,
    MultiPolygon,
    GeometryCollection,
  },
});

export class FormData {
  constructor(target) {
    this.target = target;
    this.data = {};
  }

  input(inputId) {
    return queryById(this.target)(inputId);
  }

  error(errorId) {
    return queryById(this.target)(errorId);
  }

  async validateInputs() {
    for (const inputId of Object.keys(this.data)) {
      await this.validateInput(inputId);
    }
  }

  validateInput(inputId) {
    this.data[inputId].validator();
  }

  inputErrors(inputId) {
    return this.data[inputId].errors;
  }

  hasInputErrors(inputId) {
    const errors = this.inputErrors(inputId);
    return errors && errors.length > 0;
  }

  setValidator(inputId, validator) {
    this.data[inputId] = Object.assign({},
      this.data[inputId],
      {validator: validator});
  }

  setValidationResult(inputId, value, errors) {
    this.data[inputId] = Object.assign({},
      this.data[inputId],
      {value: value, errors: errors});
  }

  async isValid() {
    await this.validateInputs();
    for (const inputId of Object.keys(this.data)) {
      if (this.hasInputErrors(inputId)) {
        return false;
      }
    }
    return true;
  }

  focusOnInvalidInput() {
    for (const inputId of Object.keys(this.data)) {
      if (this.hasInputErrors(inputId)) {
        queryById(this.target)(inputId).focus();
        return;
      }
    }
  }

  value(inputId) {
    return this.data[inputId].value;
  }
}

export const newFormData = (target) => {
  return new FormData(target);
};

export const payload = (formData) => {
  return {
    algemeenPlanId: formData.value('algemeenPlanId'),
  };
};

export const inspraak = (formData, zonderShapefile, inspraakId = null) => {
  if (!zonderShapefile) {
    return inspraakMetGeometrie(formData, inspraakId);
  }
  if (zonderShapefile && formData.value('niscodes') !== undefined) {
    return inspraakMetNiscodes(formData, inspraakId);
  }
  return inspraakMetGeometrie(formData, inspraakId);
};

export const inspraakMetGeometrie = (formData, inspraakId) => {
  const inspraak = {
    '@type': 'geometrie',
    'dossierType': formData.value('procedure'),
    'naam': formData.value('naam'),
    'initiatiefnemer': formData.value('initiatiefnemer'),
    'geometrie': formData.value('geometrie'),
    'dossierInfo': formData.value('dossierInformatie'),
    'inspraakInstructies': formData.value('inspraakInstructies'),
    'periodeVan': formData.value('periodeVan'),
    'periodeTotEnMet': formData.value('periodeTotEnMet'),
    'payload': payload(formData),
  };

  if (inspraakId) {
    inspraak.id = inspraakId;
  }

  return inspraak;
};

export const inspraakMetNiscodes = (formData, inspraakId = null) => {
  const inspraak = {
    '@type': 'niscodes',
    'dossierType': formData.value('procedure'),
    'naam': formData.value('naam'),
    'initiatiefnemer': formData.value('initiatiefnemer'),
    'niscodes': formData.value('niscodes'),
    'dossierInfo': formData.value('dossierInformatie'),
    'inspraakInstructies': formData.value('inspraakInstructies'),
    'periodeVan': formData.value('periodeVan'),
    'periodeTotEnMet': formData.value('periodeTotEnMet'),
    'payload': payload(formData),
  };

  if (inspraakId) {
    inspraak.id = inspraakId;
  }

  return inspraak;
};

export const readShapeFileToGeoJson = async (inputFiles) => {
  if (inputFiles.length !== 1) {
    return {errors: [NO_FILE]};
  }
  const inputFile = inputFiles[0];
  if (!inputFile.name.endsWith('.zip')) {
    return {errors: [NOT_ZIP]};
  }

  const {entries} = await unzip(inputFile);

  const invalidExtensions = Object.keys(entries)
    .filter(
      (fn) => !ALLOWED_EXTENSIONS.some((ext) => fn.toLowerCase().endsWith(ext)));
  if (invalidExtensions.length !== 0) {
    return {errors: [INVALID_EXTENSION]};
  }

  const shapeFiles = Object.keys(entries).filter((file) => file.toLowerCase().endsWith('.shp'));
  if (shapeFiles.length === 0) {
    return {errors: [NO_SHP_FILES]};
  }
  if (shapeFiles.length > 1) {
    return {errors: [MULTIPLE_SHP_FILES]};
  }

  const zipEntry = entries[shapeFiles[0]];
  const contentArrayBuffer = await zipEntry.arrayBuffer();
  const featureCollection = await shapefile.read(contentArrayBuffer);
  return readAndMergeFeaturesFromCollection(featureCollection);
};

const readAndMergeFeaturesFromCollection = (featureCollection) => {
  if (featureCollection.features.length === 0) {
    return {errors: [NO_FEATURES]};
  }
  const geometry = GeoJsonReader.readFeatures(featureCollection)
    .map((f) => transformToOLGeometry(f))
    .reduce((l, r) => l.union(r));
  return {value: GoeJsonWriter.write(geometry)};
};

export const transformToOLGeometry = (f) => {
  return Ol3Parser.read(f.getGeometry());
};

