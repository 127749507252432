import {css, html, LitElement, render} from 'lit';
import {until} from 'lit/directives/until.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';

import {Router} from '@vaadin/router';
import {get, registerTranslateConfig, translate, use} from 'lit-translate';

import {define} from '@domg-wc/common-utilities';

export {
  html,
  LitElement,
  css,
  until,
  render,
  ifDefined,
  unsafeHTML,
};

export {define};
export {Router};

export {
  get,
  registerTranslateConfig,
  translate,
  use,
};

const SUPPORTED_LANGUAGES = ['nl'];

// Small library to improve on fetch() usage
export const api = async (method, url, data, headers = {}) => {
  const body = (data instanceof FormData) ? data : JSON.stringify(data);
  const extendedHeaders = Object.assign({},
    (data instanceof FormData) ? {} : api.headers, headers);
  const response = await fetch(url, {
    method: method, // POST GET PUT DELETE ...
    body: body, // send it as stringified json
    credentials: api.credentials, // to keep the session on the request
    headers: extendedHeaders, // extend the headers
    redirect: 'follow',
  });
  if (!response.ok) {
    const err = new Error(response.statusText);
    err.response = response;
    throw err;
  }

  if (response.status === 204) {
    // return nothing as there is no content
    return;
  }

  return response.json();
};

// Defaults that can be globally overwritten
api.credentials = 'same-origin';
api.headers = {
  'Accept': 'application/*+json, application/json', // receive json
  'Content-Type': 'application/json', // send json
};

// Convenient methods
['get', 'post', 'put', 'delete', 'patch'].forEach(
  (method) => api[method] = api.bind(null, method.toUpperCase()));

export const runSilently = (runnable) => {
  (async () => {
    return await runnable();
  })().catch((error) => {
    console.error(error);
  });
};

export const hasLink = (resource, naam) => {
  if (!resource) {
    return false;
  }
  if (!resource._links) {
    return false;
  }
  return resource._links[naam] != null;
};

export const getLink = (resource, naam) => {
  return resource._links[naam].href;
};

export const queryById = (component) => (id) => {
  return queryBySelector(component)(`[id="${id}"]`);
};

export const queryBySelector = (component) => (id) => {
  if (!component.shadowRoot) {
    return component.querySelector(id);
  }
  return component.shadowRoot.querySelector(id);
};

export const groupBy = (key) => (result, item) => {
  return Object.assign(result,
    {[item[key]]: (result[item[key]] || []).concat(item)});
};

export const comparingWithFunction = (fn) => (a, b) => {
  const fa = fn(a);
  const fb = fn(b);
  return fa === fb ? 0 : fa < fb ? -1 : 1;
};

export const bindVlSelect = (
  {
    component,
    choices,
    selectedChoice,
    sortFilter,
    placeholder,
  }) => {
  component.ready().then(() => {
    if (sortFilter) {
      component.sortFilter = sortFilter;
    }
    if (placeholder) {
      choices.push(
        {label: placeholder, value: '', placeholder: true, selected: true});
    }
    if (selectedChoice) {
      choices.forEach((c) => c.selected = c.value === selectedChoice);
    }
    component.choices = choices;
  });
};

export const formatDate = (date) => {
  // eslint-disable-next-line new-cap
  return Intl.DateTimeFormat(getUserPreferredLanguage(),
    {year: 'numeric', month: 'long', day: 'numeric'}).format(new Date(date));
};

export const debounce = ({func, delay = 100, context}) => {
  let timerId;
  return (...args) => {
    const boundFunc = func.bind(context, ...args);
    clearTimeout(timerId);
    timerId = setTimeout(boundFunc, delay);
  };
};

export const handleGeneralLoketError = (error) => {
  (async () => {
    console.log(error.stack);
    const response = error.response || {};
    return handle401(response) ||
      handle403(response) ||
      handleLoket404(response) ||
      handleLoket500(response);
  })().catch((error) => {
    console.error(error);
  });
};

export const handleErrors = (error, errorComponent) => {
  (async () => {
    console.log(error.stack);
    const response = error.response || {};
    return await handle400(response, errorComponent) ||
      handle401(response) ||
      handle403(response) ||
      await handle404(response, errorComponent) ||
      handle500(response, errorComponent);
  })().catch((error) => {
    console.error(error);
  });
};

const handle400 = async (response, errorComponent) => {
  if (response.status === 400) {
    const valErrors = await response.json();
    if (valErrors.validationErrors) {
      errorComponent.validationErrors = valErrors.validationErrors;
    } else {
      errorComponent.errors4xx = [{
        message: valErrors.detail,
      }];
    }
    return true;
  }
  return false;
};

const handle401 = (response) => {
  if (response.status === 401) {
    window.location.reload();
    return true;
  }
  return false;
};

const handle403 = (response) => {
  if (response.status === 403) {
    Router.go('/403');
    return true;
  }
  return false;
};

const handle500 = (response) => {
  if (response.status >= 500) {
    Router.go('/500');
    return true;
  }
  return false;
};

const handleLoket404 = (response) => {
  if (response.status === 404) {
    Router.go('/inspraak-beheer/404');
    return true;
  }
  return false;
};

const handleLoket500 = (response) => {
  if (response.status >= 500) {
    Router.go('/inspraak-beheer/500');
    return true;
  }
  return false;
};

const handle404 = async (response, errorComponent) => {
  if (response.status === 404) {
    const responseJson = await response.json();
    errorComponent.notFoundErrors = [{
      message: responseJson.detail,
    }];
    return true;
  }
  return false;
};

const getUserPreferredLanguage = () => {
  if (SUPPORTED_LANGUAGES.includes(navigator.language)) {
    return navigator.language;
  }
  return SUPPORTED_LANGUAGES[0];
};

export const performWithLoader = (button, action) => {
  (async () => {
    button.setAttribute('disabled', '');
    button.setAttribute('loading', '');
    await action();
  })().catch((error) => {
    console.log(error);
  }).finally(() => {
    button.removeAttribute('disabled', '');
    button.removeAttribute('loading', '');
  });
};


export const fixVlRichData = (richData) => {
  const toggleFilterButton = richData.shadowRoot.querySelector('#toggle-filter');
  toggleFilterButton.style.display = 'none';
};

// EVENTS
const customEvent = (name, detail) => {
  return new CustomEvent(name, detail);
};

export const deleteConfirmed = () => {
  return customEvent('delete-confirmed', {});
};

export const NO_FEATURES = 'Zip-shape bestand bevat geen features';
export const NOT_ZIP = 'Opgeladen bestand is geen zip bestand';
export const NO_FILE = 'Locatie is verplicht';
export const INVALID_EXTENSION = 'Zip-shape bestand bevat bestanden met een ongeldige extensie';
export const MULTIPLE_SHP_FILES = 'Zip-shape bestand bevat meer dan één shape bestand';
export const NO_SHP_FILES = 'Zip bestand bevat geen shape bestand';

export const ALLOWED_EXTENSIONS = [
  '.shp',
  '.dbf',
  '.shx',
  '.prj',
  '.qix',
  '.qpj',
  '.fix',
  '.xml',
  '.shp.xml',
  '.sbn',
  '.sbx',
  '.fbn',
  '.fbx',
  '.ain',
  '.aih',
  '.ixs',
  '.mxs',
  '.atx',
  '.cpg'];
