import {
  comparingWithFunction,
  debounce,
  define,
  fixVlRichData,
  formatDate,
  get,
  getLink,
  hasLink,
  html,
  LitElement,
  queryById, unsafeHTML,
} from '../../common/commons';
import {api, getResource} from '../../common/rest';
import {config, resolveConfig} from '../../common/config';
import {
  buildBeheerTrefwoordFilter,
  buildLocatieFilter,
  buildPeriodeBegintVoorFilter,
  buildPeriodeEindigtNaFilter,
} from '../../common/filter';
import {reduceFilterSpec} from '../../common/rsql';
import {
  renderContentHeader,
  renderFormGrid,
  renderLayout,
  renderStack,
} from '../../common/templates';
import {formMixin} from '../../common/forms';
import {date, select, text} from '../../common/inputs';
import './ibo-bevestig-verwijder-inspraak';
import {__asGroupedLocaties} from '../../common/administratieve-eenheden';


import '@domg-wc/components/accordion';
import '@domg-wc/components/datepicker';
import '@domg-wc/components/info-tile';
import '@domg-wc/components/loader';
import '@domg-wc/components/pager';
import '@domg-wc/components/rich-data';
import '@domg-wc/components/typography';

import '@domg-wc/elements/action-group';
import '@domg-wc/elements/button';
import '@domg-wc/elements/form';
import '@domg-wc/elements/link';
import '@domg-wc/elements/input-field';
import '@domg-wc/elements/properties';
import '@domg-wc/elements/search-filter';
import '@domg-wc/elements/select';
import '@domg-wc/elements/title';


class IboInspraakBeheer extends formMixin(LitElement) {
  static get properties() {
    return {
      page: {type: Object},
      loading: {type: Boolean},
      keuzelijstLoading: {type: Boolean},
    };
  }

  constructor() {
    super();
    this.__debouncedSearch = debounce(
      {func: () => this.__search(), context: this, delay: 100});
    this.__searcher = null;
    this.page = {
      paging: {
        currentPage: 1,
        itemsPerPage: 20,
      },
    };
    this.loading = true;
    this.keuzelijstLoading = true;
  }

  static get defaultSortKeuze() {
    return 'periodeVan,desc';
  }

  async firstUpdated(changedProperties) {
    try {
      const resource = await getResource({url: '/api'});
      this.__links = resource._links;

      const locaties = await getResource({url: this.__links.locaties.href});
      this.__locaties = locaties._embedded.administratieveEenheden;
    } catch (e) {
      console.error(e);
    }
    this.keuzelijstLoading = false;
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);

    if (_changedProperties.has('page') && this.page) {
      this.byId('rich-data').data = this.page;
      fixVlRichData(this.byId('rich-data'));
    }

    if (_changedProperties.has('keuzelijstLoading') && !this.keuzelijstLoading) {
      this.formData = {};

      // pas zoeken als formdata ingevuld is
      this.__debouncedSearch();
    }
  }

  get form() {
    return this.byId('search-form');
  }

  __extractPage(data) {
    return {
      data: data._embedded.inspraakPeriodeList,
      paging: {
        currentPage: data.page.number + 1,
        itemsPerPage: data.page.size,
        totalItems: data.page.totalElements,
      },
    };
  }

  byId(id) {
    return queryById(this)(id);
  }


  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${renderContentHeader('')}
      <section is="vl-region">
        ${renderLayout([this.__renderContent()])}
      </section>
      ${this.__renderBevestigVerwijderInspraak()}
    `;
  }

  __renderContent() {
    return renderStack([
      {template: this.__renderInspraakTitle()},
      {size: 8, mediumSize: 10, template: this.__renderInspraakOverzicht()},
      {template: html`<hr/>`},
      {template: this.__renderRichData()},
    ]);
  }

  __renderInspraakTitle() {
    return html`
      <h1 is="vl-h1">Beheer inspraakperiodes</h1>
    `;
  }

  __renderInspraakOverzicht() {
    return html`
      <p>Dit is de beheermodule van het inspraakportaal. Hier ziet u alle
        inspraakperiodes, ook die uit het verleden. Op het
        <a is="vl-link" href="/">publieke deel</a>
        worden alleen de actieve en toekomstige inspraakperiodes getoond.</p>
      <br/>
      ${this.__renderRegistreerInspraakPeriode()}
    `;
  }

  __renderRegistreerInspraakPeriode() {
    if (!this.__hasRegistreerInspraakPeriode) {
      return html``;
    }

    return html`
      <a is="vl-link-button" href="/inspraak-aanmaken">
        <span is="vl-icon" data-vl-icon="add" data-vl-before></span>
        Inspraakperiode toevoegen
      </a>
    `;
  }

  __renderRichData() {
    // TODO data-vl-filter-closable moet hier eigenlijk niet
    //  (https://github.com/milieuinfo/webcomponent-vl-ui-rich-data/issues/14)

    // TODO 2: sorter select in methode gieten zoals de anderen
    //  zie https://github.com/milieuinfo/webcomponent-vl-ui-rich-data/issues/15
    return html`
      <vl-rich-data id="rich-data" data-vl-filter-closable>
        <vl-pager slot="pager" @change="${this.__handlePageNumberChanged}"></vl-pager>
        <div slot="content">${this.__renderResultaten()}</div>
        <div slot="no-content">${this.__renderGeenResultaten()}</div>
        <select
          is="vl-select" id="sort-dropdown" name="sort-dropdown" slot="sorter"
          @change="${this.__handleSortOrderChanged}">
          ${(this.__renderSortKeuzes())}
        </select>
        <div is="vl-search-filter" data-vl-alt="" slot="filter">
          ${this.__renderSearchForm()}
        </div>
      </vl-rich-data>`;
  }

  __renderSortKeuzes() {
    return ['naam', 'initiatiefnemer', 'dossierType', 'periodeVan,desc', 'periodeTotEnMet,desc']
    .map((sk) => this.__renderSortKeuze(sk));
  }

  __renderSortKeuze(sk) {
    return html`
      <option value="${sk}" ?selected="${sk === IboInspraakBeheer.defaultSortKeuze}">
        ${get(`sortering.${sk}`)}
      </option>`;
  }

  __renderSearchForm() {
    if (this.keuzelijstLoading) {
      return html`
        <vl-loader id="loader-search-form"></vl-loader>`;
    }
    return html`
      <form
        is="vl-form" id="search-form" novalidate
        @reset="${this.__handleSearchFormReset}"
        @submit="${(e) => e.preventDefault()}">

        ${this.__renderTrefwoord()}
        ${this.__renderLocatie()}
        ${this.__renderPeriode()}
        ${this.__renderSearchButton()}
      </form>
      <div>
        <button is="vl-button-link" type="reset" form="search-form">
          Zoekopdracht verwijderen
        </button>
      </div>`;
  }

  __renderTrefwoord() {
    return html`
      <section>
        <h2>Filter inspraakperiodes</h2>
        ${renderFormGrid([
          text({
            label: 'Trefwoord',
            property: 'trefwoord',
            placeholder: 'Naam, procedure, initiatiefnemer, ...',
            onChange: this.__handleSearchChanged,
          }),
        ])}
      </section>
    `;
  }

  __renderLocatie() {
    return html`
      <section>
        <h2>Locatie</h2>
        ${this.__renderLocatieSelect()}
      </section>
    `;
  }

  __renderLocatieSelect() {
    return renderFormGrid([
      select({
        label: 'Betrokken gemeente of provincie',
        property: 'betrokken-locaties',
        placeholder: 'Alle gemeentes en provincies',
        onChange: this.__handleSearchChanged,
        choices: this.__locaties,
        groupFunction: __asGroupedLocaties,
        sortFilter: comparingWithFunction((x) => x.id),
      })]);
  }

  __renderPeriode() {
    return html`
      <section>
        <h2>Periode voor inspraak</h2>
        ${renderFormGrid([
          date({
            label: 'Inspraakperiode eindigt na',
            property: 'periodeEindigtNa',
            onChange: this.__handleSearchChanged,
          }),
          date({
            label: 'Inspraakperiode begint voor',
            property: 'periodeBegintVoor',
            onChange: this.__handleSearchChanged,
          }),
        ])}
      </section>
    `;
  }

  __renderSearchButton() {
    return html`
      <div>
        <button is="vl-button" id="search-button" type="submit"
                @click="${this.__handleSearchChanged}">
          <span is="vl-icon" data-vl-icon="search" data-vl-before></span>
          Zoeken
        </button>
      </div>
    `;
  }

  __renderGeenResultaten() {
    if (this.loading) {
      return html`
        <vl-loader></vl-loader>`;
    }

    return html`
      <p>Er zijn geen inspraakperiodes gevonden die aan uw huidige filter
        voldoen.</p>
    `;
  }

  __renderResultaten() {
    if (this.loading) {
      return html`
        <vl-loader></vl-loader>`;
    }

    return renderStack([
      ...this.page.data.flatMap((item) => this.__renderResultaat(item)),
    ]);
  }

  __renderResultaat(inspraakperiode) {
    const subTitle =
      `Inspraakperiode loopt van ${formatDate(
        inspraakperiode.periodeVan)} tot en met ${formatDate(
        inspraakperiode.periodeTotEnMet)}`;
    return {
      template: html`
        <vl-info-tile data-vl-toggleable>
          <span slot="title">${inspraakperiode.naam}</span>
          <span slot="subtitle">${subTitle}</span>
          <div slot="content">
            ${renderStack([
              {
                template: html`
                  ${this.__renderResultaatInspraakInfo(inspraakperiode)}
                  ${this.__renderResultaatDossierInfo(inspraakperiode)}`,
              },
              {template: this.__renderResultaatBezwaren(inspraakperiode)},
              {
                template: html`
                  <div is="vl-action-group">
                    ${this.__renderWijzigButton(inspraakperiode)}
                    ${this.__renderVerwijderButton(inspraakperiode)}
                  </div>`,
              },
            ])}
          </div>
        </vl-info-tile>
      `,
    };
  }

  __renderResultaatInspraakInfo(inspraakperiode) {
    return html`
      <vl-properties>
        <dl is="vl-properties-list">
          <dt is="vl-property-term">Initiatiefnemer</dt>
          <dd is="vl-property-value">${inspraakperiode.initiatiefnemer}</dd>

          <dt is="vl-property-term">Procedure</dt>
          <dd is="vl-property-value">
            ${this.__procedureLabel(inspraakperiode.dossierType)}
          </dd>
        </dl>
      </vl-properties>
    `;
  }

  __procedureLabel(dossierType) {
    return config.alleProcedurekeuzes.find((pk) => pk.id === dossierType).label;
  }

  __renderResultaatDossierInfo(inspraakperiode) {
    return html`
      <h4 is="vl-h4">Over dit dossier</h4>
      <vl-typography>${unsafeHTML(inspraakperiode.dossierInfo)}</vl-typography>
    `;
  }

  __renderResultaatBezwaren(inspraakperiode) {
    return html`
      <h4 is="vl-h4">Bezwaren, opmerkingen en adviezen indienen</h4>
      <vl-typography>${unsafeHTML(inspraakperiode.inspraakInstructies)}
      </vl-typography>
    `;
  }

  __renderVerwijderButton(item) {
    if (hasLink(item, 'verwijderInspraakPeriode')) {
      return html`
        <button is="vl-button"
                @click="${() => this.__handleVerwijderButtonClicked(
                  item)}" data-vl-error>
          <span is="vl-icon" data-vl-icon="bin" data-vl-before></span>
          Verwijderen
        </button>`;
    }
    return html``;
  }

  __handleVerwijderButtonClicked(item) {
    const verwijderenModal = queryById(this)('bevestigVerwijderInspraakPopUp');
    verwijderenModal.inspraak = Object.assign({}, item);
    verwijderenModal.link = getLink(item, 'verwijderInspraakPeriode');
  }

  __renderWijzigButton(item) {
    if (hasLink(item, 'wijzigInspraakPeriode')) {
      return html`
        <a is="vl-link-button" href="/inspraak-wijzigen/${item.id}">
          <span is="vl-icon" data-vl-icon="pencil" data-vl-before></span>
          Wijzigen
        </a>
      `;
    }
    return html``;
  }

  __renderBevestigVerwijderInspraak() {
    return html`
      <ibo-bevestig-verwijder-inspraak
        id="bevestigVerwijderInspraakPopUp"
        @delete-confirmed="${this.__handleInspraakPeriodeVerwijderd}"
      />
    `;
  }

  async __handleInspraakPeriodeVerwijderd() {
    this.__debouncedSearch();
  }

  __handlePageNumberChanged(e) {
    if (this.page.paging.currentPage !== e.detail.currentPage) {
      this.page = Object.assign(this.page, {
        paging: {
          currentPage: e.detail.currentPage,
          itemsPerPage: this.page.paging.itemsPerPage,
          totalItems: this.page.paging.totalItems,
        },
      });
      this.__debouncedSearch();
    }
  }

  __handleSortOrderChanged(e) {
    this.__sort = e.target ? e.target.value : GrupsZoeken.defaultSortKeuze;
    this.__debouncedSearch();
  }

  __handleSearchFormReset() {
    this.formData = {};
    // formdata resettten zou voldoende zijn, daar je dan een change event
    // verwacht van de form dit is niet zo voor alle componenten, dus moeten
    // we hier expliciet nog de pager resetten en de search triggeren
    this.__resetPager();
    this.__debouncedSearch();
  }

  __handleSearchChanged(e) {
    this.__resetPager();
    this.__debouncedSearch();
  }

  __resetPager() {
    this.page = Object.assign(this.page, {
      paging: {
        currentPage: 1,
        itemsPerPage: this.page.paging.itemsPerPage,
        totalItems: this.page.totalItems,
      },
    });
  }

  async __search() {
    this.loading = true;
    const data = await this.__searchApi().get({
      url: this.__searchUrl({
        filterSpec: this.__createFilterSpec(),
        page: this.page.paging.currentPage,
        size: this.page.paging.itemsPerPage,
        sort: this.__sort,
      }),
    });
    this.page = this.__extractPage(data);
    this.__hasRegistreerInspraakPeriode = hasLink(data, 'registreerInspraakPeriode');
    this.loading = false;
  }

  __searchApi() {
    if (this.__searcher) {
      this.__searcher.abort();
    }
    this.__searcher = api();
    return this.__searcher;
  }

  __createFilterSpec() {
    const formData = this.formData;
    return {
      trefwoord: {
        value: formData.trefwoord,
        filter: buildBeheerTrefwoordFilter,
      },
      betrokkenLocatie: {
        value: formData['betrokken-locaties'],
        filter: buildLocatieFilter,
      },
      periodeVan: {
        value: formData.periodeBegintVoor,
        filter: buildPeriodeBegintVoorFilter,
      },
      periodeTotEnMet: {
        value: formData.periodeEindigtNa,
        filter: buildPeriodeEindigtNaFilter,
      },
    };
  }

  __searchUrl(
    {
      filterSpec,
      page = this.page.paging.currentPage,
      size = this.page.paging.itemsPerPage,
      sort = IboInspraakBeheer.defaultSortKeuze,
    } = {}) {
    const url = new URL(this.__links.inspraakPeriodes.href);
    if (filterSpec) {
      url.searchParams.append('filter', reduceFilterSpec(filterSpec));
    }
    url.searchParams.append('page', (page - 1).toString(10));
    url.searchParams.append('size', size.toString(10));
    url.searchParams.append('sort', sort);
    return url.href;
  }
}

Promise.all([
  window.customElements.whenDefined('vl-select'),
  resolveConfig(),
]).then(() => {
  define('ibo-inspraak-beheer', IboInspraakBeheer);
});

