import {
  api,
  bindVlSelect,
  comparingWithFunction,
  define,
  getLink,
  handleErrors,
  handleGeneralLoketError,
  html,
  LitElement,
  performWithLoader,
  queryById,
  Router,
  runSilently,
} from '../../common/commons';
import {config, resolveConfig} from '../../common/config';
import {
  renderDatepicker,
  renderFormGrid,
  renderFormRow,
  renderFunctionalHeader,
  renderInputField,
  renderLayout,
  renderMultiSelect,
  renderSelect,
  renderStack,
  renderTextArea,
  renderUpload,
} from '../../common/templates';
import {inspraak, newFormData, readShapeFileToGeoJson} from './ibo-inspraak';
import '../error/ibo-error';
import {__asGroupedLocaties} from '../../common/administratieve-eenheden';

import '@domg-wc/components/radio';
import '@domg-wc/components/textarea';
import '@domg-wc/components/upload';

import '@domg-wc/elements/button';
import '@domg-wc/elements/form';
import '@domg-wc/elements/form-message';
import '@domg-wc/elements/input-field';
import '@domg-wc/elements/multiselect';
import '@domg-wc/elements/select';

const GRUP_VALUE = '0d6264ed-6c51-4559-a0ee-eab1e7aed9c3';

class IboInspraakWijzigen extends LitElement {
  static get properties() {
    return {
      inspraakPeriode: {type: Object},
      location: Object,
      zonderShapefile: {type: Boolean},
    };
  }

  constructor() {
    super();
    this._formData = newFormData(this);
    this.inspraakPeriode = {};
    this.zonderShapefile = false;
  }

  createRenderRoot() {
    return this;
  }

  async firstUpdated(_changedProperties) {
    this._bewaarButton = queryById(this)('bewaar');
    this._geometrieDiv = queryById(this)('geometrieDiv');
    this._niscodesDiv = queryById(this)('niscodesDiv');
    await this.__loadLinks();
    runSilently(() => this._loadData());
  }

  async _loadData() {
    try {
      this.inspraakPeriode = await api.get(
        `${this.__links.inspraakPeriodes.href}/${this.__inspraakId()}`);
      await this.__updateComponents(this.inspraakPeriode);
    } catch (error) {
      handleGeneralLoketError(error);
    }
  }

  async __loadLinks() {
    const data = await api.get('/api');
    this.__links = data._links;
  }

  async __updateComponents(inspraakPeriode) {
    // make it work ...
    queryById(this)('periodeVan').value = inspraakPeriode.periodeVan;
    queryById(this)('periodeTotEnMet').value = inspraakPeriode.periodeTotEnMet;

    await this.__loadAdministratieveEenheden();
    await this.__loadProcedurekeuzes(inspraakPeriode);
  }

  async __loadAdministratieveEenheden() {
    const data = await api.get(this.__links.administratieveEenheden.href);
    bindVlSelect({
      component: queryById(this)('niscodes'),
      choices: __asGroupedLocaties(data._embedded.administratieveEenheden),
      sortFilter: (comparingWithFunction((x) => x.id)),
    });
  }

  _label(naam, groepering) {
    if (groepering.toUpperCase() === 'Provincie'.toUpperCase()) {
      return 'Provincie ' + naam;
    }
    return naam;
  }

  updated(_changedProperties) {
    if (_changedProperties.has('zonderShapefile')) {
      this.__hideAndUnhideLocatieShapeOrList();
    }
  }

  __hideAndUnhideLocatieShapeOrList() {
    if (this.zonderShapefile) {
      this._geometrieDiv.setAttribute('hidden', '');
      queryById(this)('geometrie').clear();
      this._niscodesDiv.removeAttribute('hidden');
    } else {
      this._geometrieDiv.removeAttribute('hidden');
      this._niscodesDiv.setAttribute('hidden', '');
    }
  }

  async __loadProcedurekeuzes(inspraakPeriode) {
    bindVlSelect({
      component: queryById(this)('procedure'),
      choices: config.eigenProcedurekeuzes.map(
        (p) => {
          return {
            label: p.label, value: p.id,
            selected: inspraakPeriode.dossierType === p.id,
          };
        }),
    });
  }

  render() {
    return html`
      ${renderFunctionalHeader('Inspraakportaal beleidsdomein omgeving',
        'Inspraakperiode wijzigen', '/inspraak-beheer')}
      ${renderLayout([this._renderWijzigFormulier()])}
    `;
  }

  _renderWijzigFormulier() {
    return html`
      <section is="vl-region">
        <h1 is="vl-h1">Inspraakperiode wijzigen</h1>
        ${renderStack([
          {size: 8, mediumSize: 10, template: this.__renderForm()},
          {size: 8, mediumSize: 10, template: this.__renderFormActies()},
        ])}
      </section>
    `;
  };

  __renderForm() {
    return html`
      <ibo-error id="errorWijzigInspraak"></ibo-error>
      <form id="inspraak-form" novalidate>
        ${renderFormGrid([
          {template: renderFormRow([{template: this.__renderNaam()}])},
          {
            template: renderFormRow([
              {size: 6, mediumSize: 6, template: this.__renderPeriodeVan()},
              {size: 6, mediumSize: 6, template: this.__renderPeriodeTotEnMet()},
            ]),
          },
          {template: renderFormRow([{template: this.__renderLocatieKeuzes()}])},
          {template: renderFormRow([{template: this.__renderGeometrieUpload()}])},
          {template: renderFormRow([{template: this.__renderAdministratieveEenheden()}])},
          {template: renderFormRow([{template: this.__renderDossierInfo()}])},
          {template: renderFormRow([{template: this.__renderInspraakInstructies()}])},
          {
            template: renderFormRow([{size: 6, mediumSize: 6, template: this.__renderProcedure()},
              {size: 6, mediumSize: 6, template: this.__renderAlgemeenPlanId()},
            ]),
          },
          {
            template: renderFormRow([{size: 6, mediumSize: 6, template: this.__renderInitiatiefnemer()}]),
          },
        ])}
      </form>
    `;
  }

  __renderNaam() {
    return renderInputField({
      formData: this._formData,
      label: 'Naam',
      value: this.inspraakPeriode.naam,
      validator: (value) => value ? {value: value} : {errors: ['Naam  is verplicht']},
    });
  }

  __renderPeriodeVan() {
    return renderDatepicker({
      formData: this._formData,
      label: 'Periode van',
      value: this.inspraakPeriode.periodeVan,
      validator: (value) => value ? {value: value} : {errors: ['Start datum van de periode is verplicht']},
    });
  }

  __renderPeriodeTotEnMet() {
    return renderDatepicker({
      formData: this._formData,
      label: 'Periode tot en met',
      value: this.inspraakPeriode.periodeTotEnMet,
      validator: (value) => value ? {value: value} : {errors: ['Eind datum van de periode is verplicht']},
    });
  }

  __renderLocatieKeuzes() {
    return html`
      <vl-radio-group id="radio-group">
        <vl-radio
          id="shape" data-vl-label="A.d.h.v. shape bestand"
          data-vl-value="shape" data-vl-checked=""
          @change="${this.__handleLocatieKeuzeChanged}"></vl-radio>
        <vl-radio
          id="list"
          data-vl-label="A.d.h.v. gemeenten, provincies of gewest"
          data-vl-value="list"
          @change="${this.__handleLocatieKeuzeChanged}"></vl-radio>
      </vl-radio-group>
    `;
  }

  __handleLocatieKeuzeChanged() {
    this.zonderShapefile = queryById(this)('list').checked;
  }

  __renderGeometrieUpload() {
    return renderUpload({
      formData: this._formData,
      label: 'Vervang locatie',
      inputId: 'geometrie',
      errorId: 'errorGeometrie',
      acceptedFiles: '.zip',
      validator: async ({files, accepted}) => {
        if (!this.zonderShapefile && files.length > 0 &&
          !this.__niscodesHasValue()) {
          return await readShapeFileToGeoJson(accepted);
        }
        return {value: this.inspraakPeriode.locatie};
      },
    });
  }

  __renderAdministratieveEenheden() {
    return renderMultiSelect({
      formData: this._formData,
      label: 'Vervang locatie',
      inputId: 'niscodes',
      errorId: 'errorNiscodes',
      search: true,
      validator: (value) => {
        if (this.__niscodesHasValue() && this.zonderShapefile) {
          return value;
        }
        return '';
      },
    });
  }

  __niscodesHasValue() {
    return queryById(this)('niscodes').value.length > 0;
  }

  __renderDossierInfo() {
    return renderTextArea({
      formData: this._formData,
      label: 'Dossier Informatie',
      value: this.inspraakPeriode.dossierInfo,
      validator: (value) => value ?
        {value: value} :
        {errors: ['Dossier informatie is verplicht']},
    });
  }

  __renderInspraakInstructies() {
    return renderTextArea({
      formData: this._formData,
      label: 'Inspraak Instructies',
      value: this.inspraakPeriode.inspraakInstructies,
      validator: (value) => value ?
        {value: value} :
        {errors: ['Inspraak instructies zijn verplicht']},
    });
  }

  __renderProcedure() {
    return renderSelect({
      formData: this._formData,
      label: 'Procedure',
      validator: (value) => value ?
        {value: value} :
        {errors: ['Procedure keuze is verplicht']},
      changeHandler: (value) => {
        value = value.detail.value;
        if (value !== GRUP_VALUE) {
          queryById(this)('algemeenPlanId').setAttribute('disabled', 'true');
        } else {
          queryById(this)('algemeenPlanId').removeAttribute('disabled');
        }
      },
    });
  }

  __renderAlgemeenPlanId() {
    return renderInputField({
      formData: this._formData,
      label: 'Algemeen Plan ID',
      inputId: 'algemeenPlanId',
      value: this.inspraakPeriode.payload ? this.inspraakPeriode.payload.algemeenPlanId : '',
      validator: (value) => {
        if (queryById(this)('procedure').value === GRUP_VALUE && !value) {
          return {errors: ['Algemeen Plan ID is verplicht']};
        } else {
          return {value: value};
        }
      },
      placeholder: 'RUP_02000_212_00437_00001',
    });
  }


  __renderInitiatiefnemer() {
    return renderInputField({
      formData: this._formData,
      value: this.inspraakPeriode.initiatiefnemer,
      label: 'Initiatiefnemer',
      validator: (value) => value ?
        {value: value} :
        {errors: ['Initiatiefnemer is verplicht']},
    });
  }

  __renderFormActies() {
    return html`
      <div is="vl-action-group" id="modal-action-group">
        <button
          id="bewaar" is="vl-button" value="Wijzigen"
          @click="${this._editInspraak}">
          <span is="vl-icon" data-vl-icon="save" data-vl-before></span>
          Inspraakperiode opslaan
        </button>
        <button
          is="vl-button-link" data-vl-secondary value="Annuleren"
          @click="${() => Router.go('/inspraak-beheer')}">
          <span is="vl-icon" data-vl-icon="close" data-vl-before></span>
          Annuleer
        </button>
      </div>
    `;
  }

  async _editInspraak() {
    performWithLoader(this._bewaarButton, () => this._performSave());
  }

  async _performSave() {
    if (!await this._formData.isValid()) {
      this._formData.focusOnInvalidInput();
      return;
    }

    try {
      await api.put(getLink(this.inspraakPeriode, 'wijzigInspraakPeriode'),
        inspraak(this._formData, this.zonderShapefile, this.__inspraakId()));
      Router.go('/inspraak-beheer');
    } catch (error) {
      handleErrors(error, queryById(this)('errorWijzigInspraak'));
    }
  }

  __inspraakId() {
    return this.location.params.id;
  }
}

Promise.all([
  window.customElements.whenDefined('vl-select'),
  resolveConfig(),
]).then(() => {
  define('ibo-inspraak-wijzigen', IboInspraakWijzigen);
});
